/** @jsxImportSource @emotion/react */
import { Box, Paper, Stack, Typography } from '@mui/material';
import { getAttributeColour } from 'Modules/FieldGuide/Lib';
import { Attribute } from 'Modules/FieldGuide/Types';
import type { DigivolutionConditions as DigivolutionConditionsType } from 'Modules/FieldGuide/Types';
import { Translate } from 'Translation';
import React from 'react';

import styles from './styles';

interface Props {
  conditions: DigivolutionConditionsType | undefined;
}

const DigivolutionConditions: React.FC<Props> = ({ conditions }) => {
  const {
    definitionList,
    definitionListRow,
    definitionListDefinitionCell,
    definitionListCell,
  } = styles(getAttributeColour(Attribute.Neutral));

  return (
    <Paper sx={{ p: 2 }} elevation={3}>
      <Stack>
        <Typography
          component="p"
          sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}
        >
          <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.title" />
        </Typography>
        <Box sx={{ mb: 2 }}>
          <dl css={definitionList}>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.level" />
              </dt>
              <dd css={definitionListCell}>{conditions?.level ?? '-'}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.attack" />
              </dt>
              <dd css={definitionListCell}>{conditions?.attack ?? '-'}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.defence" />
              </dt>
              <dd css={definitionListCell}>{conditions?.defence ?? '-'}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.hp" />
              </dt>
              <dd css={definitionListCell}>{conditions?.hp ?? '-'}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.intelligence" />
              </dt>
              <dd css={definitionListCell}>
                {conditions?.intelligence ?? '-'}
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.sp" />
              </dt>
              <dd css={definitionListCell}>{conditions?.sp ?? '-'}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.speed" />
              </dt>
              <dd css={definitionListCell}>{conditions?.speed ?? '-'}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.ability" />
              </dt>
              <dd css={definitionListCell}>{conditions?.ability ?? '-'}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.camaradarie" />
              </dt>
              <dd css={definitionListCell}>{conditions?.camaradarie ?? '-'}</dd>
            </div>
          </dl>
        </Box>
        <Box>
          <Typography component="p" sx={{ fontWeight: 'bold', mb: 1 }}>
            <Translate path="Pages.FieldGuide.Digimon.digivolution.requirements.additionalConditions" />
          </Typography>
          <Typography>{conditions?.additionalConditions ?? '-'}</Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default DigivolutionConditions;
