import type {
  ListingCursor,
  ListingEntities,
  ListingRTK,
  PaginationOptions,
} from 'Store';
import { useTranslation } from 'Translation';
import { uniqBy } from 'lodash';
import type { SnackbarKey } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';

import { notificationContext } from '../Contexts';

interface Returns<Options> {
  entities: ListingEntities[];
  isLoading: boolean;
  options: Options;
  rtkQuery: ListingRTK;
  setOptions: (options: Options) => void;
  clearFilters: () => void;
  clearState: () => void;
  paginate: () => void;
  setLimit: (limit: number) => void;
}

/**
 * @desc This hook takes a useGet custom hook from RTKQuery and generates the cursor based pagination methods and the query entity
 * @typeParam Query - This is going to be a member of the ListingRTK Union Type
 * @param {unknown} query - This is the query hook of your paginated list query
 * @param {Record<string, string | number | boolean | unknown>} options - This is the filter options object that is used to provide params to the query
 */
function usePagination<Query extends ListingRTK, Options extends {}>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: any,
  lmt: number = 40,
): Returns<Options> {
  // Notifications
  const notificationCtx = useContext(notificationContext);
  const fetchError = useTranslation('Common.errors.fetch', {
    entity: 'digimon',
  });
  const [notificationKey, setNotificationKey] = useState<SnackbarKey>();

  // State for pagination and stuff
  const [shouldSkip, setShouldSkip] = useState<boolean>(false);
  const [cursor, setCursor] = useState<ListingCursor>();
  const [previousCursor, setPreviousCursor] = useState<ListingCursor>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(lmt);

  const [entities, setEntities] = useState<ListingEntities[]>([]);

  const [options, setOptions] = useState<Options>({} as Options);
  const completeOptions: PaginationOptions<Options> = useMemo(
    () => ({ afterCursor: cursor, limit, ...options }),
    [limit, cursor, options],
  );

  const clearState = (): void => {
    setIsLoading(true);
    setShouldSkip(false);
    setEntities([]);
    setCursor(undefined);
    setPreviousCursor(undefined);
  };

  const clearFilters = (): void => {
    setOptions({} as Options);
    clearState();
  };

  const paginate = (): void => {
    if (rtkQuery.data?.cursor.afterCursor) {
      setCursor(rtkQuery.data.cursor.afterCursor);
    }
  };

  const updateOptions = (options: Options): void => {
    setOptions((prevOptions) => ({ ...prevOptions, ...options }));
    setShouldSkip(false);
  };

  const rtkQuery: Query = query(completeOptions, {
    skip: shouldSkip,
  });

  useEffect(() => {
    if (!rtkQuery.isLoading && rtkQuery.data?.items) {
      if (cursor) {
        if (cursor !== previousCursor) {
          setEntities((prevEntities) => {
            const merged = rtkQuery.data?.items
              ? [...prevEntities, ...rtkQuery.data.items]
              : [];
            const newDigimon = uniqBy(merged, 'id');

            return newDigimon;
          });

          setTimeout((): void => setPreviousCursor(cursor), 500);
        }
      } else {
        setEntities(rtkQuery.data.items);
      }

      setTimeout((): void => setIsLoading(false), 500);
    }
  }, [cursor, rtkQuery, previousCursor]);

  useEffect(() => {
    if (rtkQuery.error && !notificationKey) {
      const key = notificationCtx.notify({
        title: fetchError,
        variant: 'error',
      });

      // setNotificationKey(key);
    }
  }, [fetchError, notificationCtx, notificationKey, rtkQuery.error]);

  return {
    clearFilters,
    clearState,
    entities,
    isLoading: isLoading || rtkQuery.isLoading,
    options,
    paginate,
    rtkQuery,
    setLimit,
    setOptions: updateOptions,
  };
}

export default usePagination;
