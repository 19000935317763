import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppBarStyling, Breadcrumb } from '../..';

export interface UIState {
  appBarBreadcrumbs: Breadcrumb[];
  appBarStyling: AppBarStyling;
  metaTitle: string;
  isAppBarLoading: boolean;
  isDrawerOpen: boolean;
}

const initialState: UIState = {
  appBarBreadcrumbs: [{ label: 'Digidex' }],
  appBarStyling: {
    bgcolor: 'undefined',
  },
  isAppBarLoading: false,
  isDrawerOpen: false,
  metaTitle: 'Digidex',
};

const defaultStyles = {} as AppBarStyling;

export const uiSlice = createSlice({
  initialState,
  name: 'ui',
  reducers: {
    setAppBarStyling: (
      state,
      action: PayloadAction<{ styles?: AppBarStyling; loading?: boolean }>,
    ): void => {
      state.appBarStyling = action.payload.styles ?? defaultStyles;
      state.isAppBarLoading = action.payload.loading ?? false;
    },
    setPage: (
      state,
      action: PayloadAction<{ title: string; breadcrumbs: Breadcrumb[] }>,
    ) => {
      state.appBarBreadcrumbs = action.payload.breadcrumbs;
      state.metaTitle = action.payload.title;
    },
    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const { setPage, setAppBarStyling, toggleDrawer } = uiSlice.actions;

export default uiSlice.reducer;
