import { css } from '@emotion/react';
import { largeCornerRadius, neutralAttributeColour, theme, white } from 'Theme';

const styles = {
  avatar: {
    bgcolor: '#ffffff5e',
  },
  avatarContainer: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  button: {
    color: white,
    fontWeight: '600',
  },
  card: {
    bgcolor: neutralAttributeColour,
    borderRadius: largeCornerRadius,
    color: white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contents: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  definitionList: css`
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
  `,
  definitionListCell: css`
    display: table-cell;
    border-left-width: 3px;
    padding: ${theme.spacing(0.45)} 0;
    padding-right: ${theme.spacing(0.45)};
  `,
  definitionListRow: css`
    display: table-row;
  `,
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
  },
  title: {
    color: white,
    fontWeight: 'bold',
  },
};

export default styles;
