import Search from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import type { TranslationPath } from 'Translation';
import { Translate, useTranslation } from 'Translation';
import React, { useMemo } from 'react';

interface Props {
  search: SearchField;
  isOpen: boolean;
  onClose(): void;
  filters: PaginationFilter[];
  clearFilters(): void;
}

export enum PaginationFilterType {
  Select,
  Switch,
}

export interface PaginationFilterOption {
  id: string;
  label: TranslationPath;
  value: string | number;
}

export interface SearchField {
  id: string;
  label: TranslationPath;
  placeholder: TranslationPath;
}

export interface PaginationFilter {
  id: string;
  label: TranslationPath;
  placeholder?: TranslationPath;
  type: PaginationFilterType;
  onChange(e: any): void;
  value: string | number | boolean;
  options?: PaginationFilterOption[];
}

interface SelectFilter extends PaginationFilter {
  options: PaginationFilterOption[];
}

interface BooleanSwitchFilter extends PaginationFilter {
  value: boolean;
}

function generateFilters(filters: PaginationFilter[]) {
  return filters.map((filter) => {
    switch (filter.type) {
      case PaginationFilterType.Select: {
        const selectFilter = filter as SelectFilter;
        const inputLabelId = `${selectFilter.id}-label`;
        return (
          <Grid xs={12} md={4} item key={selectFilter.id}>
            <FormControl fullWidth>
              <InputLabel id={inputLabelId}>
                <Translate path={selectFilter.label} />
              </InputLabel>
              <Select
                labelId={inputLabelId}
                id={selectFilter.id}
                value={selectFilter.value}
                label={<Translate path={selectFilter.label} />}
                onChange={selectFilter.onChange}
              >
                {selectFilter.options.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.value}
                    children={<Translate path={option.label} />}
                  />
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      }

      case PaginationFilterType.Switch: {
        const switchFilter = filter as BooleanSwitchFilter;
        return (
          <Grid xs={12} md={4} item key={filter.id}>
            <FormControlLabel
              onChange={filter.onChange}
              value={filter.value}
              control={<Switch checked={switchFilter.value} />}
              label={<Translate path={filter.label} />}
            />
          </Grid>
        );
      }

      default:
        return <></>;
    }
  });
}

function PaginationFilters({
  clearFilters,
  filters,
  isOpen,
  onClose,
  search,
}: Props): JSX.Element {
  const isFiltered = useMemo(() => true, []);

  const filterElements = useMemo(() => generateFilters(filters), [filters]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <Box>
          <Box mb={3} mt={1} width="100%">
            <TextField
              id={search.id}
              label={useTranslation(search.label)}
              variant="outlined"
              placeholder={useTranslation(search.placeholder)}
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Grid container spacing={2}>
            {filterElements}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button disabled={!isFiltered} onClick={clearFilters}>
          {useTranslation('Components.PaginationFilters.actions.reset')}
        </Button>
        <Button onClick={onClose}>
          {useTranslation('Components.PaginationFilters.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaginationFilters;
