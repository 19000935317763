enum AvailableAt {
  ChapterOne = 'chapter-1',
  ChapterTwo = 'chapter-2',
  ChapterThree = 'chapter-3',
  ChapterFour = 'chapter-4',
  ChapterFive = 'chapter-5',
  ChapterSix = 'chapter-6',
  ChapterSeven = 'chapter-7',
  ChapterEight = 'chapter-8',
  ChapterNine = 'chapter-9',
  ChapterTen = 'chapter-10',
  ChapterEleven = 'chapter-11',
  ChapterTwelve = 'chapter-12',
  ChapterThirteen = 'chapter-13',
  ChapterFourteen = 'chapter-14',
  ChapterFifteen = 'chapter-15',
  ChapterSixteen = 'chapter-16',
  ChapterSeventeen = 'chapter-17',
  ChapterEighteen = 'chapter-18',
  ChapterNineteen = 'chapter-19',
  ChapterTwenty = 'chapter-20',
  PostGame = 'post-game',
}

export default AvailableAt;
