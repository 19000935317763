/** @jsxImportSource @emotion/react */
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import type { Roster } from 'Modules/Digilab';
import type { Item, ItemEffect } from 'Modules/FieldGuide';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListRow,
  DescriptionTerm,
} from 'Modules/Shared';
import { Translate, useTranslation } from 'Translation';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import useGetEquipment from '../../useGetEquipment';

interface FormData {
  number: number;
  itemId: number;
}

interface Props {
  roster: Roster;
  slotNumber: number;
  isOpen: boolean;
  onClose: () => void;
}

const AddModal: React.FC<Props> = ({
  isOpen = false,
  onClose,
  roster,
  slotNumber = 1,
}) => {
  const { setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: { itemId: 0, number: slotNumber },
  });
  const { items, isLoading, isSuccess } = useGetEquipment();

  const description = useTranslation(
    'Pages.Digilab.DigimonTeamMember.equipment.modals.add.description',
    { digimon: roster.nickname },
  );

  const watchedItemId = watch('itemId');
  const selectedItem: Item | undefined = useMemo(() => {
    if (watchedItemId !== 0) {
      return items.find((item) => item.id === watchedItemId);
    }

    return undefined;
  }, [items, watchedItemId]);

  const onSubmit = (data: FormData) => {
    console.log(data);
  };

  useEffect(
    () => () => {
      if (!isOpen) {
        reset({ number: slotNumber });
      }
    },
    [slotNumber, isOpen],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {useTranslation(
          'Pages.Digilab.DigimonTeamMember.equipment.modals.add.title',
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2}>
            <FormControl fullWidth>
              <InputLabel id="equipment-itemId-label">Item</InputLabel>
              <Select
                labelId="equipment-itemId-label"
                label="Item"
                value={`${watchedItemId ?? ''}`}
                onChange={(e: SelectChangeEvent) =>
                  setValue('itemId', Number(e.target.value), {
                    shouldValidate: true,
                  })
                }
              >
                {items.map((item) => (
                  <MenuItem
                    value={`${item.id}`}
                    key={`${item.id}-${item.name}`}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!!selectedItem &&
              Array.isArray(selectedItem.effects) &&
              selectedItem.effects.length > 0 && (
                <Paper sx={{ marginTop: '10px', padding: '10px' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    <Translate path="Pages.Digilab.DigimonTeamMember.equipment.modals.add.effects.title" />
                  </Typography>
                  <DescriptionList>
                    {selectedItem.effects.map((effect: ItemEffect) => (
                      <DescriptionListRow key={`effect-${effect.id}`}>
                        <DescriptionTerm>{effect.name}:</DescriptionTerm>
                        <DescriptionDetails>+{effect.value}</DescriptionDetails>
                      </DescriptionListRow>
                    ))}
                  </DescriptionList>
                </Paper>
              )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation(
            'Pages.Digilab.DigimonTeamMember.equipment.modals.add.buttons.cancel',
          )}
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>
          {useTranslation(
            'Pages.Digilab.DigimonTeamMember.equipment.modals.add.buttons.equipItem',
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
