import {
  darkAttributeColour,
  earthAttributeColour,
  electricAttributeColour,
  fireAttributeColour,
  lightAttributeColour,
  neutralAttributeColour,
  plantAttributeColour,
  waterAttributeColour,
  windAttributeColour,
} from 'Theme';

import { Attribute } from '../Types';

function getAttributeColour(digimonAttribute?: Attribute): string {
  switch (digimonAttribute) {
    case Attribute.Dark:
      return darkAttributeColour;

    case Attribute.Earth:
      return earthAttributeColour;

    case Attribute.Electric:
      return electricAttributeColour;

    case Attribute.Fire:
      return fireAttributeColour;

    case Attribute.Light:
      return lightAttributeColour;

    case Attribute.Neutral:
      return neutralAttributeColour;

    case Attribute.Plant:
      return plantAttributeColour;

    case Attribute.Water:
      return waterAttributeColour;

    case Attribute.Wind:
      return windAttributeColour;

    default:
      return neutralAttributeColour;
  }
}

export default getAttributeColour;
