import { css } from '@emotion/react';
import { theme } from 'Theme';

const styles = {
  descriptionList: css`
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
  `,
  descriptionListCell: css`
    display: table-cell;
    border-left-width: 3px;
    padding: ${theme.spacing(0.45)} 0;
    padding-right: ${theme.spacing(0.45)};
  `,
  descriptionListRow: css`
    display: table-row;
  `,
};

export default styles;
