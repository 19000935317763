import type { Digimon } from 'Modules/FieldGuide';
import type { Theme as NotifyTheme } from 'react-toastify';

import type { UserType } from './UserType';

enum Accent {
  Blue = 'blue',
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
  Mauve = 'mauve',
  Purple = 'purple',
}

enum Theme {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}

export interface User {
  id: number;
  type: UserType;
  email: string;
  name: string;
  username: string;
  favourites: Digimon[];
  avatar: string;
  theme: Theme;
  notificationTheme: NotifyTheme;
  accent: Accent;
  createdAt: Date;
}

export interface UserPrefences {
  avatar: string;
  theme: Theme;
  accent: Accent;
}
