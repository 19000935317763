import { configureStore } from '@reduxjs/toolkit';

import { api } from './api';
import { rtkQueryAuthErrorHandler, rtkQueryErrorLogger } from './middleware';
import reducers from './reducers';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      rtkQueryErrorLogger,
      rtkQueryAuthErrorHandler,
    ),
  reducer: reducers,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
