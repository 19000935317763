import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AuthTokens } from '../types';

type AuthState = {
  accessToken: string | null | undefined;
};

const slice = createSlice({
  initialState: { accessToken: null } as AuthState,
  name: 'auth',
  reducers: {
    setCredentials: (
      state,
      { payload: { access_token } }: PayloadAction<AuthTokens>,
    ) => {
      state.accessToken = access_token;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;
