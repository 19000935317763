import { largeCornerRadius, neutralAttributeColour, white } from 'Theme';

const styles = {
  avatar: {
    bgcolor: '#ffffffB3',
    fontWeight: 'bold',
    height: 65,
    width: 65,
  },
  avatarContainer: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  avatarSmall: {
    bgcolor: '#ffffffB3',
    border: 'none',
    fontWeight: 'bold',
    height: 30,
    width: 30,
  },
  button: {
    color: white,
    fontWeight: '600',
  },
  card: {
    bgcolor: neutralAttributeColour,
    borderRadius: largeCornerRadius,
    color: white,
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contents: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
  },
  metaBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    marginTop: '0px',
  },
  metaLabel: {
    color: white,
    fontSize: '0.8rem',
  },
  metaTitle: {
    color: white,
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  subtitle: {
    color: white,
    fontSize: '0.85rem',
    marginBottom: '1px !important',
  },
  title: {
    color: white,
    fontWeight: 'bold',
  },
};

export default styles;
