/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

const TableLink: React.FC<{ children?: ReactNode; to: string }> = ({
  children,
  to,
}) => {
  const theme = useTheme();

  return (
    <Link to={to} style={{ color: theme.palette.text.primary }}>
      {children}
    </Link>
  );
};

export default TableLink;
