// import { useAuth0 } from '@auth0/auth0-react';
import { useLogto } from '@logto/react';
import { useGetMeQuery } from 'Modules/Profile/Store';
import { api } from 'Store';
import type { ReactNode } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import type { AuthTokens } from '../Store';
import { setCredentials } from '../Store';

interface Props {
  isAuthenticated: boolean;
  logout(): void;
  setTokens(tk: string | null | undefined): void;
}

export const authContext = React.createContext<Props>({
  isAuthenticated: false,
  logout: async () => null,
  setTokens: (tk: string | null | undefined) => null,
});

export function AuthProvider({
  children,
}: {
  children?: ReactNode;
}): JSX.Element {
  const [hasRetried, setHasRetried] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userQuery = useGetMeQuery({});
  const { getAccessToken, signOut } = useLogto();
  // const { getAccessTokenSilently } = useAuth0();

  if (userQuery.isError && !hasRetried) {
    setTimeout(() => userQuery.refetch(), 10000);
    setHasRetried(true);
  }

  const user = userQuery.data;
  const hasFetchedUser = userQuery.isSuccess;
  const isAuthenticated = useMemo(
    () => !!user && !!hasFetchedUser,
    [user, hasFetchedUser],
  );

  const clearTokens = () => {
    const payload = {
      access_token: undefined,
    } as AuthTokens;
    dispatch(setCredentials(payload));
    localStorage.removeItem(
      process.env.REACT_APP_JWT_ACCESS_TOKEN ?? '@access-token',
    );
  };

  const setTokens = async (tk: string | null | undefined): Promise<void> => {
    const token = await getAccessToken(process.env.REACT_APP_LOGTO_RESOURCE);

    if (token) {
      localStorage.setItem(
        process.env.REACT_APP_JWT_ACCESS_TOKEN ?? '@access-token',
        token,
      );
    }

    // const token = await getAccessTokenSilently();
    dispatch(
      setCredentials({
        access_token: token,
      }),
    );
  };

  useEffect(() => {
    async function getPersistedToken() {
      try {
        const token = await getAccessToken(
          process.env.REACT_APP_LOGTO_RESOURCE,
        );
        const payload = {
          access_token: token,
        } as AuthTokens;

        dispatch(setCredentials(payload));
      } catch (error) {
        console.error(error);
      }
    }

    getPersistedToken();
  }, []);

  const logout = (): void => {
    clearTokens();
    api.util.resetApiState();
    signOut(process.env.REACT_APP_URL);
  };

  return (
    <authContext.Provider
      value={{
        isAuthenticated,
        logout,
        setTokens,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
