import type { ProcessedTeam, ProcessedTeamMember, Team } from '../Types';
import { TeamMemberType, TeamSlotState } from '../Types';

export function processTeam(team: Team): ProcessedTeam {
  const teamMemberTemplate = [
    {
      number: 1,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Active,
    },
    {
      number: 2,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Active,
    },
    {
      number: 3,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Active,
    },
    {
      number: 4,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
    {
      number: 5,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
    {
      number: 6,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
    {
      number: 7,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
    {
      number: 8,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
    {
      number: 9,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
    {
      number: 10,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
    {
      number: 11,
      state: TeamSlotState.Empty,
      type: TeamMemberType.Reserve,
    },
  ];

  const teamMembers = teamMemberTemplate.map(
    (entry, index): ProcessedTeamMember => {
      const member = team.members[index];

      if (member) {
        return {
          ...entry,
          ...member,
          state: TeamSlotState.Used,
        };
      }

      return {
        ...entry,
        state: TeamSlotState.Empty,
      };
    },
  );

  teamMembers.sort((memberA, memberB) => memberA.number - memberB.number);

  return {
    ...team,
    members: teamMembers,
  };
}

export function processTeams(teams: Team[]): ProcessedTeam[] {
  return teams.map(processTeam);
}
