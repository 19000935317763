import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn('We got a rejected action!');
      console.log(action);
      // toastUtils.warn(action.error.message)
    }

    return next(action);
  };

export const rtkQueryAuthErrorHandler: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  async (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      console.warn('We got an auth issue');
      // const refreshToken = (getState() as RootState).authSlice.refreshToken;

      // if (!!refreshToken && refreshToken.length > 0) {
      //   // @ts-ignore
      //   const promise = dispatch(
      //     // @ts-ignore
      //     api.endpoints.refresh.initiate({ token: refreshToken }),
      //   );
      //   Promise.resolve(promise)
      //     .then((result) => {
      //       if ('data' in result) {
      //         localStorage.setItem(
      //           process.env.REACT_APP_JWT_ACCESS_TOKEN ?? '@access-token',
      //           result.data.access_token,
      //         );
      //         localStorage.setItem(
      //           process.env.REACT_APP_JWT_REFRESH_TOKEN ?? '@refresh-token',
      //           result.data.refresh_token,
      //         );
      //       } else if ('error' in result) {
      //         dispatch(
      //           setCredentials({ access_token: null, refresh_token: null }),
      //         );
      //         localStorage.removeItem(
      //           process.env.REACT_APP_JWT_ACCESS_TOKEN ?? '@access-token',
      //         );
      //         localStorage.removeItem(
      //           process.env.REACT_APP_JWT_REFRESH_TOKEN ?? '@refresh-token',
      //         );
      //       }
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //     });
      // }
    }

    if (isRejectedWithValue(action) && action.payload.status === 403) {
      console.warn('We got an auth issue, do not have the right priviledges');
    }

    return next(action);
  };
