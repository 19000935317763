import { rosterApiSlice, teamsApiSlice } from './slices';

export const digilabSlices = {
  [teamsApiSlice.reducerPath]: teamsApiSlice.reducer,
  [rosterApiSlice.reducerPath]: rosterApiSlice.reducer,
};

export {
  useGetRosterQuery,
  useGetRosterByIDQuery,
  useCreateRosterMutation,
  useCreateTeamMutation,
  useGetTeamsQuery,
  useGetTeamByIDQuery,
  usePatchRosterMutation,
  usePatchTeamMutation,
} from './slices';
