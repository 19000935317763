import type { TranslationPath } from 'Translation/Types';

import { AvailableAt } from '../Types';

function getAvailableAtLabel(availableAt: AvailableAt): TranslationPath {
  switch (availableAt) {
    case AvailableAt.ChapterOne:
      return 'FieldGuide.chapters.chapterOne';

    case AvailableAt.ChapterTwo:
      return 'FieldGuide.chapters.chapterTwo';

    case AvailableAt.ChapterThree:
      return 'FieldGuide.chapters.chapterThree';

    case AvailableAt.ChapterFour:
      return 'FieldGuide.chapters.chapterFour';

    case AvailableAt.ChapterFive:
      return 'FieldGuide.chapters.chapterFive';

    case AvailableAt.ChapterSix:
      return 'FieldGuide.chapters.chapterSix';

    case AvailableAt.ChapterSeven:
      return 'FieldGuide.chapters.chapterSeven';

    case AvailableAt.ChapterEight:
      return 'FieldGuide.chapters.chapterEight';

    case AvailableAt.ChapterNine:
      return 'FieldGuide.chapters.chapterNine';

    case AvailableAt.ChapterTen:
      return 'FieldGuide.chapters.chapterTen';

    case AvailableAt.ChapterEleven:
      return 'FieldGuide.chapters.chapterEleven';

    case AvailableAt.ChapterTwelve:
      return 'FieldGuide.chapters.chapterTwelve';

    case AvailableAt.ChapterThirteen:
      return 'FieldGuide.chapters.chapterThirteen';

    case AvailableAt.ChapterFourteen:
      return 'FieldGuide.chapters.chapterFourteen';

    case AvailableAt.ChapterFifteen:
      return 'FieldGuide.chapters.chapterFifteen';

    case AvailableAt.ChapterSixteen:
      return 'FieldGuide.chapters.chapterSixteen';

    case AvailableAt.ChapterSeventeen:
      return 'FieldGuide.chapters.chapterSeventeen';

    case AvailableAt.ChapterEighteen:
      return 'FieldGuide.chapters.chapterEighteen';

    case AvailableAt.ChapterNineteen:
      return 'FieldGuide.chapters.chapterNineteen';

    case AvailableAt.ChapterTwenty:
      return 'FieldGuide.chapters.chapterTwenty';

    case AvailableAt.PostGame:
      return 'FieldGuide.chapters.postGame';

    default:
      return 'FieldGuide.chapters.chapterOne';
  }
}

export default getAvailableAtLabel;
