/** @jsxImportSource @emotion/react */
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import type { Roster, RosterMove } from 'Modules/Digilab';
import { usePatchRosterMutation } from 'Modules/Digilab';
import type { DigimonMove, MovesPaginatedListFilter } from 'Modules/FieldGuide';
import {
  getAttributeLabel,
  getMoveTypeLabel,
  useGetMovesQuery,
} from 'Modules/FieldGuide';
import type { ComboBoxOption } from 'Modules/Shared';
import { TransferList } from 'Modules/Shared';
import type { PaginationOptions } from 'Store';
import { useTranslation } from 'Translation';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  roster: Roster;
}

function getMoveSecondaryLabel(move: DigimonMove): string {
  if (move.attribute) {
    if (move.power) {
      return i18n.t(
        'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.attribute.power',
        {
          attribute: i18n.t(getAttributeLabel(move.attribute)),
          cost: move.spCost,
          power: move.power,
          type: i18n.t(getMoveTypeLabel(move.type)),
        },
      );
    }

    return i18n.t(
      'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.attribute.noPower',
      {
        attribute: i18n.t(getAttributeLabel(move.attribute)),
        cost: move.spCost,
        type: i18n.t(getMoveTypeLabel(move.type)),
      },
    );
  } else {
    if (move.power) {
      return i18n.t(
        'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.noAttribute.power',
        {
          cost: move.spCost,
          power: move.power,
          type: i18n.t(getMoveTypeLabel(move.type)),
        },
      );
    }

    return i18n.t(
      'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.noAttribute.noPower',
      { cost: move.spCost, type: i18n.t(getMoveTypeLabel(move.type)) },
    );
  }
}

function generateMoveComboBoxOption(move: DigimonMove): ComboBoxOption {
  return {
    label: move.name,
    secondaryLabel: getMoveSecondaryLabel(move),
    value: move.id.toString(),
  };
}

function generateRosterMoveComboBoxOption(move: RosterMove): ComboBoxOption {
  const dm = move.move as DigimonMove;
  return {
    disabled: dm.signature === true,
    id: move.id,
    label: dm.name,
    secondaryLabel: getMoveSecondaryLabel(dm),
    value: dm.id.toString(),
  };
}

const ChangeMovesModal: React.FC<Props> = ({
  isOpen = false,
  onClose,
  roster,
}) => {
  const movesRTK = useGetMovesQuery({
    limit: 500,
    signature: false,
  } as PaginationOptions<MovesPaginatedListFilter>);
  const [patchRoster, result] = usePatchRosterMutation();
  const { moves, digimon } = roster;
  const [movesToSelect, setMovesToSelect] = useState<ComboBoxOption[]>([]);
  const [currentMoves, setCurrentMoves] = useState<ComboBoxOption[]>([]);

  useEffect(() => {
    if (movesRTK.isSuccess && movesRTK.data.items) {
      setMovesToSelect(movesRTK.data.items.map(generateMoveComboBoxOption));
    }

    if (moves) {
      setCurrentMoves(moves.map(generateRosterMoveComboBoxOption));
    }
  }, [movesRTK, moves]);

  const updateLists = useCallback(
    (items: ComboBoxOption[], movedRight: boolean) => {
      const itms: string[] = items.map((el) => el.label);

      if (movedRight) {
        const availableMoves = movesToSelect.filter((el, index) => {
          if (!itms.includes(el.label)) {
            return el;
          }
        });

        const selectedMoves: ComboBoxOption[] = [...currentMoves, ...items];

        setMovesToSelect(availableMoves);
        setCurrentMoves(selectedMoves);
      } else {
        const availableMoves: ComboBoxOption[] = [...movesToSelect, ...items];

        const selectedMoves = currentMoves.filter((el, index) => {
          if (!itms.includes(el.label)) {
            return el;
          }
        });

        setMovesToSelect(
          availableMoves.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }

            if (a.label > b.label) {
              return 1;
            }

            return 0;
          }),
        );

        setCurrentMoves(selectedMoves);
      }
    },
    [movesToSelect, currentMoves],
  );

  const saveMoves = useCallback(() => {
    patchRoster({
      id: roster.id,
      moves: currentMoves.map((el, index) => ({
        id: el.id,
        move: Number(el.value),
        number: index + 1,
      })),
    });
  }, [currentMoves]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        {useTranslation('Pages.Digilab.Roster.moves.edit.modal.title')}
      </DialogTitle>
      <DialogContent>
        <Stack>
          <DialogContentText sx={{ mb: 2 }} id="alert-dialog-description">
            {useTranslation(
              'Pages.Digilab.Roster.moves.edit.modal.description',
              { digimon: roster.nickname },
            )}
          </DialogContentText>
          <TransferList
            updateLists={updateLists}
            leftColumn={{
              items: movesToSelect,
              searchLabel:
                'Pages.Digilab.Roster.moves.edit.transferList.search',
              subtitle:
                'Pages.Digilab.Roster.moves.edit.transferList.add.subtitle',
              title: 'Pages.Digilab.Roster.moves.edit.transferList.add.title',
            }}
            rightColumn={{
              items: currentMoves,
              maxCount: 20,
              searchLabel:
                'Pages.Digilab.Roster.moves.edit.transferList.search',
              subtitle:
                'Pages.Digilab.Roster.moves.edit.transferList.remove.subtitle',
              title:
                'Pages.Digilab.Roster.moves.edit.transferList.remove.title',
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation(
            'Pages.Digilab.Roster.moves.edit.modal.buttons.cancel',
          )}
        </Button>
        <LoadingButton loading={result.isLoading} onClick={saveMoves}>
          {useTranslation('Pages.Digilab.Roster.moves.edit.modal.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeMovesModal;
