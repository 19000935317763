import FavoriteIcon from '@mui/icons-material/Favorite';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import WindowIcon from '@mui/icons-material/Window';
import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import type { PaginationFilter, SearchField } from 'Modules/Shared';
import {
  ClassicPaginationFilters,
  ListingSpeedDial,
  PaginationFilters,
  setPage,
} from 'Modules/Shared';
import type { ListingEntities } from 'Store';
import type { TranslationPath } from 'Translation';
import { Translate } from 'Translation';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import type { SpeedDialItem } from '../ListingSpeedDial';

interface TableHeading {
  dataKey: string;
  label?: TranslationPath;
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
  width?: string | number;
}

interface Props {
  breadcrumbs: Array<{ label: string; to?: string }>;
  entity: string;
  entities: ListingEntities[];
  filters?: PaginationFilter[];
  headings?: TableHeading[];
  isLoading: boolean;
  limit: number;
  listingSkeletonComponent: React.ReactElement;
  noResultsComponent?: React.ReactElement;
  searchProps?: SearchField;
  speedDialItems?: SpeedDialItem[];
  title: string;
  clearFilters(): void;
  itemRender(entity: ListingEntities): React.ReactElement;
  paginate(): void;
  rowRender?(entity: ListingEntities): React.ReactElement;
}

/**
 * @desc This Component is a ListingView that supports infinite scroll based pagination
 * @param {Array<{ label: string; to?: string }>} breadcrumbs - This is to set the app header with this pages breadcrumb
 * @param {string} entity - This is the name of the entity, use this for unique keys
 * @param {Array<ListingEntities>} entities - This is the entities from the RTKQuery
 * @param {Array<PaginationFilter>} filters
 * @param {string} title - This is the title of the page
 * @param filters - These are the filters to be generated
 * @remarks Must be used in conjunction with the usePagination hook
 */
function NewListingView({
  entity,
  entities,
  breadcrumbs,
  title,
  limit = 40,
  listingSkeletonComponent,
  isLoading,
  itemRender,
  clearFilters,
  headings,
  rowRender,
  filters = [],
  speedDialItems = [],
  paginate,
  searchProps = {
    id: 'listing-search',
    label: 'Components.PaginationFilters.search.label',
    placeholder: 'Components.PaginationFilters.search.placeholder',
  },
  noResultsComponent = <p>Nothing to see</p>,
}: Props): JSX.Element {
  // State for pagination and stuff

  const [isTableView, setIsTableView] = useState<boolean>(false);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: windowHeight / 1.5,
  });
  const dispatch = useDispatch();
  const theme = useTheme();

  const [speedDialRight, setSpeedDialRight] = useState<number>(2);
  const [containerScrollbarWidth, setContainerScrollbarWidth] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const closeFilterModal = useCallback(() => setIsFilterOpen(false), []);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  const loadingArray = useMemo(() => {
    // @ts-ignore
    const numMap: number[] = [...Array(limit).keys()];

    return numMap.map((index) => (
      <Grid
        xs={12}
        sm={6}
        lg={4}
        xl={3}
        key={`${entity}-loading-${index}`}
        item
      >
        {listingSkeletonComponent}
      </Grid>
    ));
  }, [limit]);

  useLayoutEffect(() => {
    if (trigger) {
      setWindowHeight(window.innerHeight + windowHeight);
    }
  }, [trigger]);

  useLayoutEffect(() => {
    /**
     * We calculate the scrollbar width so we can calculate the computed value of the container (padding, width, scrollbar)
     * We need to do this because by using CSS Grid and taking the lists out of the regular flow their widths are not constrained by the container
     * useLayoutEffect fires synchronously after all DOM mutations so we use this to read the DOM layout - https://reactjs.org/docs/hooks-reference.html#uselayouteffect
     */
    if (containerRef.current) {
      const width =
        containerRef.current.offsetWidth - containerRef.current.clientWidth;
      setSpeedDialRight(width === 0 ? 2 : 4);
      if (width !== containerScrollbarWidth) {
        setContainerScrollbarWidth(width);
      }
    }
    // @ts-ignore
  }, [containerRef, containerScrollbarWidth]);

  const handleScroll = useCallback(async () => {
    paginate();
  }, [isLoading, paginate]);

  useEffect(() => {
    if (trigger) {
      debouncedScroll();
    }
  }, [trigger]);

  const debouncedScroll = debounce(handleScroll, 250);

  const allSpeedDialItems: SpeedDialItem[] = useMemo(
    () => [
      ...speedDialItems,
      {
        icon: <SearchIcon />,
        onClick: () => setIsFilterOpen(true),
        tooltipTitle: 'Components.ListingSpeedDial.search',
      },
      {
        icon: <FavoriteIcon />,
        onClick: () => setIsFilterOpen(true),
        tooltipTitle: 'Components.ListingSpeedDial.favourite',
      },
      isTableView
        ? {
            icon: <WindowIcon />,
            onClick: () => setIsTableView(false),
            tooltipTitle: 'Components.ListingSpeedDial.gridView',
          }
        : {
            icon: <ListIcon />,
            onClick: () => setIsTableView(true),
            tooltipTitle: 'Components.ListingSpeedDial.tableView',
          },
    ],
    [speedDialItems, isTableView],
  );

  if (isTableView && headings && rowRender) {
    return (
      <Stack
        sx={{ height: '100%', overflowY: 'auto', p: 2 }}
        alignItems="center"
        ref={containerRef}
      >
        {entities.length !== 0 && (
          <Stack
            spacing={2}
            sx={{ maxWidth: 1600, width: '100%' }}
            alignItems="center"
          >
            <ClassicPaginationFilters
              filters={filters}
              search={searchProps}
              clearFilters={clearFilters}
            />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headings.map((heading) => (
                      <TableCell
                        key={heading.dataKey}
                        align={heading.align}
                        width={heading.width}
                      >
                        {heading.label && <Translate path={heading.label} />}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{entities.map(rowRender)}</TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                bottom: theme.spacing(2),
                position: 'fixed',
                right: theme.spacing(2),
              }}
            >
              <ListingSpeedDial
                speedDialRight={speedDialRight}
                items={allSpeedDialItems}
              />
            </Box>
          </Stack>
        )}
        {entities.length === 0 && !isLoading && noResultsComponent}
      </Stack>
    );
  }

  return (
    <Box sx={{ height: '100%', overflowY: 'auto', p: 2 }} ref={containerRef}>
      {entities.length !== 0 && (
        <>
          <Stack direction="row" justifyContent="center">
            <Grid container spacing={2} maxWidth={1600}>
              {entities.length > 0 &&
                entities.map((item) => {
                  const ent = item as unknown as ListingEntities;
                  return (
                    <Grid xs={12} sm={6} lg={4} xl={3} item key={ent.id}>
                      {itemRender(ent)}
                    </Grid>
                  );
                })}
              {isLoading && loadingArray}
            </Grid>
          </Stack>
          <Box
            sx={{
              bottom: theme.spacing(2),
              position: 'fixed',
              right: theme.spacing(2),
            }}
          >
            <ListingSpeedDial
              speedDialRight={speedDialRight}
              items={allSpeedDialItems}
            />
          </Box>
          <PaginationFilters
            isOpen={isFilterOpen}
            onClose={closeFilterModal}
            filters={filters}
            search={searchProps}
            clearFilters={clearFilters}
          />
        </>
      )}
      {entities.length === 0 && !isLoading && noResultsComponent}
    </Box>
  );
}

export default NewListingView;
