import AddIcon from '@mui/icons-material/Add';
import { Grid, Stack, TableCell, TableRow } from '@mui/material';
import type { ProcessedTeam, Team } from 'Modules/Digilab';
import {
  TeamMemberChip,
  TeamsListingItem,
  processTeams,
  useGetTeamsQuery,
} from 'Modules/Digilab';
import type { Digimon, DigimonPaginatedListFilter } from 'Modules/FieldGuide';
import {
  Attribute,
  DigimonListingItemSkeleton,
  DigimonStage,
  DigimonType,
  getAttributeLabel,
  getDigimonStageLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide';
import type { PaginationFilter, PaginationFilterOption } from 'Modules/Shared';
import {
  ListingView,
  PaginationFilterType,
  TableLink,
  useModalProps,
  usePrevious,
} from 'Modules/Shared';
import type { SpeedDialItem } from 'Modules/Shared/Components/ListingSpeedDial';
import type { ListingEntities, PaginationOptions } from 'Store';
import type { TranslationPath } from 'Translation';
import { useTranslation } from 'Translation';
import { debounce, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AddModal } from './modals';

const TeamsListing: React.FC = () => {
  const [shouldSkip, setShouldSkip] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string | undefined | null>();
  const [limit, setLimit] = useState<number>(20);
  const [options, setOptions] = useState<DigimonPaginatedListFilter>({});
  const prevCursor = usePrevious(cursor);
  const prevOptions = usePrevious(options);
  const [teams, setTeams] = useState<ProcessedTeam[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const completeOptions: PaginationOptions<DigimonPaginatedListFilter> =
    useMemo(
      () => ({ afterCursor: cursor, limit, ...options }),
      [limit, cursor, options],
    );
  const addModalProps = useModalProps();

  const teamsRTK = useGetTeamsQuery(completeOptions, { skip: shouldSkip });

  useEffect(() => {
    if (!teamsRTK.isLoading && teamsRTK.data?.items) {
      const received = teamsRTK.data.items
        ? processTeams(teamsRTK.data.items as unknown as Team[])
        : [];
      if (cursor) {
        setTeams((prevTeams) => {
          const merged = teamsRTK.data?.items
            ? ([...prevTeams, ...received] as ProcessedTeam[])
            : [];
          const newTeams = uniqBy(merged, 'id');

          return newTeams;
        });
      } else {
        setTeams(received);
      }

      setTimeout(() => setIsLoading(false), 500);
    }
  }, [teamsRTK]);

  const title = useTranslation('Pages.FieldGuide.DigimonListing.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.Digilab.breadcrumb'), to: '/digilab' },
    { label: useTranslation('Pages.Digilab.Teams.breadcrumb') },
  ];

  const searchProps = useMemo(
    () => ({
      id: 'teams-search',
      label:
        'Pages.FieldGuide.DigimonListing.paginationFilters.search.label' as TranslationPath,
      placeholder:
        'Pages.FieldGuide.DigimonListing.paginationFilters.search.placeholder' as TranslationPath,
    }),
    [],
  );

  const paginate = () => {
    if (teamsRTK.data?.cursor.afterCursor) {
      setCursor(teamsRTK.data.cursor.afterCursor);
    }
  };

  const clearState = () => {
    setIsLoading(true);
    setShouldSkip(true);
    setTeams([]);
    setCursor(undefined);
  };

  const clearFilters = () => {
    setOptions({});
    clearState();
  };

  const shouldFetch = () => setTimeout(() => setShouldSkip(false), 500);

  const updateFilters = (updatedFilter: DigimonPaginatedListFilter) => {
    setOptions((prevOptions) => ({ ...prevOptions, ...updatedFilter }));
    shouldFetch();
  };

  const setAttributeFilter = (e: any) => {
    const value = e.target.value;
    clearState();

    if (value === 'all') {
      updateFilters({ attribute: null });
    } else {
      updateFilters({ attribute: value });
    }
  };

  const setStageFilter = (e: any) => {
    const value = e.target.value;
    clearState();

    if (value === 'all') {
      updateFilters({ stage: null });
    } else {
      updateFilters({ stage: value });
    }
  };

  const setTypeFilter = (e: any) => {
    const value = e.target.value;
    clearState();

    if (value === 'all') {
      updateFilters({ type: null });
    } else {
      updateFilters({ type: value });
    }
  };

  const getLabelValue = (value?: string | null) => {
    if (value === null) {
      return 'all';
    }

    return value ?? '';
  };

  const digimonAttributes: PaginationFilterOption[] = useMemo(() => {
    const attributes = Object.values(Attribute);

    const allItem: PaginationFilterOption = {
      id: 'attribute-all',
      label: 'Components.Select.all',
      value: 'all',
    };
    const attributeMenuItem: PaginationFilterOption[] = attributes.map(
      (attribute) => ({
        id: `attribute-${attribute}`,
        label: getAttributeLabel(attribute) as TranslationPath,
        value: attribute as string,
      }),
    );

    return [allItem, ...attributeMenuItem];
  }, []);

  const digimonStages: PaginationFilterOption[] = useMemo(() => {
    const stages = Object.values(DigimonStage);

    const allItem: PaginationFilterOption = {
      id: 'stage-all',
      label: 'Components.Select.all',
      value: 'all',
    };
    const stageMenuItem: PaginationFilterOption[] = stages.map((stage) => ({
      id: `stage-${stage}`,
      label: getDigimonStageLabel(stage) as TranslationPath,
      value: stage as string,
    }));

    return [allItem, ...stageMenuItem];
  }, []);

  const digimonTypes: PaginationFilterOption[] = useMemo(() => {
    const types = Object.values(DigimonType);

    const allItem: PaginationFilterOption = {
      id: 'type-all',
      label: 'Components.Select.all',
      value: 'all',
    };
    const typeMenuItem: PaginationFilterOption[] = types.map((type) => ({
      id: `type-${type}`,
      label: getDigimonTypeLabel(type) as TranslationPath,
      value: type as string,
    }));

    return [allItem, ...typeMenuItem];
  }, []);

  const renderItem = useCallback((item: ListingEntities) => {
    const entity = item as unknown as Team;
    return <TeamsListingItem key={entity.id} team={entity} />;
  }, []);

  const renderRow = useCallback((item: ListingEntities) => {
    const team = item as unknown as Team;
    return (
      <TableRow key={team.id}>
        <TableCell>
          <TableLink to={`/digilab/teams/${team.id}`}>{team.name}</TableLink>
        </TableCell>
        <TableCell>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <TeamMemberChip
              member={{
                digimon: team.members[0].member?.digimon as Digimon,
                name: team.members[0].member?.nickname,
              }}
            />
            <TeamMemberChip
              member={{
                digimon: team.members[1].member?.digimon as Digimon,
                name: team.members[1].member?.nickname,
              }}
            />
            <TeamMemberChip
              member={{
                digimon: team.members[2].member?.digimon as Digimon,
                name: team.members[2].member?.nickname,
              }}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[3].member?.digimon as Digimon,
                  name: team.members[3].member?.nickname,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[4].member?.digimon as Digimon,
                  name: team.members[4].member?.nickname,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[5].member?.digimon as Digimon,
                  name: team.members[5].member?.nickname,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[6].member?.digimon as Digimon,
                  name: team.members[6].member?.nickname,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[7].member?.digimon as Digimon,
                  name: team.members[7].member?.nickname,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[8].member?.digimon as Digimon,
                  name: team.members[8].member?.nickname,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[9].member?.digimon as Digimon,
                  name: team.members[9].member?.nickname,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TeamMemberChip
                member={{
                  digimon: team.members[10].member?.digimon as Digimon,
                  name: team.members[10].member?.nickname,
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{team.maxPartyMemory}</TableCell>
      </TableRow>
    );
  }, []);

  const filters: PaginationFilter[] = useMemo(
    () => [
      {
        id: 'digimon-attribute',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.attribute.label' as TranslationPath,
        onChange: setAttributeFilter,
        options: digimonAttributes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.attribute),
      },
      {
        id: 'digimon-stage',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.stage.label' as TranslationPath,
        onChange: setStageFilter,
        options: digimonStages,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.stage),
      },
      {
        id: 'digimon-type',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.type.label' as TranslationPath,
        onChange: setTypeFilter,
        options: digimonTypes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.type),
      },
    ],
    [
      digimonAttributes,
      digimonStages,
      digimonTypes,
      options.attribute,
      options.stage,
      options.type,
    ],
  );

  const speedDialItems: SpeedDialItem[] = useMemo(
    () => [
      {
        icon: <AddIcon />,
        onClick: () => addModalProps.onOpen(),
        tooltipTitle: 'Pages.Digilab.Teams.speedDial.add' as TranslationPath,
      },
    ],
    [],
  );

  return (
    <>
      <ListingView
        entity="teams"
        isLoading={!!teamsRTK.isLoading || isLoading}
        paginate={paginate}
        entities={teams as any}
        breadcrumbs={breadcrumbs}
        title={title}
        listingSkeletonComponent={<DigimonListingItemSkeleton />}
        itemRender={renderItem}
        filters={filters}
        getEntitiesCount={40}
        searchProps={searchProps}
        clearFilters={clearFilters}
        speedDialItems={speedDialItems}
        noResultsComponent={<p>This is a test</p>}
        rowRender={renderRow}
        headings={[
          {
            dataKey: 'name',
            label: 'Pages.Digilab.Teams.tableListing.headings.name',
            width: 200,
          },
          {
            dataKey: 'activeMembers',
            label: 'Pages.Digilab.Teams.tableListing.headings.activeMembers',
            width: 200,
          },
          {
            dataKey: 'reserveMembers',
            label: 'Pages.Digilab.Teams.tableListing.headings.reserveMembers',
          },
          {
            dataKey: 'memoryUsage',
            label: 'Pages.Digilab.Teams.tableListing.headings.memoryUsage',
            width: 100,
          },
        ]}
      />
      <AddModal isOpen={addModalProps.isOpen} onClose={addModalProps.onClose} />
    </>
  );
};

export default TeamsListing;
