import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { SearchDTO, SearchResponse } from 'Modules/Shared';
import { SearchDomains } from 'Modules/Shared';
import type { StoreEntities } from 'Store';
import { api } from 'Store';
import queryString from 'query-string';

export const searchApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    search: build.query<SearchResponse, SearchDTO>({
      query: (
        options = { categories: [SearchDomains.All], simplified: false },
      ) =>
        `/search?${queryString.stringify(
          { ...options },
          { arrayFormat: 'bracket' },
        )}`,
    }),
  }),
});

export const { useSearchQuery } = searchApiSlice;
