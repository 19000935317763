import { PersistMethod } from '../Types';

const persistenceKey = 'cachedItems';

export function addToPersistedObjectsMap(
  key: string,
  method: PersistMethod,
): void {
  const stringifiedPersistenceMap = window.localStorage.getItem(persistenceKey);
  const persistenceMap: Record<string, PersistMethod> =
    stringifiedPersistenceMap ? JSON.parse(stringifiedPersistenceMap) : {};
  persistenceMap[key] = method;

  window.localStorage.setItem(persistenceKey, JSON.stringify(persistenceMap));
}

export function removefromPersistedObjectsMap(key: string): void {
  const stringifiedPersistenceMap = window.localStorage.getItem(persistenceKey);
  const persistenceMap: Record<string, PersistMethod> =
    stringifiedPersistenceMap ? JSON.parse(stringifiedPersistenceMap) : {};
  delete persistenceMap[key];

  window.localStorage.setItem(persistenceKey, JSON.stringify(persistenceMap));
}

export function removeAllPersistedItems(): void {
  const stringifiedPersistenceMap = window.localStorage.getItem(persistenceKey);
  const persistenceMap: Record<string, PersistMethod> =
    stringifiedPersistenceMap ? JSON.parse(stringifiedPersistenceMap) : {};

  Object.entries(persistenceMap).forEach(([key, value]) => {
    switch (value) {
      case PersistMethod.LocalStorage:
        window.localStorage.removeItem(key);

        break;

      case PersistMethod.SessionStorage:
        window.sessionStorage.removeItem(key);

        break;

      default:
        break;
    }
  });

  window.localStorage.setItem(persistenceKey, JSON.stringify({}));
}
