/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './styles';

export const DescriptionList: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { descriptionList } = styles;

  return (
    <Box css={descriptionList} component="dl">
      {children}
    </Box>
  );
};

export const DescriptionListRow: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { descriptionListRow } = styles;

  return <div css={descriptionListRow}>{children}</div>;
};

export const DescriptionTerm: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { descriptionListCell } = styles;

  return <dt css={descriptionListCell}>{children}</dt>;
};

export const DescriptionDetails: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { descriptionListCell } = styles;

  return <dd css={descriptionListCell}>{children}</dd>;
};
