import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { setPage } from 'Modules/Shared';
import { useTranslation } from 'Translation';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Dashboard(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const title = useTranslation('Pages.FieldGuide.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.FieldGuide.breadcrumb') },
  ];

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  return (
    <Box sx={{ p: 2 }}>
      <p>placeholder</p>
      {/* <Card /> */}
    </Box>
  );
}

export default Dashboard;
