/** @jsxImportSource @emotion/react */
import { Box, Card, CardActions, CardContent, Skeleton } from '@mui/material';
import { Translate } from 'Translation';
import React from 'react';

import styles from './styles';

const MoveLisitingItemSkeleton: React.FC = () => {
  const {
    avatarContainer,
    card,
    contents,
    definitionList,
    definitionListRow,
    definitionListCell,
    footer,
  } = styles;

  return (
    <Card sx={{ ...card }}>
      <CardContent>
        <Skeleton height={30} width={200} />
        <Box sx={contents}>
          <Box css={definitionList}>
            <div css={definitionListRow}>
              <dt css={definitionListCell}>
                <Translate path="Pages.FieldGuide.MovesListing.listingItem.levelAcquired" />
              </dt>
              <dd css={definitionListCell}>
                <Skeleton height={30} width={100} />
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListCell}>
                <Translate path="Pages.FieldGuide.MovesListing.listingItem.type" />
              </dt>
              <dd css={definitionListCell}>
                <Skeleton height={30} width={100} />
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListCell}>
                <Translate path="Pages.FieldGuide.MovesListing.listingItem.spUse" />
              </dt>
              <dd css={definitionListCell}>
                <Skeleton height={30} width={100} />
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListCell}>
                <Translate path="Pages.FieldGuide.MovesListing.listingItem.power" />
              </dt>
              <dd css={definitionListCell}>
                <Skeleton height={30} width={100} />
              </dd>
            </div>
          </Box>
          <Box sx={avatarContainer}>
            <Skeleton variant="circular" width={30} height={30} />
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={footer}>
        <Skeleton height={30} width={100} />
        <Skeleton height={30} width={100} />
      </CardActions>
    </Card>
  );
};

export default MoveLisitingItemSkeleton;
