enum Attribute {
  Dark = 'dark',
  Earth = 'earth',
  Electric = 'electric',
  Fire = 'fire',
  Light = 'light',
  Neutral = 'neutral',
  Plant = 'plant',
  Water = 'water',
  Wind = 'wind',
}

export default Attribute;
