import type { Digimon } from 'Modules/FieldGuide';

import type { Roster, RosterLevel } from './roster';
import type { RosterMove } from './rosterMove';
import type { RosterStats } from './rosterStats';

export enum TeamSlotState {
  Empty = 'empty',
  Used = 'used',
}

export enum TeamMemberType {
  Active = 'active',
  Reserve = 'reserve',
}

export interface TeamMember {
  id?: number;
  type: TeamMemberType;
  number: number;
  member?: Roster;
}

export interface ProcessedTeamMember extends TeamMember {
  state: TeamSlotState;
}

export interface TeamMemberDigivolutionHistory {
  id: number;
  level: RosterLevel;
  stats?: RosterStats[];
  moves?: RosterMove[];
  digimon: Partial<Digimon>;
}
