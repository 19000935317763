import { useCallback, useContext } from 'react';

import { themeContext } from '../Contexts';
import { getAccent } from '../Lib';
import type { ThemeAccent } from '../Types';

function useGetThemeAccent(): () => ThemeAccent {
  const state = useContext(themeContext);

  if (typeof state === 'undefined') {
    throw new Error(
      'useGetThemeAccent can only be used in the ThemeContextProvider',
    );
  }

  const getAppThemeAccent = useCallback(
    () => getAccent(state.accent),
    [state.accent],
  );

  return getAppThemeAccent;
}

export default useGetThemeAccent;
