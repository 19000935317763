import { useCallback, useMemo, useState } from 'react';

function useModalProps(isOpenByDefault: boolean = false) {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenByDefault);
  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const onToggle = useCallback(() => setIsOpen(!!isOpen), [isOpen, setIsOpen]);

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
  };
}

export default useModalProps;
