import { Avatar, Chip, TableCell, TableRow } from '@mui/material';
import {
  MoveListingItem,
  MoveListingItemSkeleton,
} from 'Modules/FieldGuide/Components';
import {
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getMoveTypeLabel,
} from 'Modules/FieldGuide/Lib';
import { useGetMovesQuery } from 'Modules/FieldGuide/Store';
import type {
  DigimonMove,
  MovesPaginatedListFilter,
} from 'Modules/FieldGuide/Types';
import { Attribute, MoveType } from 'Modules/FieldGuide/Types';
import type { PaginationFilter, PaginationFilterOption } from 'Modules/Shared';
import {
  NewListingView,
  PaginationFilterType,
  TableLink,
  usePagination,
} from 'Modules/Shared';
import type { ListingEntities, MovesListingRTK } from 'Store';
import type { TranslationPath } from 'Translation';
import { Translate, useTranslation } from 'Translation';
import type { ChangeEvent } from 'react';
import React, { useCallback, useMemo } from 'react';

function MovesListing(): JSX.Element {
  const {
    clearFilters,
    clearState,
    entities,
    isLoading,
    options,
    paginate,
    setOptions,
  } = usePagination<MovesListingRTK, MovesPaginatedListFilter>(
    useGetMovesQuery,
    40,
  );

  const title = useTranslation('Pages.FieldGuide.MovesListing.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    { label: useTranslation('Pages.FieldGuide.MovesListing.breadcrumb') },
  ];

  const searchProps = useMemo(
    () => ({
      id: 'moves-search',
      label:
        'Pages.FieldGuide.MovesListing.paginationFilters.search.label' as TranslationPath,
      placeholder:
        'Pages.FieldGuide.MovesListing.paginationFilters.search.placeholder' as TranslationPath,
    }),
    [],
  );

  const getLabelValue = (value?: string | null): string => {
    if (value === null) {
      return 'all';
    }

    return value ?? '';
  };

  const moveAttributes: PaginationFilterOption[] = useMemo(() => {
    const attributes = Object.values(Attribute);

    const allItem: PaginationFilterOption = {
      id: 'attribute-all',
      label: 'Components.Select.all',
      value: 'all',
    };
    const attributeMenuItem: PaginationFilterOption[] = attributes.map(
      (attribute) => ({
        id: `attribute-${attribute}`,
        label: getAttributeLabel(attribute) as TranslationPath,
        value: attribute as string,
      }),
    );

    return [allItem, ...attributeMenuItem];
  }, []);

  const moveTypes: PaginationFilterOption[] = useMemo(() => {
    const types = Object.values(MoveType);

    const allItem: PaginationFilterOption = {
      id: 'type-all',
      label: 'Components.Select.all',
      value: 'all',
    };
    const typeMenuItem: PaginationFilterOption[] = types.map((type) => ({
      id: `type-${type}`,
      label: getMoveTypeLabel(type) as TranslationPath,
      value: type as string,
    }));

    return [allItem, ...typeMenuItem];
  }, []);

  const renderItem = useCallback((item: ListingEntities) => {
    const move = item as DigimonMove;
    return <MoveListingItem key={move.id} move={move} />;
  }, []);

  const renderRow = useCallback((item: ListingEntities) => {
    const move = item as DigimonMove;
    return (
      <TableRow key={move.id}>
        <TableCell>
          <Avatar src={move.icon} />
        </TableCell>
        <TableCell>
          <TableLink to={`/field-guide/moves/${move.id}`}>
            {move.name}
          </TableLink>
        </TableCell>
        <TableCell>{move.power}</TableCell>
        <TableCell>{move.spCost}</TableCell>
        <TableCell>
          <Translate path={getMoveTypeLabel(move.type)} />
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              bgcolor: getAttributeColour(
                move ? move.attribute : Attribute.Neutral,
              ),
              color: getAttributeFontColour(
                move ? move.attribute : Attribute.Neutral,
              ),
            }}
            label={<Translate path={getAttributeLabel(move.attribute)} />}
          />
        </TableCell>
      </TableRow>
    );
  }, []);

  const filters: PaginationFilter[] = useMemo(() => {
    const setAttributeFilter = (e: ChangeEvent<HTMLSelectElement>): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ attribute: null });
      } else {
        setOptions({ attribute: value as Attribute });
      }
    };

    const setTypeFilter = (e: ChangeEvent<HTMLSelectElement>): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ type: null });
      } else {
        setOptions({ type: value as MoveType });
      }
    };

    return [
      {
        id: 'digimon-attribute',
        label:
          'Pages.FieldGuide.MovesListing.paginationFilters.attribute.label' as TranslationPath,
        onChange: setAttributeFilter,
        options: moveAttributes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.attribute),
      },
      {
        id: 'digimon-type',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.type.label' as TranslationPath,
        onChange: setTypeFilter,
        options: moveTypes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.type),
      },
    ];
  }, [
    clearState,
    moveAttributes,
    moveTypes,
    options.attribute,
    options.type,
    setOptions,
  ]);

  return (
    <NewListingView
      entity="moves"
      isLoading={isLoading}
      paginate={paginate}
      entities={entities}
      breadcrumbs={breadcrumbs}
      title={title}
      listingSkeletonComponent={<MoveListingItemSkeleton />}
      itemRender={renderItem}
      filters={filters}
      limit={40}
      clearFilters={clearFilters}
      searchProps={searchProps}
      rowRender={renderRow}
      headings={[
        {
          dataKey: 'avatar',
          label: 'Pages.FieldGuide.MovesListing.tableListing.headings.icon',
          width: 100,
        },
        {
          dataKey: 'name',
          label: 'Pages.FieldGuide.MovesListing.tableListing.headings.name',
        },
        {
          dataKey: 'power',
          label: 'Pages.FieldGuide.MovesListing.tableListing.headings.power',
        },
        {
          dataKey: 'spCost',
          label: 'Pages.FieldGuide.MovesListing.tableListing.headings.spUsage',
        },
        {
          dataKey: 'type',
          label: 'Pages.FieldGuide.MovesListing.tableListing.headings.type',
        },
        {
          dataKey: 'attribute',
          label:
            'Pages.FieldGuide.MovesListing.tableListing.headings.attribute',
        },
      ]}
    />
  );
}

export default MovesListing;
