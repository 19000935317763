export enum SearchDomains {
  All = 'all',
  Digimon = 'digimon',
  Item = 'item',
  Location = 'location',
  Move = 'move',
  Roster = 'roster',
  Team = 'team',
}

export interface SearchResponseItem {
  id: number;
  image?: string;
  name: string;
  type: string;
}

export type SearchResponse = Partial<
  Record<SearchDomains, Array<SearchResponseItem>>
> & { total: number };

export interface SearchDTO {
  categories?: SearchDomains[];
  simplified?: boolean;
  term?: string;
}
