/** @jsxImportSource @emotion/react */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import {
  getAttributeColour,
  getAttributeFontColour,
  getMoveTypeLabel,
} from 'Modules/FieldGuide/Lib';
import type { DigimonMove } from 'Modules/FieldGuide/Types';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListRow,
  DescriptionTerm,
} from 'Modules/Shared';
import { Translate } from 'Translation';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

const MoveLisitingItem: React.FC<{ move: DigimonMove }> = ({ move }) => {
  const { avatar, avatarContainer, button, card, contents, footer, title } =
    styles;
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(`/field-guide/moves/${move.id}`);
  };

  const bgColor = useMemo(() => getAttributeColour(move.attribute), [move]);
  const fontColor = useMemo(
    () => getAttributeFontColour(move.attribute),
    [move],
  );

  return (
    <Card sx={{ ...card, bgcolor: bgColor, color: fontColor }}>
      <CardContent>
        <Typography sx={{ ...title, color: fontColor }} gutterBottom>
          {move.name}
        </Typography>
        <Box sx={contents}>
          <DescriptionList>
            {move.levelAcquiredAt && (
              <DescriptionListRow>
                <DescriptionTerm>
                  <Translate path="Pages.FieldGuide.MovesListing.listingItem.levelAcquired" />
                  :
                </DescriptionTerm>
                <DescriptionDetails>{move.levelAcquiredAt}</DescriptionDetails>
              </DescriptionListRow>
            )}
            <DescriptionListRow>
              <DescriptionTerm>
                <Translate path="Pages.FieldGuide.MovesListing.listingItem.type" />
                :
              </DescriptionTerm>
              <DescriptionDetails>
                <Translate path={getMoveTypeLabel(move.type)} />
              </DescriptionDetails>
            </DescriptionListRow>
            <DescriptionListRow>
              <DescriptionTerm>
                <Translate path="Pages.FieldGuide.MovesListing.listingItem.spUse" />
                :
              </DescriptionTerm>
              <DescriptionDetails>{move.spCost}</DescriptionDetails>
            </DescriptionListRow>
            {!!move.power && (
              <DescriptionListRow>
                <DescriptionTerm>
                  <Translate path="Pages.FieldGuide.MovesListing.listingItem.power" />
                  :
                </DescriptionTerm>
                <DescriptionDetails>{move.power}</DescriptionDetails>
              </DescriptionListRow>
            )}
          </DescriptionList>
          <Box sx={avatarContainer}>
            <Avatar sx={avatar} alt={move.name} src={move.icon} />
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={footer}>
        <Button
          onClick={navigateTo}
          sx={{ ...button, color: fontColor }}
          size="small"
        >
          See more
        </Button>
        <Button sx={{ ...button, color: fontColor }} size="small">
          Like
        </Button>
      </CardActions>
    </Card>
  );
};

export default MoveLisitingItem;
