/** @jsxImportSource @emotion/react */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import type { Roster } from 'Modules/Digilab';
import type { Digimon } from 'Modules/FieldGuide';
import { Translate } from 'Translation';
import React, { useState } from 'react';

import { About, Digivolution, EditMoves, Equipment, Moves } from './tabs';

function Body({
  digimon,
  isEditMode,
  roster,
}: {
  digimon: Digimon;
  isEditMode: boolean;
  roster: Roster;
}): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<string>('1');

  const handleTabClick = (event: any, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
        <TabList onChange={handleTabClick} aria-label="tabs">
          <Tab
            label={
              <Translate path="Pages.Digilab.DigimonTeamMember.tabs.about" />
            }
            value="1"
            sx={{ fontWeight: 'bold' }}
          />
          <Tab
            label={
              <Translate path="Pages.Digilab.DigimonTeamMember.tabs.equipment" />
            }
            value="2"
            sx={{ fontWeight: 'bold' }}
          />
          <Tab
            label={
              <Translate path="Pages.Digilab.DigimonTeamMember.tabs.moves" />
            }
            value="3"
            sx={{ fontWeight: 'bold' }}
          />
          <Tab
            label={
              <Translate path="Pages.Digilab.DigimonTeamMember.tabs.digivolution" />
            }
            value="4"
            sx={{ fontWeight: 'bold' }}
          />
          <Tab
            label={
              <Translate path="Pages.Digilab.DigimonTeamMember.tabs.battleHistory" />
            }
            value="5"
            sx={{ fontWeight: 'bold' }}
          />
        </TabList>
      </Box>
      <TabPanel value="1">
        <About roster={roster} />
      </TabPanel>
      <TabPanel value="2">
        <Equipment roster={roster} />
      </TabPanel>
      <TabPanel value="3">
        <Moves roster={roster} />
      </TabPanel>
      <TabPanel value="4">
        <Digivolution roster={roster} />
      </TabPanel>
    </TabContext>
  );
}

export default Body;
