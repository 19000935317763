import {
  digimonApiSlice,
  itemsApiSlice,
  locationsApiSlice,
  movesApiSlice,
  supportSkillsApiSlice,
} from './slices';

export const fieldGuideSlices = {
  [digimonApiSlice.reducerPath]: digimonApiSlice.reducer,
  [itemsApiSlice.reducerPath]: itemsApiSlice.reducer,
  [locationsApiSlice.reducerPath]: locationsApiSlice.reducer,
  [movesApiSlice.reducerPath]: movesApiSlice.reducer,
  [supportSkillsApiSlice.reducerPath]: supportSkillsApiSlice.reducer,
};

export {
  useGetDigimonQuery,
  useGetDigimonByIDQuery,
  useGetItemsQuery,
  useGetItemByIDQuery,
  useGetLocationsQuery,
  useGetLocationByIDQuery,
  useGetMovesQuery,
  useGetMoveByIDQuery,
  useGetSupportSkillsQuery,
  useGetSupportSkillByIDQuery,
} from './slices';

export type { DigimonByIDQuery } from './slices';
