import type { SxProps, Theme } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import type { TranslationPath } from 'Translation';
import { useTranslation } from 'Translation';
import * as React from 'react';

import type { ComboBoxOption } from './types';

interface Props {
  disablePortal?: boolean;
  fullWidth?: boolean;
  label: TranslationPath;
  id?: string;
  options: ComboBoxOption[];
  sx?: SxProps<Theme> | undefined;
  value?: string;
  onChange?: (e: any, newValue: ComboBoxOption | null) => void;
  grouped?: boolean;
}

function groupedByFunc(option: ComboBoxOption) {
  return option.group ?? '';
}

const ComboBox: React.FC<Props> = ({
  disablePortal = false,
  fullWidth = false,
  id,
  options,
  label,
  sx,
  grouped = false,
  value,
  onChange,
}) => {
  const translatedLabel = useTranslation(label);

  return (
    <Autocomplete
      disablePortal={disablePortal}
      fullWidth={fullWidth}
      id={id}
      options={options}
      getOptionDisabled={(option) => option.disabled === true}
      sx={sx}
      renderInput={(params) => (
        <TextField {...params} label={translatedLabel} />
      )}
      onChange={onChange}
      groupBy={grouped ? groupedByFunc : undefined}
    />
  );
};

export default ComboBox;
