import { DigimonType } from 'Modules/FieldGuide/Types';
import type { PaginationFilterOption } from 'Modules/Shared/Components';
import type { TranslationPath } from 'Translation';

import getDigimonTypeLabel from './getDigimonTypeLabel';

function getDigimonTypesFilterOptions(): PaginationFilterOption[] {
  const types = Object.values(DigimonType);

  const allItem: PaginationFilterOption = {
    id: 'type-all',
    label: 'Components.Select.all',
    value: 'all',
  };
  const typeMenuItem: PaginationFilterOption[] = types.map((type) => ({
    id: `type-${type}`,
    label: getDigimonTypeLabel(type) as TranslationPath,
    value: type as string,
  }));

  return [allItem, ...typeMenuItem];
}

export default getDigimonTypesFilterOptions;
