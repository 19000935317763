import { useAuth0 } from '@auth0/auth0-react';
import { useLogto } from '@logto/react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import { authContext } from 'Modules/Auth';
import { useGetMeQuery } from 'Modules/Profile';
import { LoginModal, toggleDrawer } from 'Modules/Shared';
import type { RootState } from 'Store';
import { drawerWidth, white } from 'Theme';
import { useTranslation } from 'Translation';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

function AppBar(): JSX.Element {
  const navigate = useNavigate();
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<any>(null);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState<boolean>(false);
  const { appBarBreadcrumbs, appBarStyling, isAppBarLoading, isDrawerOpen } =
    useSelector((state: RootState) => state.ui);

  const { signIn, isAuthenticated, signOut } = useLogto();
  // const { loginWithRedirect } = useAuth0();
  const authCtx = useContext(authContext);
  const userQuery = useGetMeQuery({});

  const user = userQuery.data;
  const hasFetchedUser = userQuery.isSuccess;
  const userIsLoggedIn = useMemo(
    () => !!user && !!hasFetchedUser,
    [user, hasFetchedUser],
  );

  const dispatch = useDispatch();
  const theme = useTheme();

  const openDrawer = useCallback(() => {
    dispatch(toggleDrawer(true));
  }, []);

  const fontColor = useMemo(
    () => (appBarStyling.color ? appBarStyling.color : white),
    [appBarStyling],
  );

  const handleAccountMenuOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAccountMenuAnchorEl(e.currentTarget);
    },
    [],
  );

  const handleAccountMenuClose = useCallback(() => {
    setAccountMenuAnchorEl(null);
  }, []);

  const handleOpenLoginDialog = useCallback(() => {
    setIsLoginDialogOpen(true);
  }, []);

  const handleCloseLoginDialog = useCallback(() => {
    setIsLoginDialogOpen(false);
  }, []);

  const trigger = useScrollTrigger();

  useEffect(() => {
    if (authCtx.isAuthenticated && !hasFetchedUser && !userIsLoggedIn) {
      userQuery.refetch();
    }
  }, [authCtx, hasFetchedUser, userQuery, userIsLoggedIn]);

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <MuiAppBar
          sx={{
            ...appBarStyling,
            marginLeft: isDrawerOpen ? `${drawerWidth}px` : 0,
            transition: theme.transitions.create(['margin', 'width'], {
              duration: theme.transitions.duration.enteringScreen,
              easing: theme.transitions.easing.easeOut,
            }),
            width: isDrawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
          }}
        >
          <Toolbar>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexGrow={1}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, ...(isDrawerOpen && { display: 'none' }) }}
                onClick={openDrawer}
              >
                <MenuIcon />
              </IconButton>
              {isAppBarLoading ? (
                <Box sx={{ width: '200px' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={
                    <NavigateNextIcon
                      fontSize="small"
                      sx={{ color: fontColor }}
                    />
                  }
                >
                  {appBarBreadcrumbs.map((breadcrumb) => {
                    if (breadcrumb.to) {
                      return (
                        <Link
                          underline="none"
                          color={white}
                          to={breadcrumb.to}
                          component={RouterLink}
                          key={breadcrumb.to}
                        >
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              color: fontColor,
                              flexGrow: 1,
                              fontWeight: 'bold',
                            }}
                          >
                            {breadcrumb.label}
                          </Typography>
                        </Link>
                      );
                    }

                    return (
                      <Typography
                        key={breadcrumb.label}
                        variant="h6"
                        component="div"
                        sx={{
                          color: fontColor,
                          flexGrow: 1,
                          fontWeight: 'bold',
                        }}
                      >
                        {breadcrumb.label}
                      </Typography>
                    );
                  })}
                </Breadcrumbs>
              )}
            </Box>
            <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
              {!authCtx.isAuthenticated && (
                <Button
                  color="inherit"
                  onClick={async (): Promise<void> => {
                    signIn(`${process.env.REACT_APP_URL}/callback`);
                    // await loginWithRedirect();
                  }}
                >
                  Login
                </Button>
              )}
              {authCtx.isAuthenticated && (
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="account-menu"
                  aria-haspopup="true"
                  onClick={handleAccountMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </MuiAppBar>
      </Slide>
      <Menu
        anchorEl={accountMenuAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        id="user-profile-menu"
        keepMounted
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={!!accountMenuAnchorEl && userIsLoggedIn}
        onClose={handleAccountMenuClose}
      >
        <MenuItem
          onClick={() => {
            navigate('/profile');
            handleAccountMenuClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleAccountMenuClose}>My account</MenuItem>
        <MenuItem onClick={authCtx.logout}>Logout</MenuItem>
      </Menu>
      <LoginModal onClose={handleCloseLoginDialog} isOpen={isLoginDialogOpen} />
    </>
  );
}

export default AppBar;
