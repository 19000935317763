import type { Digimon } from '.';

export enum ItemCategory {
  Consumable = 'consumable',
  Equipment = 'equipment',
  KeyItem = 'key-item',
  Medal = 'medal',
}

export enum ItemEffectStat {
  ATK = 'ATK',
  DEF = 'DEF',
  INT = 'INT',
  HP = 'HP',
  SP = 'SP',
  SPD = 'SPD',
  EXP = 'EXP',
  MEM = 'MEM',
  EVA = 'EVA',
  ACU = 'ACU',
  CRT = 'CRT',
  CAM = 'CAM',
  Revival = 'revival',
  HPSP = 'HP & SP',
}

export enum ItemEffectDuration {
  FiveTurns = '5-turns',
  OnUse = 'on-use',
  Permanent = 'permanent',
  PermanentEquip = 'permanent-equiped',
}

export enum ItemEffectTarget {
  Digifarm = 'digifarm',
  Digivice = 'digivice',
  Single = 'single',
  Team = 'team',
}

export enum ItemEffectUnit {
  None = 'none',
  Percentage = 'percentage',
}

export interface ItemEffect {
  id: number;
  name: ItemEffectStat;
  value: number;
  duration: ItemEffectDuration;
  target: ItemEffectTarget;
  unit: ItemEffectUnit;
}

export interface Item {
  id: number;
  name: string;
  icon: string;
  category: ItemCategory;
  description: string;
  price: number;
  soldAt: any[];
  droppedBy: Digimon[];
  effects?: ItemEffect[];
  medalNumber?: number;
}
