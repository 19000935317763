import { Stack, Typography } from '@mui/material';
import { themeContext } from 'Theme';
import { useSnackbar } from 'notistack';
import type { SnackbarKey, VariantType } from 'notistack';
import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import OneSignal from 'react-onesignal';
import type { TypeOptions } from 'react-toastify';
import { toast } from 'react-toastify';

import { MoreInfoNotification } from '../Components';
import type { NotificationToast } from '../Types';

interface Props {
  // notify: ({
  //   title,
  //   content,
  //   variant,
  // }: {
  //   title: string;
  //   content?: string;
  //   variant?: VariantType;
  // }) => SnackbarKey;
  // simpleNotify: (title: string, variant: VariantType) => SnackbarKey;
  notify: ({
    id,
    title,
    content,
    variant,
  }: {
    id?: string;
    title: string;
    content?: string;
    variant?: TypeOptions;
  }) => unknown;
  simpleNotify: (title: string, variant: TypeOptions) => unknown;
}

export const notificationContext = React.createContext<Props>({
  notify: ({
    title,
    content,
    variant,
  }: {
    title: string;
    content?: string;
    variant?: TypeOptions;
  }) => '',
  simpleNotify: (title: string, variant: TypeOptions = 'info') => '',
});

function NotificationProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const themeCtx = useContext(themeContext);
  const { enqueueSnackbar } = useSnackbar();

  // const notify = ({
  //   title,
  //   content,
  //   variant,
  // }: {
  //   title: string;
  //   content?: string;
  //   variant?: VariantType;
  // }): SnackbarKey =>
  //   enqueueSnackbar(title, {
  //     variant: variant || 'info',
  //   });

  // const simpleNotify = (
  //   title: string,
  //   variant: VariantType = 'info',
  // ): SnackbarKey =>
  //   enqueueSnackbar(title, {
  //     variant,
  //   });

  const notify = ({
    id,
    title,
    content,
    type = 'default',
  }: {
    id?: string;
    title: string;
    content?: string;
    type?: TypeOptions;
  }): unknown =>
    toast(<MoreInfoNotification title={title} content={content} />, {
      theme: themeCtx.notificationTheme,
      toastId: id,
      type,
    });

  const simpleNotify = (
    title: string,
    type: TypeOptions = 'default',
  ): unknown => toast(title, { theme: themeCtx.notificationTheme, type });

  OneSignal.on('notificationDisplay', (data: NotificationToast) => {
    console.log("The user's subscription state is now:");
    console.log(data);
    notify({
      content: data.content,
      id: data.id,
      title: data.heading,
      type: data.data?.type ?? 'info',
    });
  });

  OneSignal.getSubscription((data: boolean) => {
    notify({
      content:
        'This is a toast, just some content to fill this space, lets see how this works.',
      id: 'getSubscription',
      title: 'getSubscription',
      type: 'info',
    });
  });

  return (
    <notificationContext.Provider
      value={{
        notify,
        simpleNotify,
      }}
    >
      {children}
    </notificationContext.Provider>
  );
}

export default NotificationProvider;
