import type { TranslationPath } from 'Translation/Types';
import { useTranslation as usei18Translation } from 'react-i18next';

function useTranslation(
  path: TranslationPath,
  toInterpolate?: Record<string, unknown>,
): string {
  return usei18Translation().t(path, toInterpolate);
}

export default useTranslation;
