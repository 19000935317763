import type { Item, ItemEffect } from 'Modules/FieldGuide';
import { ItemEffectTarget } from 'Modules/FieldGuide';

import type { Roster } from '../Types';

interface TeamMemberStat {
  id: number;
  name: string;
  value: number;
  increasedBy: number;
  maxValue: number;
}

interface ProcessedStats {
  basic: {
    cam: TeamMemberStat;
    abi: TeamMemberStat;
  };
  progressable: TeamMemberStat[];
}

function generateStats(roster: Roster) {
  const progressable: TeamMemberStat[] = [];
  const basicStats: any = {};
  const equipmentStats: Record<string, number> = {};

  if (roster.equipment) {
    roster.equipment.forEach((equipment) => {
      const item = equipment.item as Item;
      if (item.effects) {
        item.effects.forEach((effect: ItemEffect) => {
          if (effect.target === ItemEffectTarget.Single) {
            const existingStat = equipmentStats[effect.name] ?? 0;
            const newStatValue = effect.value + existingStat;
            equipmentStats[effect.name] = newStatValue;
          }
        });
      }
    });
  }

  if (roster.stats) {
    roster.stats.forEach((stat) => {
      if (stat.name === 'camaradarie' || stat.name === 'ability') {
        if (stat.name === 'camaradarie') {
          basicStats['cam'] = stat;
        } else {
          basicStats['abi'] = stat;
        }
      } else {
        if (equipmentStats[stat.name]) {
          const toIncreaseBy = equipmentStats[stat.name];
          progressable.push({
            ...stat,
            increasedBy: stat.increasedBy + toIncreaseBy,
            value: stat.value + toIncreaseBy,
          });
        } else {
          progressable.push(stat);
        }
      }
    });
  }

  return {
    basic: basicStats,
    progressable,
  } as unknown as ProcessedStats;
}

export default generateStats;
