import { TableCell, TableRow } from '@mui/material';
import {
  LocationListingItem,
  MoveListingItemSkeleton,
} from 'Modules/FieldGuide/Components';
import { getAvailableAtLabel } from 'Modules/FieldGuide/Lib';
import { useGetLocationsQuery } from 'Modules/FieldGuide/Store';
import type {
  Location,
  LocationsPaginatedListFilter,
} from 'Modules/FieldGuide/Types';
import type { PaginationFilter } from 'Modules/Shared';
import {
  NewListingView,
  PaginationFilterType,
  TableLink,
  usePagination,
} from 'Modules/Shared';
import type { ListingEntities, LocationsListingRTK } from 'Store';
import type { TranslationPath } from 'Translation';
import { Translate, useTranslation } from 'Translation';
import type { ChangeEvent } from 'react';
import React, { useCallback, useMemo } from 'react';

function LocationsListing(): JSX.Element {
  const {
    clearFilters,
    clearState,
    entities,
    isLoading,
    options,
    paginate,
    setOptions,
  } = usePagination<LocationsListingRTK, LocationsPaginatedListFilter>(
    useGetLocationsQuery,
    40,
  );

  const title = useTranslation('Pages.FieldGuide.LocationsListing.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    { label: useTranslation('Pages.FieldGuide.LocationsListing.breadcrumb') },
  ];

  const renderItem = useCallback((item: ListingEntities) => {
    const location = item as Location;
    return <LocationListingItem key={location.id} location={location} />;
  }, []);

  const renderRow = useCallback((item: ListingEntities) => {
    const location = item as Location;
    return (
      <TableRow key={location.id}>
        <TableCell>
          <TableLink to={`/field-guide/locations/${location.id}`}>
            {location.name}
          </TableLink>
        </TableCell>
        <TableCell>
          <Translate path={getAvailableAtLabel(location.availableAt)} />
        </TableCell>
      </TableRow>
    );
  }, []);

  const filters: PaginationFilter[] = useMemo(() => {
    const setParentsFilter = (e: ChangeEvent<HTMLInputElement>): void => {
      const parents = e.target.checked;
      clearState();

      if (parents) {
        setOptions({ parents });
      } else {
        setOptions({ parents: undefined });
      }
    };

    return [
      {
        id: 'location-parents-only',
        label:
          'Pages.FieldGuide.LocationsListing.paginationFilters.parentsOnly.label' as TranslationPath,
        onChange: setParentsFilter,
        type: PaginationFilterType.Switch,
        value: !!options.parents,
      },
    ];
  }, [clearState, options.parents, setOptions]);

  const searchProps = useMemo(
    () => ({
      id: 'locations-search',
      label:
        'Pages.FieldGuide.LocationsListing.paginationFilters.search.label' as TranslationPath,
      placeholder:
        'Pages.FieldGuide.LocationsListing.paginationFilters.search.placeholder' as TranslationPath,
    }),
    [],
  );

  return (
    <NewListingView
      entity="locations"
      isLoading={isLoading}
      paginate={paginate}
      entities={entities}
      breadcrumbs={breadcrumbs}
      title={title}
      listingSkeletonComponent={<MoveListingItemSkeleton />}
      itemRender={renderItem}
      filters={filters}
      limit={40}
      searchProps={searchProps}
      clearFilters={clearFilters}
      rowRender={renderRow}
      headings={[
        {
          dataKey: 'name',
          label: 'Pages.FieldGuide.LocationsListing.tableListing.headings.name',
        },
        {
          dataKey: 'availableAt',
          label:
            'Pages.FieldGuide.LocationsListing.tableListing.headings.availableAt',
        },
      ]}
    />
  );
}

export default LocationsListing;
