/** @jsxImportSource @emotion/react */
import { Divider, Grid, Paper, Stack, Tab, Typography } from '@mui/material';
import type { ProcessedTeam, UpdateMemberDTO } from 'Modules/Digilab';
import { TeamMemberSlot, usePatchTeamMutation } from 'Modules/Digilab';
import { useModalProps } from 'Modules/Shared';
import { white } from 'Theme';
import { Translate, useTranslation } from 'Translation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddModal } from '../../modals';

interface Props {
  team: ProcessedTeam;
}

const Members: React.FC<Props> = ({ team }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [patchTeam, result] = usePatchTeamMutation();
  const [selectedSlot, setSelectedSlot] = useState<number>(1);
  const [selectedID, setSelectedID] = useState<number>(0);
  const navigate = useNavigate();
  const addModalProps = useModalProps();

  useEffect(() => {
    if (!result.isLoading) {
      if (result.isSuccess) {
        enqueueSnackbar('Removed from team', { variant: 'success' });
      } else if (result.isError) {
        enqueueSnackbar('Unable to remove from team', { variant: 'error' });
      }
    }
  }, [result]);

  const removeFromTeam = useCallback(
    (id?: number) => {
      if (id === undefined) {
        throw new Error('Wobbly');
      }

      const updatedTeamMembers: UpdateMemberDTO[] = team.members
        .filter((member) => member.id !== id && member.id !== undefined)
        .map((member) => ({
          id: member.id,
          number: member.number,
          roster: Number(member.member?.id),
        }));

      patchTeam({
        id: team.id,
        members: updatedTeamMembers,
      });
    },
    [team],
  );

  const openAddModal = (slotNumber: number) => {
    setSelectedSlot(slotNumber);
    setSelectedID(0);
    addModalProps.onOpen();
  };

  const navigateTo = (id: number) => {
    navigate(`/digilab/roster/${id}`);
  };

  const teamIds: UpdateMemberDTO[] = useMemo(() => {
    const ids: UpdateMemberDTO[] = [];

    team.members.forEach((member) => {
      if (member.id && member.member?.id) {
        ids.push({
          id: member.id,
          number: member.number,
          roster: member.member.id,
        });
      }
    });

    return ids;
  }, [team]);

  return (
    <>
      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={{ md: 4, xs: 2 }}
        sx={{ flex: 1, height: '100%' }}
      >
        <Stack flex={{ lg: 0.35, md: 0.5, xs: 1 }} sx={{ height: '100%' }}>
          <Typography component="h2" variant="h3" sx={{ color: white, mb: 1 }}>
            {useTranslation('Pages.Digilab.Team.activeTeamLabel')}
          </Typography>
          <Stack spacing={2}>
            <TeamMemberSlot
              slot={team.members[0]}
              navigate={navigateTo}
              addMember={openAddModal}
              removeFromTeam={removeFromTeam}
            />
            <TeamMemberSlot
              slot={team.members[1]}
              navigate={navigateTo}
              addMember={openAddModal}
              removeFromTeam={removeFromTeam}
            />
            <TeamMemberSlot
              slot={team.members[2]}
              navigate={navigateTo}
              addMember={openAddModal}
              removeFromTeam={removeFromTeam}
            />
          </Stack>
        </Stack>
        <Stack flex={1} sx={{ height: '100%' }}>
          <Typography component="h2" variant="h3" sx={{ color: white, mb: 1 }}>
            {useTranslation('Pages.Digilab.Team.reservesTeamLabel')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[3]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[4]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[5]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[6]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[7]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[8]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[9]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
            <Grid item xs={12} lg={5.5} xl={4}>
              <TeamMemberSlot
                slot={team.members[10]}
                navigate={navigateTo}
                addMember={openAddModal}
                removeFromTeam={removeFromTeam}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <AddModal
        isOpen={addModalProps.isOpen}
        onClose={addModalProps.onClose}
        slotNumber={selectedSlot}
        team={teamIds}
        memberID={selectedID}
        teamID={team.id}
      />
    </>
  );
};

export default Members;
