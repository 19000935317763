/** @jsxImportSource @emotion/react */
import { Button, Grid, Stack, Tooltip } from '@mui/material';
import type { Roster } from 'Modules/Digilab';
import {
  DigivolutionConditionsTooltip,
  digimonMeetsDigivolutionConditions,
} from 'Modules/Digilab';
import type { Digimon } from 'Modules/FieldGuide';
import { DigimonListingItem } from 'Modules/FieldGuide';
import { useModalProps } from 'Modules/Shared';
import { TranslateTypography, useTranslation } from 'Translation';
import React, { useState } from 'react';

import { DigivolutionModal, DigivolutionModalMode } from '../../modals';

interface Props {
  roster: Roster;
}

const Digivolution: React.FC<Props> = ({ roster }) => {
  const digivolveModalProps = useModalProps();
  const [presetDigivolveTarget, setPresetDigivolveTarget] = useState<number>(0);
  const [modalMode, setModalMode] = useState<DigivolutionModalMode>(
    DigivolutionModalMode.Digivolve,
  );

  console.log(roster);

  return (
    <Stack>
      <Stack direction="row" mb={2}>
        <Button
          onClick={() => {
            setModalMode(DigivolutionModalMode.Digivolve);
            digivolveModalProps.onOpen();
          }}
        >
          {useTranslation(
            'Pages.Digilab.Roster.digivolutions.buttons.digivolve',
          )}
        </Button>
        <Button
          onClick={() => {
            setModalMode(DigivolutionModalMode.Dedigivolve);
            digivolveModalProps.onOpen();
          }}
        >
          {useTranslation(
            'Pages.Digilab.Roster.digivolutions.buttons.dedigivolve',
          )}
        </Button>
      </Stack>
      <Stack spacing={2} mb={2}>
        <TranslateTypography
          variant="h4"
          path="Pages.Digilab.Roster.digivolutions.potential.title"
        />
        <Grid container spacing={2}>
          {(roster.digimon.digivolutionPotential as Digimon[]).map(
            (potential, index) => {
              const matched = digimonMeetsDigivolutionConditions(
                roster,
                potential,
              );
              return (
                <Grid
                  item
                  sx={{ paddingLeft: index === 0 ? 0 : undefined }}
                  xs={
                    12 /
                    ((roster.digimon.digivolutionPotential as Digimon[])
                      .length ?? 1)
                  }
                  key={`digivolution-potential-${potential}`}
                >
                  <Tooltip
                    title={
                      <DigivolutionConditionsTooltip
                        target={potential.name}
                        conditions={matched}
                      />
                    }
                  >
                    <DigimonListingItem
                      digimon={potential}
                      disabled={!matched.matches.all}
                      primaryButton={{
                        label:
                          'Pages.Digilab.Roster.digivolutions.buttons.digivolve',
                        onClick: () => {
                          setPresetDigivolveTarget(potential.id);
                          digivolveModalProps.onOpen();
                        },
                      }}
                    />
                  </Tooltip>
                </Grid>
              );
            },
          )}
        </Grid>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Stack spacing={2}>
            <TranslateTypography
              variant="h4"
              path="Pages.Digilab.Roster.digivolutions.history.title"
            />
            <Stack spacing={2}>
              {roster.digivolutionHistory?.map((history, index) => (
                <DigimonListingItem
                  key={`digivolution-${history.id}`}
                  digimon={history.digimon}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <DigivolutionModal
        isOpen={digivolveModalProps.isOpen}
        onClose={digivolveModalProps.onClose}
        roster={roster}
        mode={modalMode}
        digivolveTo={presetDigivolveTarget}
      />
    </Stack>
  );
};

export default Digivolution;
