import type { TranslationPath } from 'Translation/Types';

import { MoveType } from '../Types';

function getMoveTypeLabel(moveType?: MoveType): TranslationPath {
  switch (moveType) {
    case MoveType.Magic:
      return 'FieldGuide.Digimon.moveType.magic';

    case MoveType.Physical:
      return 'FieldGuide.Digimon.moveType.physical';

    case MoveType.Support:
      return 'FieldGuide.Digimon.moveType.support';

    default:
      return 'FieldGuide.Digimon.moveType.magic';
  }
}

export default getMoveTypeLabel;
