/** @jsxImportSource @emotion/react */
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import type { CreateTeamDTO, Roster } from 'Modules/Digilab';
import {
  generateCreateRosterDTO,
  useCreateRosterMutation,
  useCreateTeamMutation,
  useGetRosterQuery,
} from 'Modules/Digilab';
import { getDigimonStageLabel } from 'Modules/FieldGuide';
import type { ComboBoxOption } from 'Modules/Shared';
import { MultiComboBox } from 'Modules/Shared';
import { Translate, useTranslation } from 'Translation';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface FormData {
  digimon: number[];
  name: string;
  memoryAvailable: number;
  isCurrentTeam: boolean;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function generateComboBoxOption(roster: Roster): ComboBoxOption {
  const digimon = roster.digimon;
  return {
    group: i18n.t(getDigimonStageLabel(digimon.stage)),
    label: roster.nickname,
    value: roster.id.toString(),
  };
}

const AddModal: React.FC<Props> = ({ isOpen = false, onClose }) => {
  const [createTeam, result] = useCreateTeamMutation();
  const [selectedDigimon, setSelectedDigimon] = useState<ComboBoxOption[]>([]);
  const rosterRTK = useGetRosterQuery({ limit: 350 });
  const { setValue, handleSubmit, reset, watch, control } = useForm({
    defaultValues: {
      digimon: [] as number[],
      isCurrentTeam: false,
      memoryAvailable: 0,
      name: '',
    },
  });

  const description = useTranslation(
    'Pages.Digilab.Teams.modals.add.description',
  );
  const digimon: Roster[] = rosterRTK.data?.items ?? [];
  const digimonOptions: ComboBoxOption[] = digimon.map(generateComboBoxOption);

  const watchedDigimon = watch('digimon', []);

  const onSubmit = (data: FormData) => {
    const teamMemoryUsed = data.digimon.reduce((prev, currentValue) => {
      const member: Roster | undefined = digimon.find(
        (digi) => digi.id === currentValue,
      );

      if (member) {
        return prev + (member.digimon.memoryUsage ?? 0);
      }

      return prev + 0;
    }, 0);

    console.log(teamMemoryUsed);

    const dto: CreateTeamDTO = {
      isCurrentTeam: !!data.isCurrentTeam,
      maxPartyMemory: Number(data.memoryAvailable),
      members: data.digimon.map((member, index) => ({
        number: index + 1,
        roster: member,
      })),
      name: data.name,
    };
    createTeam(dto);
  };

  useEffect(
    () => () => {
      if (!isOpen) {
        reset({ digimon: [], memoryAvailable: 0, name: '' });
        setSelectedDigimon([]);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    if (
      !result.isUninitialized &&
      result.isSuccess &&
      watchedDigimon.length !== 0
    ) {
      reset({ digimon: [], memoryAvailable: 0, name: '' });
      setSelectedDigimon([]);
      onClose();
    }
  }, [reset, result, onClose]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {useTranslation('Pages.Digilab.Teams.modals.add.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2} spacing={2}>
            <Grid container spacing={2} width="100%">
              <Grid item xs={12} sm={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel htmlFor="name" shrink={true}>
                        <Translate path="Pages.Digilab.Teams.modals.add.fields.name" />
                      </InputLabel>
                      <TextField
                        id="name"
                        label={
                          <Translate path="Pages.Digilab.Teams.modals.add.fields.name" />
                        }
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue('name', e.target.value)
                        }
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="memoryAvailable"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel htmlFor="memoryAvailable" shrink={true}>
                        <Translate path="Pages.Digilab.Teams.modals.add.fields.memoryAvailable" />
                      </InputLabel>
                      <TextField
                        id="memoryAvailable"
                        label={
                          <Translate path="Pages.Digilab.Teams.modals.add.fields.memoryAvailable" />
                        }
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue('memoryAvailable', Number(e.target.value))
                        }
                        variant="outlined"
                        type="number"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <MultiComboBox
              label="Pages.Digilab.RosterListing.modals.add.fields.digimon"
              options={digimonOptions}
              value={selectedDigimon}
              grouped
              onChange={(e, newValue) => {
                const values: number[] = [];

                if (newValue) {
                  newValue.forEach((value) => values.push(Number(value.value)));
                }

                setValue('digimon', values, { shouldValidate: true });

                if (newValue) {
                  setSelectedDigimon(newValue);
                }
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation('Pages.Digilab.Teams.modals.add.buttons.cancel')}
        </Button>
        <LoadingButton
          loading={result.isLoading}
          disabled={false}
          onClick={handleSubmit(onSubmit)}
        >
          {useTranslation('Pages.Digilab.Teams.modals.add.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
