import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { setPage } from 'Modules/Shared';
import { largeCornerRadius, theme, white } from 'Theme';
import { useTranslation } from 'Translation';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const styles = {
  button: {
    borderRadius: largeCornerRadius,
    color: white,
    fontWeight: 600,
    height: '75px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  fieldGuideButton: {
    '&:hover': {
      bgcolor: theme.palette.green.dark,
    },
    bgcolor: theme.palette.green.main,
  },
  itemsButton: {
    '&:hover': {
      bgcolor: theme.palette.yellow.dark,
    },
    bgcolor: theme.palette.yellow.main,
  },
  locationsButton: {
    '&:hover': {
      bgcolor: theme.palette.purple.dark,
    },
    bgcolor: theme.palette.purple.main,
  },
  movesButton: {
    '&:hover': {
      bgcolor: theme.palette.red.dark,
    },
    bgcolor: theme.palette.red.main,
  },
  newsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  supportSkillsButton: {
    '&:hover': {
      bgcolor: theme.palette.blue.dark,
    },
    bgcolor: theme.palette.blue.main,
  },
};

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    button,
    fieldGuideButton,
    movesButton,
    newsHeader,
    itemsButton,
    locationsButton,
    supportSkillsButton,
  } = styles;
  const title = useTranslation('Pages.FieldGuide.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.FieldGuide.breadcrumb') },
  ];

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={3} sx={{ borderRadius: largeCornerRadius, p: 2 }}>
        <Stack>
          <Typography
            component="h1"
            variant="h3"
            sx={{ fontWeight: 'bold', mb: 4 }}
          >
            What Digimon are you looking for?
          </Typography>
          <Box sx={{ mb: 2 }}>Seach goes here</Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <Button
                sx={{ ...button, ...fieldGuideButton }}
                variant="contained"
                onClick={() => navigate('/field-guide/digimon')}
              >
                Digimon
              </Button>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button
                sx={{ ...button, ...movesButton }}
                variant="contained"
                onClick={() => navigate('/field-guide/moves')}
              >
                Moves
              </Button>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button
                sx={{ ...button, ...supportSkillsButton }}
                variant="contained"
                onClick={() => navigate('/field-guide/support-skills')}
              >
                Support Skills
              </Button>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button
                sx={{ ...button, ...itemsButton }}
                variant="contained"
                onClick={() => navigate('/field-guide/items')}
              >
                Items
              </Button>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button
                sx={{ ...button, ...locationsButton }}
                variant="contained"
                onClick={() => navigate('/field-guide/locations')}
              >
                Locations
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Box sx={{ py: 2 }}>
        <Box sx={{ ...newsHeader }}>
          <Typography component="h2" variant="h4" sx={{ fontWeight: 'bold' }}>
            News
          </Typography>
          <Button
            variant="text"
            sx={{ alignSelf: 'flex-end', fontWeight: 'bold' }}
          >
            View All
          </Button>
        </Box>
        <Box>List</Box>
      </Box>
    </Box>
  );
};

export default Landing;
