import type {
  Palette,
  PaletteColor,
  PaletteColorOptions,
  PaletteOptions,
  Theme,
  ThemeOptions,
} from '@mui/material';
import { createTheme } from '@mui/material';

import { blue, green, mauve, purple, red, yellow } from './colourPalette';
import { white } from './constants';

interface AppPaletteOptions extends PaletteOptions {
  blue?: PaletteColorOptions;
  green?: PaletteColorOptions;
  mauve?: PaletteColorOptions;
  purple?: PaletteColorOptions;
  red?: PaletteColorOptions;
  yellow?: PaletteColorOptions;
}

interface AppThemeOptions extends ThemeOptions {
  palette?: AppPaletteOptions;
}

interface AppPalette extends Palette {
  blue: PaletteColor;
  green: PaletteColor;
  mauve: PaletteColor;
  purple: PaletteColor;
  red: PaletteColor;
  yellow: PaletteColor;
}

interface AppTheme extends Theme {
  palette: AppPalette;
}

const baseTheme: AppThemeOptions = {
  palette: {
    blue: {
      contrastText: white,
      dark: blue[900],
      light: blue[100],
      main: blue[500],
    },
    green: {
      contrastText: white,
      dark: green[900],
      light: green[100],
      main: green[500],
    },
    mauve: {
      contrastText: white,
      dark: mauve[900],
      light: mauve[100],
      main: mauve[500],
    },
    primary: {
      contrastText: white,
      dark: blue[900],
      light: blue[100],
      main: blue[500],
    },
    purple: {
      contrastText: white,
      dark: purple[900],
      light: purple[100],
      main: purple[500],
    },
    red: {
      contrastText: white,
      dark: red[900],
      light: red[100],
      main: red[500],
    },
    yellow: {
      contrastText: white,
      dark: yellow[900],
      light: yellow[100],
      main: yellow[500],
    },
  },
  typography: {
    button: {
      fontWeight: 'bold',
    },
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
} as ThemeOptions;

const theme = createTheme(baseTheme) as AppTheme;

export default theme;
