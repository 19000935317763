import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import type { Battle } from 'Modules/Digilab/Types';
import { BattleParticipant, BattleType } from 'Modules/Digilab/Types';
import { getAttributeColour, getAttributeFontColour } from 'Modules/FieldGuide';
import { TableLink } from 'Modules/Shared';
import { white } from 'Theme';
import { Translate } from 'Translation';
import { Link } from 'react-router-dom';

import TeamMemberChip from '../TeamMemberChip/TeamMemberChip';
import ParticipantChip from '../TeamMemberChip/TeamMemberChip';

interface Props {
  data: Battle[];
}

const BattleHistoryTable: React.FC<Props> = ({ data }) => {
  const theme = useTheme();

  console.log(data[0].participants);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate path="Pages.Digilab.Team.battleHistory.listingTable.headings.name" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.battleHistory.listingTable.headings.team" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.battleHistory.listingTable.headings.participants" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.battleHistory.listingTable.headings.enemies" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.battleHistory.listingTable.headings.enemyTeam" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.battleHistory.listingTable.headings.enemyTrainer" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.battleHistory.listingTable.headings.type" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((element) => (
            <TableRow key={`${element.name}-${element.id}`}>
              <TableCell>{element.name}</TableCell>
              <TableCell>
                <TableLink to={`/digilab/teams/${element.team.id}`}>
                  {element.team.name}
                </TableLink>
              </TableCell>
              <TableCell>
                <Grid container spacing={1} columns={3}>
                  {element.participants.map((el, index) => (
                    <Grid
                      key={`participants-${el.name}-${el.id}`}
                      item
                      xs="auto"
                    >
                      <TeamMemberChip member={el as any} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container spacing={1} columns={3}>
                  {element.enemies.map((el, index) => (
                    <Grid key={`enemies-${el.name}-${el.id}`} item xs="auto">
                      <TeamMemberChip member={el as any} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
              <TableCell>
                {element.enemyTeam ? element.enemyTeam.name : '-'}
              </TableCell>
              <TableCell>
                {element.enemyTrainer ? element.enemyTrainer : '-'}
              </TableCell>
              <TableCell>
                <Translate
                  path={
                    element.type === BattleType.Friendly
                      ? 'Pages.Digilab.Team.battleHistory.listingTable.battleTypes.friendly'
                      : 'Pages.Digilab.Team.battleHistory.listingTable.battleTypes.recorded'
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BattleHistoryTable;
