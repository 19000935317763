import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type {
  SupportSkill,
  SupportSkillsPaginatedListFilter,
} from 'Modules/FieldGuide/Types';
import type { PaginatedList, PaginationOptions, StoreEntities } from 'Store';
import { api } from 'Store';
import urlcat from 'urlcat';

export const supportSkillsApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    getSupportSkillByID: build.query<SupportSkill, number>({
      providesTags: (result, error, arg) => [
        { id: arg, type: 'SupportSkills' },
      ],
      query: (id) => `support-skills/${id}`,
    }),
    getSupportSkills: build.query<
      PaginatedList<SupportSkill>,
      PaginationOptions<SupportSkillsPaginatedListFilter>
    >({
      providesTags: (result, error, page) =>
        result
          ? [
              // Provides a tag for each post in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.items.map(({ id }) => ({
                id,
                type: 'SupportSkills' as const,
              })),
              { id: 'PARTIAL-LIST', type: 'SupportSkills' },
            ]
          : [{ id: 'PARTIAL-LIST', type: 'SupportSkills' }],
      query: (options = { limit: 40 }) => urlcat('/support-skills', options),
    }),
  }),
});

export const { useGetSupportSkillsQuery, useGetSupportSkillByIDQuery } =
  supportSkillsApiSlice;
