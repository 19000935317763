import type { Digimon } from 'Modules/FieldGuide';

import type { RosterCreateDTO, RosterStats } from '../Types';
import { RosterPersonality } from '../Types';

function generateCreateRosterDTO(
  digimon: Digimon,
  name?: string,
  personality?: RosterPersonality,
): RosterCreateDTO {
  const stats: RosterStats[] = digimon.stats.map((stat) => ({
    increasedBy: 0,
    maxValue: stat.maxValue,
    name: stat.name,
    value: stat.value,
  })) as RosterStats[];

  stats.push(
    ...([
      {
        increasedBy: 0,
        maxValue: 200,
        name: 'ability',
        value: 0,
      },
      {
        increasedBy: 0,
        maxValue: 100,
        name: 'camaradarie',
        value: 0,
      },
    ] as RosterStats[]),
  );

  return {
    digimon: digimon.id,
    equipment: [],
    exp: 0,
    level: 1,
    moves: digimon.moves.map((move, index) => ({
      move: move.id,
      number: index + 1,
    })),
    nickname: name && name.length > 0 ? name : digimon.name,
    personality: personality ?? RosterPersonality.Brainy,
    stats,
  };
}

export default generateCreateRosterDTO;
