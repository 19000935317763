import { CardMembershipSharp, StartSharp } from '@mui/icons-material';
import {
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { white } from 'Theme';
import { Translate } from 'Translation';

const TypeTable: React.FC = () => {
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="2x"
              />
            </TableCell>
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.error.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="0.5x"
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.error.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="0.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="2x"
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="2x"
              />
            </TableCell>
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.error.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="0.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TypeTable;
