import { Avatar, TableCell, TableRow } from '@mui/material';
import {
  ItemListingItem,
  MoveListingItemSkeleton,
} from 'Modules/FieldGuide/Components';
import { getItemCategoryLabel } from 'Modules/FieldGuide/Lib';
import { useGetItemsQuery } from 'Modules/FieldGuide/Store';
import type { Item, ItemsPaginatedListFilter } from 'Modules/FieldGuide/Types';
import { ItemCategory } from 'Modules/FieldGuide/Types';
import type { PaginationFilter, PaginationFilterOption } from 'Modules/Shared';
import {
  NewListingView,
  PaginationFilterType,
  TableLink,
  usePagination,
} from 'Modules/Shared';
import type { ItemsListingRTK, ListingEntities } from 'Store';
import type { TranslationPath } from 'Translation';
import { Translate, useTranslation } from 'Translation';
import type { ChangeEvent } from 'react';
import React, { useCallback, useMemo } from 'react';

function ItemsListing(): JSX.Element {
  const {
    clearFilters,
    clearState,
    entities,
    isLoading,
    options,
    paginate,
    setOptions,
  } = usePagination<ItemsListingRTK, ItemsPaginatedListFilter>(
    useGetItemsQuery,
    40,
  );

  const title = useTranslation('Pages.FieldGuide.ItemsListing.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    { label: useTranslation('Pages.FieldGuide.ItemsListing.breadcrumb') },
  ];

  const searchProps = useMemo(
    () => ({
      id: 'items-search',
      label:
        'Pages.FieldGuide.ItemsListing.paginationFilters.search.label' as TranslationPath,
      placeholder:
        'Pages.FieldGuide.ItemsListing.paginationFilters.search.placeholder' as TranslationPath,
    }),
    [],
  );

  const setCategoryFilter = (e: ChangeEvent<HTMLSelectElement>): void => {
    const value = e.target.value;
    clearState();

    if (value === 'all') {
      setOptions({ category: null });
    } else {
      setOptions({ category: value as ItemCategory });
    }
  };

  const getLabelValue = (value?: string | null): string => {
    if (value === null) {
      return 'all';
    }

    return value ?? '';
  };

  const itemCategories: PaginationFilterOption[] = useMemo(() => {
    const categories = Object.values(ItemCategory);

    const allItem: PaginationFilterOption = {
      id: 'category-all',
      label: 'Components.Select.all',
      value: 'all',
    };
    const categoryMenuItem: PaginationFilterOption[] = categories.map(
      (category) => ({
        id: `category-${category}`,
        label: getItemCategoryLabel(category) as TranslationPath,
        value: category as string,
      }),
    );

    return [allItem, ...categoryMenuItem];
  }, []);

  const renderItem = useCallback((rawItem: ListingEntities) => {
    const item = rawItem as Item;
    return <ItemListingItem key={item.id} item={item} />;
  }, []);

  const filters: PaginationFilter[] = useMemo(
    () => [
      {
        id: 'item-category',
        label:
          'Pages.FieldGuide.ItemsListing.paginationFilters.category.label' as TranslationPath,
        onChange: setCategoryFilter,
        options: itemCategories,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.category),
      },
    ],
    [itemCategories, options.category],
  );

  const renderRow = useCallback((rawItem: ListingEntities) => {
    const item = rawItem as Item;
    return (
      <TableRow key={item.id}>
        <TableCell>
          <Avatar src={item.icon} />
        </TableCell>
        <TableCell>
          <TableLink to={`/field-guide/items/${item.id}`}>
            {item.name}
          </TableLink>
        </TableCell>
        <TableCell>¥{item.price}</TableCell>
        <TableCell>
          <Translate path={getItemCategoryLabel(item.category)} />
        </TableCell>
      </TableRow>
    );
  }, []);

  return (
    <NewListingView
      entity="items"
      isLoading={isLoading}
      paginate={paginate}
      entities={entities}
      breadcrumbs={breadcrumbs}
      title={title}
      listingSkeletonComponent={<MoveListingItemSkeleton />}
      itemRender={renderItem}
      filters={filters}
      limit={40}
      searchProps={searchProps}
      clearFilters={clearFilters}
      rowRender={renderRow}
      headings={[
        {
          dataKey: 'icon',
          label: 'Pages.FieldGuide.ItemsListing.tableListing.headings.icon',
          width: 100,
        },
        {
          dataKey: 'name',
          label: 'Pages.FieldGuide.ItemsListing.tableListing.headings.name',
        },
        {
          dataKey: 'price',
          label: 'Pages.FieldGuide.ItemsListing.tableListing.headings.price',
        },
        {
          dataKey: 'type',
          label: 'Pages.FieldGuide.ItemsListing.tableListing.headings.type',
        },
      ]}
    />
  );
}

export default ItemsListing;
