import type { ListingEntities, PaginatedList } from 'Store/types';

interface ResultWithIds<T> {
  id: string | number;
  type: T;
}

function providesList<T extends string>(
  resultsWithIds: PaginatedList<ListingEntities> | undefined,
  tagType: T,
): Array<ResultWithIds<T>> {
  return resultsWithIds
    ? [
        { id: 'LIST', type: tagType },
        ...resultsWithIds.items.map(({ id }) => ({ id, type: tagType })),
      ]
    : [{ id: 'LIST', type: tagType }];
}

export default providesList;
