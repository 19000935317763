import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { attributeList, typeList } from 'Modules/FieldGuide/Const';
import {
  getAttributeColour,
  getAttributeFontColour,
} from 'Modules/FieldGuide/Lib';
import { Attribute } from 'Modules/FieldGuide/Types';
import { Translate } from 'Translation';

import MultiplyerRow from './Row';

function AttributeTable(): JSX.Element {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Dark),
                color: getAttributeFontColour(Attribute.Dark),
              }}
            >
              <Translate path="Common.attributes.dark" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Light),
                color: getAttributeFontColour(Attribute.Light),
              }}
            >
              <Translate path="Common.attributes.light" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Earth),
                color: getAttributeFontColour(Attribute.Earth),
              }}
            >
              <Translate path="Common.attributes.earth" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Electric),
                color: getAttributeFontColour(Attribute.Electric),
              }}
            >
              <Translate path="Common.attributes.electric" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Fire),
                color: getAttributeFontColour(Attribute.Fire),
              }}
            >
              <Translate path="Common.attributes.fire" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Plant),
                color: getAttributeFontColour(Attribute.Plant),
              }}
            >
              <Translate path="Common.attributes.plant" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Water),
                color: getAttributeFontColour(Attribute.Water),
              }}
            >
              <Translate path="Common.attributes.water" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Wind),
                color: getAttributeFontColour(Attribute.Wind),
              }}
            >
              <Translate path="Common.attributes.wind" />
            </TableCell>
            <TableCell
              colSpan={4}
              sx={{
                bgcolor: getAttributeColour(Attribute.Neutral),
                color: getAttributeFontColour(Attribute.Neutral),
              }}
            >
              <Translate path="Common.attributes.neutral" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.data" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.vaccine" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.virus" />
            </TableCell>
            <TableCell>
              <Translate path="Common.types.free" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attributeList.map((attr) =>
            typeList.map((type, rowIndex: number) => (
              <MultiplyerRow attr={attr} rowIndex={rowIndex} type={type} />
            )),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AttributeTable;
