import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { ActivityLog } from 'Modules/Admin';
import type { PaginatedList, PaginationOptions, StoreEntities } from 'Store';
import { api, providesList } from 'Store';
import urlcat from 'urlcat';

export const activityLogApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    getActivityLog: build.query<
      PaginatedList<ActivityLog>,
      PaginationOptions<Record<string, string>>
    >({
      providesTags: (result, error, arg) => providesList(result, 'ActivityLog'),
      query: (options = { limit: 40 }) => urlcat('/activity-log', options),
    }),
  }),
});

export const { useGetActivityLogQuery } = activityLogApiSlice;
