import type { TranslationPath } from 'Translation/Types';

import { DigimonStage } from '../Types';

function getDigimonStageLabel(digimonStage?: DigimonStage): TranslationPath {
  switch (digimonStage) {
    case DigimonStage.TrainingLower:
      return 'FieldGuide.Digimon.stage.trainingLower';

    case DigimonStage.TrainingUpper:
      return 'FieldGuide.Digimon.stage.trainingUpper';

    case DigimonStage.Rookie:
      return 'FieldGuide.Digimon.stage.rookie';

    case DigimonStage.Champion:
      return 'FieldGuide.Digimon.stage.champion';

    case DigimonStage.Ultimate:
      return 'FieldGuide.Digimon.stage.ultimate';

    case DigimonStage.Mega:
      return 'FieldGuide.Digimon.stage.mega';

    case DigimonStage.Ultra:
      return 'FieldGuide.Digimon.stage.ultra';

    case DigimonStage.Armor:
      return 'FieldGuide.Digimon.stage.armor';

    default:
      return 'FieldGuide.Digimon.stage.trainingLower';
  }
}

export default getDigimonStageLabel;
