import { Box, Paper, Stack } from '@mui/material';
import { setPage } from 'Modules/Shared';
import { largeCornerRadius, theme, white } from 'Theme';
import { TranslateTypography, useTranslation } from 'Translation';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

const styles = {
  button: {
    borderRadius: largeCornerRadius,
    color: white,
    fontWeight: 600,
    height: '75px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  fieldGuideButton: {
    '&:hover': {
      bgcolor: theme.palette.green.dark,
    },
    bgcolor: theme.palette.green.main,
  },
  newsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
};

const About: React.FC = () => {
  const dispatch = useDispatch();
  const title = useTranslation('Pages.About.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.About.breadcrumb') },
  ];

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  return (
    <Box sx={{ p: 2 }}>
      <Paper
        elevation={3}
        sx={{ borderRadius: largeCornerRadius, mb: 2, p: 2 }}
      >
        <Stack>
          <TranslateTypography
            component="h1"
            variant="h3"
            sx={{ fontWeight: 'bold', mb: 4 }}
            path="Pages.About.overview.title"
          />
        </Stack>
      </Paper>
      <Paper elevation={3} sx={{ borderRadius: largeCornerRadius, p: 2 }}>
        <Stack>
          <TranslateTypography
            component="h1"
            variant="h3"
            sx={{ fontWeight: 'bold', mb: 4 }}
            path="Pages.About.credits.title"
          />
          <TranslateTypography
            component="p"
            variant="body1"
            sx={{ fontWeight: 'bold', mb: 1 }}
            path="Pages.About.credits.bandai"
          />
          <TranslateTypography
            component="p"
            variant="body1"
            sx={{ fontWeight: 'bold', mb: 1 }}
            path="Pages.About.credits.assets"
          />
          <TranslateTypography
            component="p"
            variant="body1"
            sx={{ fontWeight: 'bold', mb: 4 }}
            path="Pages.About.credits.data"
          />
        </Stack>
      </Paper>
    </Box>
  );
};

export default About;
