import { largeCornerRadius, neutralAttributeColour, white } from 'Theme';

const styles = {
  button: {
    color: white,
    fontWeight: '600',
  },
  card: {
    bgcolor: neutralAttributeColour,
    borderRadius: largeCornerRadius,
    color: white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
  },
  title: {
    color: white,
    fontWeight: 'bold',
  },
};

export default styles;
