import { green as colourGreen } from '../constants';

const green50 = '#eaf8f4';
const green100 = '#caece4';
const green200 = '#a7e0d3';
const green300 = '#84d4c1';
const green400 = '#69cab3';
const green600 = '#48bb9e';
const green700 = '#3fb395';
const green800 = '#36ab8b';
const green900 = '#269e7b';
const greenA100 = '#ddfff4';
const greenA200 = '#aaffe5';
const greenA400 = '#77ffd5';
const greenA700 = '#5dffcd';

const green = {
  100: green100,
  200: green200,
  300: green300,
  400: green400,
  50: green50,
  500: colourGreen,
  600: green600,
  700: green700,
  800: green800,
  900: green900,
  A100: greenA100,
  A200: greenA200,
  A400: greenA400,
  A700: greenA700,
};

export default green;
