import { CardMembershipSharp, StartSharp } from '@mui/icons-material';
import type { Theme } from '@mui/material';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import type { ProcessedStats, ProcessedTeam, Roster } from 'Modules/Digilab';
import {
  DefensiveCoverage,
  TeamSlotState,
  generateStats,
} from 'Modules/Digilab';
import {
  Attribute,
  DigimonType,
  getAttributeColour,
  getAttributeFontColour,
  getDigimonStatLabel,
} from 'Modules/FieldGuide';
import { white } from 'Theme';
import { Translate, TranslateTypography } from 'Translation';

export interface TableHeading {
  name: string;
  altname?: string;
  type: DigimonType;
  attribute: Attribute;
  icon: string;
}

interface Props {
  team: ProcessedTeam;
}

function generateStatsTable(team: ProcessedTeam) {
  const header: TableHeading[] = [];
  const stats: Record<
    string,
    {
      roster: number;
      stats: ProcessedStats;
    }
  > = {};
  const statsLabels = [
    'hp',
    'sp',
    'attack',
    'intelligence',
    'defence',
    'speed',
  ];

  const statTotals: number[] = [0];
  const keys: number[] = [];

  let teamLength = 0;
  let teamTotalStat = 0;

  team.members.forEach((member) => {
    if (member.state === TeamSlotState.Used && !!member.member) {
      const teamMember = member.member as Roster;
      teamLength = teamLength += 1;

      header.push({
        altname:
          teamMember.digimon.name !== teamMember.nickname
            ? teamMember.digimon.name
            : undefined,
        attribute: teamMember.digimon.attribute ?? Attribute.Neutral,
        icon: teamMember.digimon.image ?? '',
        name: teamMember.nickname,
        type: teamMember.digimon.type ?? DigimonType.Free,
      });

      keys.push(teamMember.id);

      const processedStats = generateStats(teamMember);

      const total = processedStats.progressable.reduce(
        (prevVal, stat) => stat.value + prevVal,
        0,
      );
      statTotals.push(total);
      teamTotalStat = teamTotalStat + total;

      stats[teamMember.nickname] = {
        roster: teamMember.id,
        stats: processedStats,
      };
    }
  });

  statTotals.push(teamTotalStat);

  const data = statsLabels.map((label) => {
    const stat: any[] = [];
    Object.values(stats).forEach((objStat) => {
      objStat.stats.progressable.forEach((rosterStat) => {
        if (rosterStat.name === label) {
          stat.push(rosterStat.value);
        }
      });
    });

    const total = stat.reduce((prevVal, currentVal) => prevVal + currentVal, 0);
    stat.unshift(label);
    stat.push(total);

    return stat;
  });

  data.push(statTotals);

  return {
    headings: header,
    table: {
      data,
      keys,
    },
  };
}

function getCellStyle(
  isMostOfTeamStrong: boolean,
  isMostOfTeamWeak: boolean,
  totalStrength: number,
  totalWeaknesses: number,
  theme: Theme,
) {
  if (isMostOfTeamStrong) {
    return {
      bgcolor: theme.palette.success.dark,
      color: white,
    };
  }

  if (isMostOfTeamWeak) {
    return {
      bgcolor: theme.palette.error.dark,
      color: white,
    };
  }

  if (totalStrength >= 2) {
    return {
      bgcolor: theme.palette.success.main,
      color: white,
    };
  }

  if (totalStrength >= 1) {
    return {
      bgcolor: theme.palette.success.light,
      color: white,
    };
  }

  if (totalWeaknesses >= 2) {
    return {
      bgcolor: theme.palette.error.main,
      color: white,
    };
  }

  if (totalWeaknesses >= 1) {
    return {
      bgcolor: theme.palette.error.light,
      color: white,
    };
  }
}

const Analysis: React.FC<Props> = ({ team }) => {
  const { table, headings } = generateStatsTable(team);

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Translate path="Pages.Digilab.Team.analysis.statTable.headings.stats" />
              </TableCell>
              {headings.map((heading) => (
                <TableCell key={heading.name}>
                  <Stack spacing={0.5}>
                    <img src={heading.icon} width={40} height={40} />
                    <Typography mb={0}>{heading.name}</Typography>
                    {heading.altname && (
                      <Typography
                        mb={0}
                        sx={{ fontSize: '0.75rem' }}
                      >{`(${heading.altname})`}</Typography>
                    )}
                  </Stack>
                </TableCell>
              ))}
              <TableCell>
                <Translate path="Pages.Digilab.Team.analysis.statTable.headings.totals" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table.data.map((stats, rowIndex) => (
              <TableRow key={`${stats[0]}`}>
                {stats.map((stat, statIndex) => {
                  const keyIndex = statIndex - 1;
                  if (statIndex === 0) {
                    if (rowIndex === table.data.length - 1) {
                      return (
                        <TableCell key={`total-stats-label-${stat}`}>
                          <Translate path="Pages.Digilab.Team.analysis.statTable.headings.digimonTotals" />
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={`stats-label-${stat}`}>
                        <Translate path={getDigimonStatLabel(stat)} />
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell
                      key={`${table.keys[keyIndex]}-${stats[0]}-stats-value-${stat}`}
                    >
                      {stat}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TranslateTypography
        variant="h4"
        path="Pages.Digilab.Team.analysis.defenceTable.title"
      />
      <DefensiveCoverage team={team} />
      <Stack alignItems="flex-end">
        <Typography sx={{ fontSize: '0.75rem' }}>
          Range from 0.25 - 3, lower is better
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Analysis;
