import type { TranslationPath } from 'Translation/Types';

import { DigimonType } from '../Types';

function getDigimonTypeLabel(digimonType?: DigimonType): TranslationPath {
  switch (digimonType) {
    case DigimonType.Data:
      return 'FieldGuide.Digimon.type.data';

    case DigimonType.Free:
      return 'FieldGuide.Digimon.type.free';

    case DigimonType.Vaccine:
      return 'FieldGuide.Digimon.type.vaccine';

    case DigimonType.Virus:
      return 'FieldGuide.Digimon.type.virus';

    default:
      return 'FieldGuide.Digimon.type.free';
  }
}

export default getDigimonTypeLabel;
