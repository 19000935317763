export enum Theme {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}

export interface ThemeAccent {
  light: string;
  main: string;
  dark: string;
  contrastText: string;
}
