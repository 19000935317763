import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Grid, Paper, Stack } from '@mui/material';
import { useGetMeQuery, useUpdateMeMutation } from 'Modules/Profile';
import { setPage } from 'Modules/Shared';
import {
  Accent,
  Theme,
  largeCornerRadius,
  palette,
  themeContext,
  white,
} from 'Theme';
import { TranslateTypography, useTranslation } from 'Translation';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const styles = {
  blueAccentButton: {
    '&:hover': {
      bgcolor: palette.blue.dark,
    },
    bgcolor: palette.blue.main,
  },
  button: {
    borderRadius: largeCornerRadius,
    color: white,
    fontWeight: 600,
    height: '75px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  greenAccentButton: {
    '&:hover': {
      bgcolor: palette.green.dark,
    },
    bgcolor: palette.green.main,
  },
  mauveAccentButton: {
    '&:hover': {
      bgcolor: palette.mauve.dark,
    },
    bgcolor: palette.mauve.main,
  },
  newsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  purpleAccentButton: {
    '&:hover': {
      bgcolor: palette.purple.dark,
    },
    bgcolor: palette.purple.main,
  },
  redAccentButton: {
    '&:hover': {
      bgcolor: palette.red.dark,
    },
    bgcolor: palette.red.main,
  },
  yellowAccentButton: {
    '&:hover': {
      bgcolor: palette.yellow.dark,
    },
    bgcolor: palette.yellow.main,
  },
};

function Settings() {
  const {
    button,
    blueAccentButton,
    greenAccentButton,
    yellowAccentButton,
    redAccentButton,
    mauveAccentButton,
    purpleAccentButton,
  } = styles;
  const title = useTranslation('Pages.Profile.Settings.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.title'), to: '/' },
    { label: title },
  ];

  const [updateProfile, { isLoading }] = useUpdateMeMutation();
  const context = useContext(themeContext);
  const userQuery = useGetMeQuery({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  const setTheme = useCallback((theme: Theme) => {
    updateProfile({ theme });
    context.setTheme(theme);
  }, []);
  const setAccent = useCallback((accent: Accent) => {
    updateProfile({ accent });
    context.setAccent(accent);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Paper
        elevation={3}
        sx={{ borderRadius: largeCornerRadius, mb: 4, p: 2 }}
      >
        <Stack>
          <TranslateTypography
            component="h1"
            variant="h3"
            sx={{ fontWeight: 'bold', mb: 2 }}
            path="Pages.Profile.Settings.theme.title"
          />
          <TranslateTypography
            component="p"
            variant="body1"
            sx={{ mb: 2 }}
            path="Pages.Profile.Settings.theme.description"
          />
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                startIcon={<PersonIcon />}
                sx={{ ...button }}
                variant="contained"
                onClick={() => setTheme(Theme.System)}
              >
                {useTranslation('Pages.Profile.Settings.theme.buttons.system')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                startIcon={<Brightness7Icon />}
                sx={{ ...button }}
                variant="contained"
                onClick={() => setTheme(Theme.Light)}
              >
                {useTranslation('Pages.Profile.Settings.theme.buttons.light')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                startIcon={<Brightness4Icon />}
                sx={{ ...button }}
                variant="contained"
                onClick={() => setTheme(Theme.Dark)}
              >
                {useTranslation('Pages.Profile.Settings.theme.buttons.dark')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Paper elevation={3} sx={{ borderRadius: largeCornerRadius, p: 2 }}>
        <Stack>
          <TranslateTypography
            component="h1"
            variant="h3"
            sx={{ fontWeight: 'bold', mb: 2 }}
            path="Pages.Profile.Settings.accent.title"
          />
          <TranslateTypography
            component="p"
            variant="body1"
            sx={{ mb: 2 }}
            path="Pages.Profile.Settings.accent.description"
          />
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                sx={{ ...button, ...blueAccentButton }}
                variant="contained"
                onClick={() => setAccent(Accent.Blue)}
              >
                {useTranslation('Pages.Profile.Settings.accent.buttons.blue')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                sx={{ ...button, ...greenAccentButton }}
                variant="contained"
                onClick={() => setAccent(Accent.Green)}
              >
                {useTranslation('Pages.Profile.Settings.accent.buttons.green')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                sx={{ ...button, ...yellowAccentButton }}
                variant="contained"
                onClick={() => setAccent(Accent.Yellow)}
              >
                {useTranslation('Pages.Profile.Settings.accent.buttons.yellow')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                sx={{ ...button, ...redAccentButton }}
                variant="contained"
                onClick={() => setAccent(Accent.Red)}
              >
                {useTranslation('Pages.Profile.Settings.accent.buttons.red')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                sx={{ ...button, ...mauveAccentButton }}
                variant="contained"
                onClick={() => setAccent(Accent.Mauve)}
              >
                {useTranslation('Pages.Profile.Settings.accent.buttons.mauve')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} xl={2}>
              <Button
                sx={{ ...button, ...purpleAccentButton }}
                variant="contained"
                onClick={() => setAccent(Accent.Purple)}
              >
                {useTranslation('Pages.Profile.Settings.accent.buttons.purple')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </Box>
  );
}

export default Settings;
