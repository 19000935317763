import type { AppBarStyling } from 'Modules/Shared';
import { setAppBarStyling, setPage } from 'Modules/Shared';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

function useEntityPage(
  entity: any,
  generateTitle: () => string,
  breadcrumbs: Array<{ label: string; to?: string }>,
  isLoading: boolean = false,
  appBarStyles?: AppBarStyling,
) {
  const dispatch = useDispatch();
  const hasAppBarStyles = useMemo(() => !!appBarStyles, [appBarStyles]);

  useEffect(() => {
    if (!!entity && !isLoading) {
      const title = generateTitle();

      dispatch(
        setPage({
          breadcrumbs: [...breadcrumbs, { label: entity.name }],
          title,
        }),
      );

      if (hasAppBarStyles) {
        dispatch(setAppBarStyling({ loading: false, styles: appBarStyles }));
      }
    } else {
      dispatch(setAppBarStyling({ loading: true, styles: appBarStyles }));
    }

    return () => {
      dispatch(setAppBarStyling({}));
    };
  }, [entity, hasAppBarStyles, isLoading, breadcrumbs]);
}

export default useEntityPage;
