import { Stack, Typography } from '@mui/material';
import React from 'react';

function MoreInfo({
  content,
  title,
}: {
  title: string;
  content?: string;
}): JSX.Element {
  return (
    <Stack spacing={0}>
      <Typography component="p" sx={{ mb: 0 }}>
        {title}
      </Typography>
      {content && (
        <Typography component="p" fontSize={14} sx={{ mb: 0 }}>
          {content}
        </Typography>
      )}
    </Stack>
  );
}

export default MoreInfo;
