import { Box, Card, CardActions, CardContent, Skeleton } from '@mui/material';
import { largeCornerRadius } from 'Theme';
import React from 'react';

import styles from './styles';

const DigimonLisitingItemSkeleton: React.FC = () => {
  const { avatarContainer, card, contents, chipContainer, footer } = styles;
  return (
    <Card sx={card}>
      <CardContent>
        <Skeleton height={30} width={200} />
        <Box sx={contents}>
          <Box sx={chipContainer}>
            <Skeleton
              sx={{ borderRadius: largeCornerRadius }}
              height={50}
              width={100}
            />
            <Skeleton
              sx={{ borderRadius: largeCornerRadius }}
              height={50}
              width={100}
            />
          </Box>
          <Box sx={avatarContainer}>
            <Skeleton variant="circular" width={80} height={80} />
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={footer}>
        <Skeleton height={30} width={100} />
        <Skeleton height={30} width={100} />
      </CardActions>
    </Card>
  );
};

export default DigimonLisitingItemSkeleton;
