import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { authContext } from 'Modules/Auth';
import { UniversalSearch, notificationContext, setPage } from 'Modules/Shared';
import { largeCornerRadius, theme, white } from 'Theme';
import { useTranslation } from 'Translation';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const styles = {
  button: {
    borderRadius: largeCornerRadius,
    color: white,
    fontWeight: 600,
    height: '75px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  digilabButton: {
    '&:hover': {
      bgcolor: theme.palette.green.dark,
    },
    bgcolor: theme.palette.green.main,
    height: '100px',
  },
  fieldGuideButton: {
    '&:hover': {
      bgcolor: theme.palette.green.dark,
    },
    bgcolor: theme.palette.green.main,
    height: '100px',
  },
  itemsButton: {
    '&:hover': {
      bgcolor: theme.palette.yellow.dark,
    },
    bgcolor: theme.palette.yellow.main,
    height: '100px',
  },
  locationsButton: {
    '&:hover': {
      bgcolor: theme.palette.purple.dark,
    },
    bgcolor: theme.palette.purple.main,
    height: '100px',
  },
  movesButton: {
    '&:hover': {
      bgcolor: theme.palette.red.dark,
    },
    bgcolor: theme.palette.red.main,
    height: '100px',
  },
  newsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  rosterButton: {
    '&:hover': {
      bgcolor: theme.palette.mauve.dark,
    },
    bgcolor: theme.palette.mauve.main,
    height: '100px',
  },
  supportSkillsButton: {
    '&:hover': {
      bgcolor: theme.palette.blue.dark,
    },
    bgcolor: theme.palette.blue.main,
    height: '100px',
  },
};

const News: React.FC = () => {
  const { button, digilabButton, fieldGuideButton, newsHeader } = styles;
  return (
    <Box sx={{ py: 2 }}>
      <Box sx={{ ...newsHeader }}>
        <Typography component="h2" variant="h4" sx={{ fontWeight: 'bold' }}>
          News
        </Typography>
        <Button
          variant="text"
          sx={{ alignSelf: 'flex-end', fontWeight: 'bold' }}
        >
          View All
        </Button>
      </Box>
      <Box>List</Box>
    </Box>
  );
};

// const Home: React.FC = () => {
//   const { button, digilabButton, fieldGuideButton, itemsButton, locationsButton, newsHeader } = styles;
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const authCtx = useContext(authContext);
//   const title = useTranslation('Pages.Home.title');
//   const breadcrumbs = [
//     { label: useTranslation('Pages.Home.breadcrumb') }
//   ];

//   useEffect(() => {
//     dispatch(setPage({ title, breadcrumbs }));
//   });

//   return (
//     <>
//       <Helmet>
//         <title>Home</title>
//       </Helmet>
//       <Stack p={10}>
//         <Stack justifyContent="space-between" sx={{ height: 'calc(100vh - calc(64px + 160px))' }} spacing={5} mb={10}>
//           <Box mb={3} mt={1} width="100%" display="flex" justifyContent="center">
//             <UniversalSearch />
//           </Box>

//           <Grid container spacing={2} width="100%">
//             <Grid item xs={3} sx={{ paddingLeft: '0px !important' }}>
//               <Button sx={{ ...button, ...fieldGuideButton }} variant="contained" onClick={() => navigate('/field-guide')}>Field Guide</Button>
//             </Grid>
//             {authCtx.isAuthenticated && (
//               <Grid item xs={3}>
//                 <Button sx={{ ...button, ...digilabButton }} variant="contained" onClick={() => navigate('/digilab')}>Digilab</Button>
//               </Grid>
//             )}
//             <Grid item xs={3}>
//               <Button sx={{ ...button, ...itemsButton }} variant="contained" onClick={() => navigate('/field-guide')}>Items</Button>
//             </Grid>
//             <Grid item xs={3}>
//               <Button sx={{ ...button, ...locationsButton }} variant="contained" onClick={() => navigate('/field-guide')}>Locations</Button>
//             </Grid>
//           </Grid>
//         </Stack>

//         <Box sx={{ py: 2 }}>
//           <Box sx={{ ...newsHeader }}>
//             <Typography component="h2" variant="h4" sx={{ fontWeight: 'bold' }}>News</Typography>
//             <Button variant="text" sx={{ alignSelf: 'flex-end', fontWeight: 'bold' }}>View All</Button>
//           </Box>
//           <Box>List</Box>
//         </Box>
//       </Stack>
//     </>
//   );
// }

function Home(): JSX.Element {
  const {
    button,
    fieldGuideButton,
    itemsButton,
    locationsButton,
    movesButton,
    newsHeader,
    supportSkillsButton,
    rosterButton,
  } = styles;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authCtx = useContext(authContext);
  const notiCtx = useContext(notificationContext);
  const title = useTranslation('Pages.Home.title');
  const breadcrumbs = [{ label: useTranslation('Pages.Home.breadcrumb') }];

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Stack>
        <Paper elevation={3} sx={{ borderRadius: `0px 0px 40px 40px`, p: 4 }}>
          <Stack justifyContent="space-between" spacing={10} mb={10}>
            <Typography
              component="h1"
              variant="h3"
              sx={{ fontWeight: 'bold', my: 4 }}
            >
              What Digimon are you looking for?
            </Typography>
            <Box
              mb={3}
              mt={1}
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <UniversalSearch />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ ...button, ...fieldGuideButton }}
                  variant="contained"
                  onClick={(): void => navigate('/field-guide/digimon')}
                >
                  Digimon
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ ...button, ...movesButton }}
                  variant="contained"
                  onClick={(): void => navigate('/field-guide/moves')}
                >
                  Moves
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ ...button, ...supportSkillsButton }}
                  variant="contained"
                  onClick={(): void => navigate('/field-guide/support-skills')}
                >
                  Support Skills
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ ...button, ...itemsButton }}
                  variant="contained"
                  onClick={(): void => navigate('/field-guide/items')}
                >
                  Items
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ ...button, ...locationsButton }}
                  variant="contained"
                  onClick={(): void => navigate('/field-guide/locations')}
                >
                  Locations
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ ...button, ...locationsButton }}
                  variant="contained"
                  onClick={(): void => navigate('/field-guide/charts')}
                >
                  Charts
                </Button>
              </Grid>
              {authCtx.isAuthenticated && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ ...button, ...rosterButton }}
                      variant="contained"
                      onClick={(): void => navigate('/digilab/roster')}
                    >
                      Roster
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ ...button, ...movesButton }}
                      variant="contained"
                      onClick={(): void => navigate('/digilab/teams')}
                    >
                      Teams
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Stack>
        </Paper>

        <Box sx={{ p: 4, py: 2 }}>
          <Box sx={{ ...newsHeader }}>
            <Typography component="h2" variant="h4" sx={{ fontWeight: 'bold' }}>
              News
            </Typography>
            <Button
              variant="text"
              sx={{ alignSelf: 'flex-end', fontWeight: 'bold' }}
              onClick={() => notiCtx.notify({ title: 'Testing' })}
            >
              View All
            </Button>
          </Box>
          <Box>List</Box>
        </Box>
      </Stack>
    </>
  );
}

export default Home;
