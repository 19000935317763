import { Checkbox, Stack } from '@mui/material';
import type { ComboBoxOption } from 'Modules/Shared';
import { ComboBox } from 'Modules/Shared';
import type { TranslationPath } from 'Translation';
import { useTranslation } from 'Translation';
import { intersection } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

interface Props {
  cannotCheckAll?: boolean;
  getNumberChecked: (items: ComboBoxOption[]) => number;
  items: ComboBoxOption[];
  toggleAll?: (items: ComboBoxOption[]) => void;
  searchLabel?: TranslationPath;
  hasSearch?: boolean;
  handleSearch?(e: any, newValue: ComboBoxOption | null): void;
}

const Header: React.FC<Props> = ({
  cannotCheckAll = true,
  getNumberChecked,
  items,
  toggleAll,
  searchLabel,
  handleSearch,
  hasSearch = false,
}) => (
  <Stack>
    {!cannotCheckAll && !!toggleAll && (
      <Checkbox
        onClick={(e) => toggleAll(items)}
        checked={getNumberChecked(items) === items.length && items.length !== 0}
        indeterminate={
          getNumberChecked(items) !== items.length &&
          getNumberChecked(items) !== 0
        }
        disabled={items.length === 0}
        inputProps={{
          'aria-label': 'all items selected',
        }}
      />
    )}
    {hasSearch && searchLabel && (
      <ComboBox
        sx={{ width: '200px' }}
        label={searchLabel}
        options={items}
        onChange={handleSearch}
        fullWidth
      />
    )}
  </Stack>
);

export default Header;
