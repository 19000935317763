import { yellow as colourYellow } from '../constants';

const yellow50 = '#fef8e9';
const yellow100 = '#fceec8';
const yellow200 = '#fbe3a3';
const yellow300 = '#f9d87e';
const yellow400 = '#f7cf63';
const yellow600 = '#f5c140';
const yellow700 = '#f3ba37';
const yellow800 = '#f2b32f';
const yellow900 = '#efa620';
const yellowA100 = '#ffffff';
const yellowA200 = '#fffbf3';
const yellowA400 = '#ffe7c0';
const yellowA700 = '#ffdea7';

const yellow = {
  100: yellow100,
  200: yellow200,
  300: yellow300,
  400: yellow400,
  50: yellow50,
  500: colourYellow,
  600: yellow600,
  700: yellow700,
  800: yellow800,
  900: yellow900,
  A100: yellowA100,
  A200: yellowA200,
  A400: yellowA400,
  A700: yellowA700,
};

export default yellow;
