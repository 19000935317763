import { Attribute } from '../Types';

const attributeList = [
  Attribute.Dark,
  Attribute.Light,
  Attribute.Earth,
  Attribute.Electric,
  Attribute.Fire,
  Attribute.Plant,
  Attribute.Water,
  Attribute.Wind,
  Attribute.Neutral,
];

export default attributeList;
