import { mauve as colourMauve } from '../constants';

const mauve50 = '#edeffb';
const mauve100 = '#d3d7f4';
const mauve200 = '#b6bced';
const mauve300 = '#98a1e6';
const mauve400 = '#828de0';
const mauve600 = '#6471d7';
const mauve700 = '#5966d2';
const mauve800 = '#4f5ccd';
const mauve900 = '#3d49c4';
const mauveA100 = '#ffffff';
const mauveA200 = '#e0e2ff';
const mauveA400 = '#adb3ff';
const mauveA700 = '#939cff';

const mauve = {
  100: mauve100,
  200: mauve200,
  300: mauve300,
  400: mauve400,
  50: mauve50,
  500: colourMauve,
  600: mauve600,
  700: mauve700,
  800: mauve800,
  900: mauve900,
  A100: mauveA100,
  A200: mauveA200,
  A400: mauveA400,
  A700: mauveA700,
};

export default mauve;
