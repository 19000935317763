import { useHandleSignInCallback } from '@logto/react';
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import About from 'About';
import Home from 'Home';
import { Dashboard } from 'Modules/Admin';
import { AuthProvider, authContext } from 'Modules/Auth';
import {
  DigilabLanding,
  RosterListing,
  RosterPage,
  TeamPage,
  TeamsListing,
} from 'Modules/Digilab';
import {
  Charts,
  DigimonDetail,
  DigimonListing,
  FieldGuideLanding,
  ItemDetail,
  ItemsListing,
  LocationDetail,
  LocationsListing,
  MoveDetail,
  MovesListing,
  SupportSkillsListing,
} from 'Modules/FieldGuide';
import { Account, Settings, useGetMeQuery } from 'Modules/Profile';
import { Wrapper, runOneSignal } from 'Modules/Shared';
import NotificationProvider from 'Modules/Shared/Contexts/notificationContext';
import type { RootState } from 'Store';
import { Theme, getAppTheme, themeContext } from 'Theme';
import { TranslationWrapper } from 'Translation';
import { SnackbarProvider } from 'notistack';
import React, { useContext, useEffect, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Callback(): JSX.Element {
  const authCtx = useContext(authContext);
  const navigate = useNavigate();
  const { isLoading } = useHandleSignInCallback(() => {
    // Navigate to root path when finished
    authCtx.setTokens('');
    navigate('/');
  });

  // When it's working in progress
  if (isLoading) {
    return <div>Redirecting...</div>;
  }

  return <></>;
}

function App(): JSX.Element {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { accessToken } = useSelector((state: RootState) => state.authSlice);
  const context = useContext(themeContext);

  const query = useGetMeQuery({}, { skip: !accessToken });
  const user = query.data ?? {
    accent: context.accent,
    notificationTheme: context.notificationTheme,
    theme: context.theme,
  };

  useEffect(() => {
    runOneSignal();
  }, []);

  useEffect(() => {
    if (user.accent !== context.accent) {
      context.setAccent(user.accent);
    }

    if (user.theme !== context.theme) {
      context.setTheme(user.theme);
    }
  }, [context, user.accent, user.theme]);

  const appTheme = useMemo(() => {
    if (!!user.theme && !!user.accent) {
      if (context.theme === Theme.System) {
        const themeMode = prefersDarkMode ? Theme.Dark : Theme.Light;
        const theme = getAppTheme(context.accent, themeMode);
        return createTheme(theme);
      }

      if (context.theme === Theme.Dark) {
        const theme = getAppTheme(context.accent, Theme.Dark);
        return createTheme(theme);
      }
    }

    const theme = getAppTheme(context.accent, Theme.Light);
    return createTheme(theme);
  }, [context.accent, context.theme, prefersDarkMode, user.accent, user.theme]);

  return (
    <HelmetProvider>
      <TranslationWrapper>
        <AuthProvider>
          <ThemeProvider theme={appTheme}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              theme={user.notificationTheme}
            />
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <CssBaseline />
              <NotificationProvider>
                <BrowserRouter>
                  <Wrapper>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/callback" element={<Callback />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/field-guide">
                        <Route
                          path="/field-guide"
                          element={<FieldGuideLanding />}
                        />
                        <Route
                          path="/field-guide/charts"
                          element={<Charts />}
                        />
                        <Route
                          path="/field-guide/digimon"
                          element={<DigimonListing />}
                        />
                        <Route
                          path="/field-guide/digimon/:digimon"
                          element={<DigimonDetail />}
                        />
                        <Route
                          path="/field-guide/items"
                          element={<ItemsListing />}
                        />
                        <Route
                          path="/field-guide/items/:item"
                          element={<ItemDetail />}
                        />
                        <Route
                          path="/field-guide/locations"
                          element={<LocationsListing />}
                        />
                        <Route
                          path="/field-guide/locations/:location"
                          element={<LocationDetail />}
                        />
                        <Route
                          path="/field-guide/moves"
                          element={<MovesListing />}
                        />
                        <Route
                          path="/field-guide/moves/:move"
                          element={<MoveDetail />}
                        />
                        <Route
                          path="/field-guide/support-skills"
                          element={<SupportSkillsListing />}
                        />
                      </Route>
                      <Route path="/digilab">
                        <Route path="/digilab" element={<DigilabLanding />} />
                        <Route
                          path="/digilab/roster"
                          element={<RosterListing />}
                        />
                        <Route
                          path="/digilab/roster/:roster"
                          element={<RosterPage />}
                        />
                        <Route
                          path="/digilab/teams"
                          element={<TeamsListing />}
                        />
                        <Route
                          path="/digilab/teams/:team"
                          element={<TeamPage />}
                        />
                      </Route>
                      <Route path="/admin">
                        <Route path="/admin" element={<Dashboard />} />
                      </Route>
                      <Route path="/account" element={<Account />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="*" element={<p>No match</p>} />
                    </Routes>
                  </Wrapper>
                </BrowserRouter>
              </NotificationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </AuthProvider>
      </TranslationWrapper>
    </HelmetProvider>
  );
}

export default App;
