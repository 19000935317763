import type { TranslationPath } from 'Translation/Types';
import { useTranslation } from 'react-i18next';

function Translate({
  path,
}: {
  path: TranslationPath;
  subTranslations?: {
    key: string;
    path: TranslationPath;
  }[];
  values?: Record<string, string | number>;
}): JSX.Element {
  const { t: translate } = useTranslation();

  return <>{translate(path)}</>;
}

export default Translate;
