/** @jsxImportSource @emotion/react */
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import type { RosterMove } from 'Modules/Digilab';
import {
  Attribute,
  getAttributeColour,
  getAttributeFontColour,
  getMoveTypeLabel,
} from 'Modules/FieldGuide';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListRow,
  DescriptionTerm,
} from 'Modules/Shared';
import { Translate } from 'Translation';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

function RosterMoveListingItem({
  move,
  onOpenAddModal,
}: {
  move: RosterMove;
  onOpenAddModal: (slotNumber: number) => void;
}): JSX.Element {
  const {
    avatar,
    avatarContainer,
    button,
    card,
    contents,
    footer,
    title,
    stickyMenuButton,
  } = styles;

  const bgColor = useMemo(
    () => getAttributeColour(move.move.attribute ?? Attribute.Neutral),
    [move],
  );
  const fontColor = useMemo(
    () => getAttributeFontColour(move.move.attribute ?? Attribute.Neutral),
    [move],
  );

  console.log(move);

  const navigate = useNavigate();

  const navigateTo = () => {};

  const isFilled = useMemo(() => !!move.id, [move.id]);
  const isSignature = useMemo(() => !!move.move.signature, [move.move]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ ...card, bgcolor: bgColor, color: fontColor }}>
      <CardContent>
        <Stack
          sx={{ position: 'relative' }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography sx={{ ...title, color: fontColor }} gutterBottom>
            {isFilled ? move.move.name : 'Empty'}
          </Typography>
          {!isSignature && (
            <>
              {isFilled ? (
                <IconButton
                  id="edit-button"
                  color="inherit"
                  aria-label="edit"
                  sx={stickyMenuButton}
                  onClick={handleClick}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <IconButton
                  id="add-button"
                  color="inherit"
                  aria-label="edit"
                  sx={stickyMenuButton}
                  onClick={() => onOpenAddModal(move.number)}
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'edit-button',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>Edit</MenuItem>
            <MenuItem onClick={handleClose}>Remove</MenuItem>
          </Menu>
        </Stack>
        {isFilled && (
          <Box sx={contents}>
            <DescriptionList>
              <DescriptionListRow>
                <DescriptionTerm>
                  <Translate path="Pages.FieldGuide.MovesListing.listingItem.type" />
                  :
                </DescriptionTerm>
                <DescriptionDetails>
                  <Translate path={getMoveTypeLabel(move.move.type)} />
                </DescriptionDetails>
              </DescriptionListRow>
              <DescriptionListRow>
                <DescriptionTerm>
                  <Translate path="Pages.FieldGuide.MovesListing.listingItem.spUse" />
                  :
                </DescriptionTerm>
                <DescriptionDetails>{move.move.spCost}</DescriptionDetails>
              </DescriptionListRow>
              {!!move.move.power && (
                <DescriptionListRow>
                  <DescriptionTerm>
                    <Translate path="Pages.FieldGuide.MovesListing.listingItem.power" />
                    :
                  </DescriptionTerm>
                  <DescriptionDetails>{move.move.power}</DescriptionDetails>
                </DescriptionListRow>
              )}
            </DescriptionList>
            <Box sx={avatarContainer}>
              <Avatar sx={avatar} alt={move.move.name} src={move.move.icon} />
            </Box>
          </Box>
        )}
      </CardContent>
      {isFilled && (
        <CardActions sx={footer}>
          <Button
            onClick={navigateTo}
            sx={{ ...button, color: fontColor }}
            size="small"
          >
            See more
          </Button>
          <Button sx={{ ...button, color: fontColor }} size="small">
            Like
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default React.memo(RosterMoveListingItem);
