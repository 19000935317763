import type { TranslationPath } from 'Translation/Types';

import { Attribute } from '../Types';

function getAttributeLabel(attribute?: Attribute): TranslationPath {
  switch (attribute) {
    case Attribute.Dark:
      return 'FieldGuide.attribute.dark';

    case Attribute.Earth:
      return 'FieldGuide.attribute.earth';

    case Attribute.Electric:
      return 'FieldGuide.attribute.electric';

    case Attribute.Fire:
      return 'FieldGuide.attribute.fire';

    case Attribute.Light:
      return 'FieldGuide.attribute.light';

    case Attribute.Neutral:
      return 'FieldGuide.attribute.neutral';

    case Attribute.Plant:
      return 'FieldGuide.attribute.plant';

    case Attribute.Water:
      return 'FieldGuide.attribute.water';

    case Attribute.Wind:
      return 'FieldGuide.attribute.wind';

    default:
      return 'FieldGuide.attribute.neutral';
  }
}

export default getAttributeLabel;
