import type { Digimon, DigivolutionConditions } from 'Modules/FieldGuide';

import type { Roster } from '../Types';
import type { DigivolutionConditionChecks } from '../types';

function digimonMeetsDigivolutionConditions(
  roster: Roster,
  potentialDigivolution: Digimon,
): DigivolutionConditionChecks {
  const conditions =
    potentialDigivolution.digivolutionConditions as DigivolutionConditions;

  const requirements = {
    ability: conditions.ability ?? 0,
    attack: conditions.attack ?? 0,
    camaradarie: conditions.camaradarie ?? 0,
    defence: conditions.defence ?? 0,
    hp: conditions.hp ?? 0,
    intelligence: conditions.intelligence ?? 0,
    level: conditions.level ?? 0,
    sp: conditions.sp ?? 0,
    speed: conditions.speed ?? 0,
  };

  const matchedConditions: DigivolutionConditionChecks = {
    matches: {
      ability: false,
      all: false,
      attack: false,
      camaradarie: false,
      defence: false,
      hp: false,
      intelligence: false,
      level: requirements.level <= (roster.level as number),
      sp: false,
      speed: false,
    },
    requirements: {
      ...requirements,
    },
    values: {
      ability: 0,
      attack: 0,
      camaradarie: 0,
      defence: 0,
      hp: 0,
      intelligence: 0,
      level: roster.level as number,
      sp: 0,
      speed: 0,
    },
  };

  const matches: boolean[] = [];

  roster.stats.forEach((stat) => {
    const name = stat.name as keyof typeof matchedConditions.values;
    matchedConditions.values[name] = stat.value + stat.increasedBy;
    matchedConditions.matches[name] =
      requirements[name] <= matchedConditions.values[name];
    matches.push(matchedConditions.matches[name]);
  });

  matchedConditions.matches.all = !matches.includes(false);

  return matchedConditions;
}

export default digimonMeetsDigivolutionConditions;
