import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from 'Store/store';

// Define a service using a base URL and expected endpoints
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL ?? ''}/`,
    prepareHeaders: (headers, { getState }) => {
      const stateToken = (getState() as RootState).authSlice.accessToken;
      const persistedToken = localStorage.getItem(
        process.env.REACT_APP_JWT_ACCESS_TOKEN ?? '@access-token',
      );

      const token = stateToken ?? persistedToken;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    ping: builder.query({
      query: () => '',
    }),
    // refresh: builder.mutation<AuthResponse, RefreshRequest>({
    //   query: (refreshToken) => ({
    //     url: `auth/refresh`,
    //     method: 'POST',
    //     body: refreshToken,
    //   }),
    //   async onQueryStarted(args, { dispatch, queryFulfilled }) {
    //     try {
    //       const query = await queryFulfilled;
    //       await dispatch(
    //         setCredentials({
    //           access_token: query.data.access_token,
    //           refresh_token: query.data.refresh_token,
    //         }),
    //       );
    //     } catch (error) {
    //       console.error('super sad face');
    //     }
    //   },
    // }),
  }),
  reducerPath: 'api',
  tagTypes: [
    'Digimon',
    'Moves',
    'Locations',
    'Items',
    'SupportSkills',
    'Teams',
    'Roster',
    'Search',
    'ActivityLog',
  ],
});
