import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import type { Roster } from 'Modules/Digilab';
import { getTeamMemberPersonalityLabel } from 'Modules/Digilab';
import type { DigimonType } from 'Modules/FieldGuide';
import {
  Attribute,
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListRow,
  DescriptionTerm,
} from 'Modules/Shared';
import { Translate } from 'Translation';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

function RosterListingItem({ roster }: { roster: Roster }): JSX.Element {
  const {
    avatar,
    avatarContainer,
    button,
    card,
    contents,
    footer,
    metaLabel,
    subtitle,
    title,
  } = styles;
  const navigate = useNavigate();

  const attribute = useMemo(
    () =>
      roster && !!roster.digimon.attribute
        ? roster.digimon.attribute
        : Attribute.Neutral,
    [roster],
  );
  const bgColor = useMemo(() => getAttributeColour(attribute), [roster]);
  const fontColor = useMemo(() => getAttributeFontColour(attribute), [roster]);
  const showName = useMemo(
    () => roster.nickname !== roster.digimon.name,
    [roster],
  );

  const navigateTo = () => {
    navigate(`/digilab/roster/${roster.id}`);
  };

  return (
    <Card sx={{ ...card, bgcolor: bgColor }}>
      <CardContent>
        <Stack direction="row" alignItems="flex-end" marginBottom={1}>
          <Typography sx={{ ...title, color: fontColor }}>
            {roster.nickname}
          </Typography>
          {showName && (
            <Typography sx={{ ...subtitle, color: fontColor, ml: 1 }}>
              {roster.digimon.name}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          <DescriptionList>
            <DescriptionListRow>
              <DescriptionTerm>
                <Typography sx={{ ...metaLabel, color: fontColor }}>
                  Lv.
                </Typography>
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  sx={{ ...metaLabel, color: fontColor }}
                  gutterBottom
                >
                  {roster.level}
                </Typography>
              </DescriptionDetails>
            </DescriptionListRow>
            <DescriptionListRow>
              <DescriptionTerm>
                <Typography sx={{ ...metaLabel, color: fontColor }}>
                  Mem.
                </Typography>
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography sx={{ ...metaLabel, color: fontColor }}>
                  {roster.digimon.memoryUsage}
                </Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          </DescriptionList>
          <Stack sx={contents}>
            <Stack direction="row" spacing={1}>
              <Stack>
                <Chip
                  sx={{ color: fontColor, mb: 1 }}
                  label={
                    <Translate
                      path={getDigimonTypeLabel(
                        roster.digimon.type as DigimonType,
                      )}
                    />
                  }
                />
                <Chip
                  sx={{ color: fontColor }}
                  label={<Translate path={getAttributeLabel(attribute)} />}
                />
              </Stack>
              <Stack>
                <Chip
                  sx={{ color: fontColor }}
                  label={
                    <Translate
                      path={getTeamMemberPersonalityLabel(roster.personality)}
                    />
                  }
                />
              </Stack>
            </Stack>
            <Stack sx={avatarContainer}>
              <Avatar
                sx={avatar}
                alt={roster.nickname}
                src={roster.digimon.image}
              />
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions sx={footer}>
        <Button
          onClick={navigateTo}
          sx={{ ...button, color: fontColor }}
          size="small"
        >
          See more
        </Button>
        <Button sx={{ ...button, color: fontColor }} size="small">
          Like
        </Button>
      </CardActions>
    </Card>
  );
}

export default RosterListingItem;
