import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Stack,
} from '@mui/material';
import { useUpdateMeMutation } from 'Modules/Profile';
import {
  Accent,
  Theme,
  largeCornerRadius,
  palette,
  themeContext,
  white,
} from 'Theme';
import { TranslateTypography, useTranslation } from 'Translation';
import React, { useCallback, useContext } from 'react';

const styles = {
  blueAccentButton: {
    '&:hover': {
      bgcolor: palette.blue.dark,
    },
    bgcolor: palette.blue.main,
  },
  button: {
    borderRadius: largeCornerRadius,
    color: white,
    fontWeight: 600,
    height: '75px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  greenAccentButton: {
    '&:hover': {
      bgcolor: palette.green.dark,
    },
    bgcolor: palette.green.main,
  },
  mauveAccentButton: {
    '&:hover': {
      bgcolor: palette.mauve.dark,
    },
    bgcolor: palette.mauve.main,
  },
  newsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  purpleAccentButton: {
    '&:hover': {
      bgcolor: palette.purple.dark,
    },
    bgcolor: palette.purple.main,
  },
  redAccentButton: {
    '&:hover': {
      bgcolor: palette.red.dark,
    },
    bgcolor: palette.red.main,
  },
  yellowAccentButton: {
    '&:hover': {
      bgcolor: palette.yellow.dark,
    },
    bgcolor: palette.yellow.main,
  },
};

const ThemeSettings: React.FC = () => {
  const {
    button,
    blueAccentButton,
    greenAccentButton,
    yellowAccentButton,
    redAccentButton,
    mauveAccentButton,
    purpleAccentButton,
  } = styles;

  const [updateProfile, { isLoading }] = useUpdateMeMutation();
  const context = useContext(themeContext);

  const setTheme = useCallback((theme: Theme) => {
    updateProfile({ theme });
    context.setTheme(theme);
  }, []);
  const setAccent = useCallback((accent: Accent) => {
    updateProfile({ accent });
    context.setAccent(accent);
  }, []);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="round-up-content"
        id="round-up-header"
      >
        <TranslateTypography
          sx={{ fontWeight: 'bold' }}
          path="Pages.Profile.Account.settings.theme.title"
        />
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <TranslateTypography path="Pages.Profile.Account.settings.theme.description" />
          <TranslateTypography
            sx={{ fontWeight: 'bold' }}
            path="Pages.Profile.Account.settings.theme.theme.title"
          />

          <Stack>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  startIcon={<PersonIcon />}
                  sx={{ ...button }}
                  variant="contained"
                  onClick={() => setTheme(Theme.System)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.theme.buttons.system',
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  startIcon={<Brightness7Icon />}
                  sx={{ ...button }}
                  variant="contained"
                  onClick={() => setTheme(Theme.Light)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.theme.buttons.light',
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  startIcon={<Brightness4Icon />}
                  sx={{ ...button }}
                  variant="contained"
                  onClick={() => setTheme(Theme.Dark)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.theme.buttons.dark',
                  )}
                </Button>
              </Grid>
            </Grid>
          </Stack>

          <Divider />

          <TranslateTypography
            sx={{ fontWeight: 'bold' }}
            path="Pages.Profile.Account.settings.theme.accent.title"
          />

          <Stack>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  sx={{ ...button, ...blueAccentButton }}
                  variant="contained"
                  onClick={() => setAccent(Accent.Blue)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.accent.buttons.blue',
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  sx={{ ...button, ...greenAccentButton }}
                  variant="contained"
                  onClick={() => setAccent(Accent.Green)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.accent.buttons.green',
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  sx={{ ...button, ...yellowAccentButton }}
                  variant="contained"
                  onClick={() => setAccent(Accent.Yellow)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.accent.buttons.yellow',
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  sx={{ ...button, ...redAccentButton }}
                  variant="contained"
                  onClick={() => setAccent(Accent.Red)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.accent.buttons.red',
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  sx={{ ...button, ...mauveAccentButton }}
                  variant="contained"
                  onClick={() => setAccent(Accent.Mauve)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.accent.buttons.mauve',
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} lg={3} xl={2}>
                <Button
                  sx={{ ...button, ...purpleAccentButton }}
                  variant="contained"
                  onClick={() => setAccent(Accent.Purple)}
                >
                  {useTranslation(
                    'Pages.Profile.Account.settings.theme.accent.buttons.purple',
                  )}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ThemeSettings;
