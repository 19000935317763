import type { Digimon } from 'Modules/FieldGuide';
import type { Range } from 'Modules/Shared';

import type { RosterEquipment } from './rosterEquipment';
import type { RosterMove } from './rosterMove';
import type { RosterStats } from './rosterStats';

export type RosterLevel =
  | Range<1, 51>
  | Range<51, 76>
  | Range<76, 92>
  | 92
  | 93
  | 94
  | 95
  | 96
  | 97
  | 98
  | 99;

export enum RosterPersonality {
  Brainy = 'brainy',
  Builder = 'builder',
  Defender = 'defender',
  Durable = 'durable',
  Fighter = 'fighter',
  Lively = 'lively',
  Nimble = 'nimble',
  Searcher = 'searcher',
}

export interface RosterDigivolutionHistory {
  id: number;
  level: number;
  digimon: Digimon;
}

export interface Roster {
  id: number;
  nickname: string;
  level: RosterLevel;
  personality: RosterPersonality;
  exp: number;
  digimon: Partial<Digimon>;
  stats: RosterStats[];
  moves: RosterMove[];
  equipment: RosterEquipment[];
  digivolutionHistory?: RosterDigivolutionHistory[];
  hidden: boolean;
  merged: boolean;
}
