/** @jsxImportSource @emotion/react */
import LoadingButton from '@mui/lab/LoadingButton';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import type { Roster, RosterLevel, RosterPersonality } from 'Modules/Digilab';
import { usePatchRosterMutation } from 'Modules/Digilab';
import { personalityList } from 'Modules/Digilab/Constants';
import { Translate, useTranslation } from 'Translation';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

interface FormData {
  camaradarie: number;
  ability: number;
  attack: number;
  attackIncreasedBy: number;
  defence: number;
  defenceIncreasedBy: number;
  intelligence: number;
  intelligenceIncreasedBy: number;
  speed: number;
  speedIncreasedBy: number;
  sp: number;
  spIncreasedBy: number;
  hp: number;
  hpIncreasedBy: number;
}

function ChangeMetaInformationModal({
  isOpen = false,
  onClose,
  roster,
}: {
  isOpen: boolean;
  onClose: () => void;
  roster: Roster;
}): JSX.Element {
  const [patchRoster, result] = usePatchRosterMutation();

  const { control, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      exp: roster.exp,
      level: roster.level,
      nickname: roster.nickname,
      personality: roster.personality,
    },
  });

  const watchedPersonality = watch('personality', roster.personality);

  const onSubmit = (data: any) => {
    const toSubmit = {
      exp: data.exp,
      id: roster.id,
      level: data.level,
      nickname: data.nickname,
      personality: data.personality,
    };

    patchRoster(toSubmit);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {useTranslation('Pages.Digilab.Roster.meta.modal.title')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2} spacing={2}>
            <Controller
              name="nickname"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="nickname" shrink={true}>
                    <Translate path="Pages.Digilab.Roster.meta.modal.fields.nickname" />
                  </InputLabel>
                  <TextField
                    id="nickname"
                    label={
                      <Translate path="Pages.Digilab.Roster.meta.modal.fields.nickname" />
                    }
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue('nickname', e.target.value)
                    }
                    variant="outlined"
                  />
                </FormControl>
              )}
            />
            <Controller
              name="level"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="camaradarie" shrink={true}>
                    <Translate path="Pages.Digilab.Roster.meta.modal.fields.level" />
                  </InputLabel>
                  <TextField
                    id="level"
                    label={
                      <Translate path="Pages.Digilab.Roster.meta.modal.fields.level" />
                    }
                    {...field}
                    InputProps={{ inputProps: { max: 99, min: 0 } }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      const level = Number(e.target.value);
                      setValue('level', level as RosterLevel);
                    }}
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              )}
            />
            <Controller
              name="exp"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="exp" shrink={true}>
                    <Translate path="Pages.Digilab.Roster.meta.modal.fields.exp" />
                  </InputLabel>
                  <TextField
                    id="exp"
                    label={
                      <Translate path="Pages.Digilab.Roster.meta.modal.fields.exp" />
                    }
                    {...field}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue('exp', Number(e.target.value))
                    }
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              )}
            />
            <Controller
              name="personality"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="exp" shrink={true}>
                    <Translate path="Pages.Digilab.RosterListing.modals.add.fields.personality" />
                  </InputLabel>
                  <Select
                    label={
                      <Translate path="Pages.Digilab.RosterListing.modals.add.fields.personality" />
                    }
                    value={`${watchedPersonality ?? ''}`}
                    onChange={(e: SelectChangeEvent) =>
                      setValue(
                        'personality',
                        e.target.value as RosterPersonality,
                        { shouldValidate: true },
                      )
                    }
                  >
                    {personalityList.map((personality) => (
                      <MenuItem
                        value={personality.value}
                        key={personality.value}
                      >
                        <Translate path={personality.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation('Pages.Digilab.Roster.meta.modal.buttons.cancel')}
        </Button>
        <LoadingButton
          loading={result.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {useTranslation('Pages.Digilab.Roster.meta.modal.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeMetaInformationModal;
