import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import type { DrawerRoute } from 'Modules/Shared';
import { useTranslation } from 'Translation';
import React, { useCallback, useState } from 'react';

interface Props {
  nested?: boolean;
  route: DrawerRoute;
  navigateTo(route: string): void;
}

const DrawerItem: React.FC<Props> = ({ nested = false, route, navigateTo }) => {
  const [openChildRoutes, setOpenChildRoutes] = useState<boolean>(false);

  const toggleCollapse = useCallback(() => {
    setOpenChildRoutes(!openChildRoutes);
  }, [openChildRoutes]);

  const navigate = useCallback(() => {
    navigateTo(route.path);
  }, [navigateTo, route.path]);

  const renderChildren = useCallback(
    (nestedRoute: DrawerRoute) => (
      <DrawerItem
        route={nestedRoute}
        navigateTo={navigateTo}
        key={nestedRoute.name}
        nested
      />
    ),
    [navigateTo],
  );
  const routeName = useTranslation(route.name);

  if (route.children) {
    const children = route.children.map(renderChildren);
    return (
      <>
        <ListItemButton sx={{ pl: nested ? 4 : 2 }} onClick={toggleCollapse}>
          {route.icon && <ListItemIcon>{route.icon}</ListItemIcon>}
          <ListItemText primary={routeName} />
          {openChildRoutes ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openChildRoutes} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
        {route.divider && <Divider />}
      </>
    );
  }

  return (
    <>
      <ListItemButton sx={{ pl: nested ? 4 : 2 }} onClick={navigate}>
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText primary={routeName} />
      </ListItemButton>
      {route.divider && <Divider />}
    </>
  );
};

export default DrawerItem;
