import AccountCircle from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FieldGuideIcon from '@mui/icons-material/Class';
import HomeIcon from '@mui/icons-material/Home';
import ScienceIcon from '@mui/icons-material/Science';
import {
  Box,
  Divider,
  IconButton,
  List,
  SwipeableDrawer as MuiDrawer,
  useTheme,
} from '@mui/material';
import { authContext } from 'Modules/Auth';
import { toggleDrawer } from 'Modules/Shared';
import type { RootState } from 'Store';
import { drawerWidth } from 'Theme';
import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DrawerItem from './DrawerItem';

function Drawer(): JSX.Element {
  const authCtx = useContext(authContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { isDrawerOpen } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  const closeDrawer = useCallback(() => {
    dispatch(toggleDrawer(false));
  }, []);

  const openDrawer = useCallback(() => {
    dispatch(toggleDrawer(true));
  }, []);

  const routes = useMemo(() => {
    const rawRoutes: any[] = [];

    return rawRoutes.map((route) => (
      <DrawerItem key={route.name} navigateTo={navigate} route={route} />
    ));
  }, [navigate]);

  return (
    <MuiDrawer
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
        bgcolor: 'background.paper',
        flexShrink: 0,
        width: drawerWidth,
      }}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      onClose={closeDrawer}
      onOpen={openDrawer}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          padding: theme.spacing(1.45, 1),
          ...theme.mixins.toolbar,
          justifyContent: 'flex-end',
        }}
      >
        <IconButton onClick={closeDrawer}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </Box>
      <Divider />
      <List>
        <DrawerItem
          navigateTo={navigate}
          route={{
            icon: <HomeIcon />,
            name: 'Navigation.Drawer.home',
            path: '/',
          }}
        />

        <DrawerItem
          navigateTo={navigate}
          route={{
            children: [
              {
                name: 'Navigation.Drawer.fieldGuide.digimon',
                path: '/field-guide/digimon',
              },
              {
                name: 'Navigation.Drawer.fieldGuide.items',
                path: '/field-guide/items',
              },
              {
                name: 'Navigation.Drawer.fieldGuide.moves',
                path: '/field-guide/moves',
              },
              {
                name: 'Navigation.Drawer.fieldGuide.locations',
                path: '/field-guide/locations',
              },
            ],
            divider: true,
            icon: <FieldGuideIcon />,
            name: 'Navigation.Drawer.fieldGuide.name',
            path: '/field-guide',
          }}
        />

        {authCtx.isAuthenticated && (
          <>
            <DrawerItem
              navigateTo={navigate}
              route={{
                children: [
                  {
                    name: 'Navigation.Drawer.digilab.roster',
                    path: '/digilab/roster',
                  },
                  {
                    name: 'Navigation.Drawer.digilab.teams',
                    path: '/digilab/teams',
                  },
                ],
                icon: <ScienceIcon />,
                name: 'Navigation.Drawer.digilab.name',
                path: '/digilab',
              }}
            />
            <DrawerItem
              navigateTo={navigate}
              route={{
                icon: <AccountCircle />,
                name: 'Navigation.Drawer.profile.name',
                path: '/account',
              }}
            />
            <DrawerItem
              navigateTo={navigate}
              route={{
                icon: <AccountCircle />,
                name: 'Navigation.Drawer.profile.name',
                path: '/admin',
              }}
            />
          </>
        )}
        {routes}
      </List>
    </MuiDrawer>
  );
}

export default Drawer;
