import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { authContext, setCredentials } from 'Modules/Auth';
import type { RootState } from 'Store';
import { drawerWidth, white } from 'Theme';
import { useTranslation } from 'Translation';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const LoginModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const authCtx = useContext(authContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { appBarBreadcrumbs, appBarStyling, isAppBarLoading, isDrawerOpen } =
    useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const theme = useTheme();

  // const [login, { isLoading }] = useLoginMutation();

  const fontColor = useMemo(
    () => (appBarStyling.color ? appBarStyling.color : white),
    [appBarStyling],
  );

  const onLogin = useCallback(async () => {
    try {
      // const result = await login({ username: email, password });
      // if ('data' in result) {
      //   authCtx.setTokens(result.data.access_token, result.data.refresh_token);
      //   onClose();
      // }
    } catch (err) {
      console.log(err);
    }
  }, [email, password]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box>
          <Typography component="h3" variant="h4">
            Sign In
          </Typography>
          <Grid mt={3} width="100%">
            <TextField
              id="login-modal-email"
              label={useTranslation('Components.LoginModal.email.label')}
              variant="outlined"
              placeholder={useTranslation(
                'Components.LoginModal.email.placeholder',
              )}
              sx={{ mb: 2, width: '100%' }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="login-modal-password"
              label={useTranslation('Components.LoginModal.password.label')}
              variant="outlined"
              placeholder={useTranslation(
                'Components.LoginModal.password.placeholder',
              )}
              type="password"
              sx={{ width: '100%' }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={onClose}>
          {useTranslation('Components.LoginModal.actions.close')}
        </Button>
        <Button onClick={onLogin}>
          {useTranslation('Components.LoginModal.actions.login')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginModal;
