/** @jsxImportSource @emotion/react */
import { Button, Grid, Stack } from '@mui/material';
import type { Roster, RosterMove } from 'Modules/Digilab';
import { RosterMoveSlot } from 'Modules/Digilab';
import { useModalProps } from 'Modules/Shared';
import { Translate, useTranslation } from 'Translation';
import React, { useMemo, useState } from 'react';

import { ChangeMovesModal } from '../../modals';

interface Props {
  roster: Roster;
}

const Moves: React.FC<Props> = ({ roster }) => {
  const [selectedSlot, setSelectedSlot] = useState<number>(1);
  const editModalProps = useModalProps();

  const openAddModal = (slotNumber: number) => {
    setSelectedSlot(slotNumber);
    editModalProps.onOpen();
  };

  const { moves, digimon } = roster;

  const processedMoves = useMemo(() => {
    const baseMoves: RosterMove[] = [...moves];
    const slotsFilled = moves.length ?? 0;

    if (slotsFilled === 20) {
      return baseMoves;
    }

    for (let index = slotsFilled; index < 20; index++) {
      baseMoves.push({
        move: {},
        number: index + 1,
      } as RosterMove);
    }

    return baseMoves;
  }, [moves]);

  return (
    <Stack>
      <Stack mb={3} flexDirection="row">
        <Button onClick={editModalProps.onOpen}>
          {useTranslation('Pages.Digilab.Roster.moves.buttons.setMoves')}
        </Button>
      </Stack>
      <Grid container spacing={2}>
        {processedMoves.map((move, index) => (
          <Grid item xs={6} key={`move-${move.id ?? `empty-${index}`}`}>
            <RosterMoveSlot move={move} onOpenAddModal={openAddModal} />
          </Grid>
        ))}
      </Grid>
      <ChangeMovesModal
        isOpen={editModalProps.isOpen}
        onClose={editModalProps.onClose}
        roster={roster}
      />
    </Stack>
  );
};

export default Moves;
