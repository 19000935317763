/** @jsxImportSource @emotion/react */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Link, Paper, Stack, Tab, Typography } from '@mui/material';
import {
  DigimonListingItem,
  ItemListingItem,
  LocationListingItem,
} from 'Modules/FieldGuide/Components';
import { useGetLocationByIDQuery } from 'Modules/FieldGuide/Store';
import type { Location } from 'Modules/FieldGuide/Types';
import { useEntityPage } from 'Modules/Shared';
import { largeCornerRadius, white } from 'Theme';
import { Translate, useTranslation } from 'Translation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import styles from './styles';

const LocationDetail: React.FC = () => {
  const params = useParams<{ location?: string | undefined }>();
  const locationId = params.location ?? 0;
  const query = useGetLocationByIDQuery(locationId);
  const location = query.data as Location;

  const getDefaultTab = useCallback((location?: Location) => {
    if (location) {
      if (location.digimon.length > 0) {
        return '1';
      } else if (location.itemsSold.length > 0) {
        return '2';
      } else {
        return '3';
      }
    }

    return '1';
  }, []);

  const [selectedTab, setSelectedTab] = useState<string>('');
  const { chipBlock, container, headerRow } = styles;

  const title = useTranslation('Pages.FieldGuide.title');
  const getTitle = () => (query.data ? `${title} - ${query.data.name}` : '');

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    {
      label: useTranslation('Pages.FieldGuide.LocationsListing.breadcrumb'),
      to: '/field-guide/locations',
    },
  ];

  useEntityPage(query.data, getTitle, breadcrumbs, !!query.isLoading);

  const handleTabClick = (event: any, newValue: string) => {
    setSelectedTab(newValue);
  };

  const childLocations = useMemo(
    () =>
      location
        ? location.children.map((location) => (
            <Grid
              xs={12}
              sm={6}
              lg={3}
              xl={2}
              item
              key={`child-location-${location.id}`}
            >
              <LocationListingItem location={location} />
            </Grid>
          ))
        : [],
    [location],
  );
  const items = useMemo(
    () =>
      location
        ? location.itemsSold.map((item) => (
            <Grid xs={12} sm={6} lg={3} xl={2} item key={`item-${item.id}`}>
              <ItemListingItem item={item} />
            </Grid>
          ))
        : [],
    [location],
  );

  useEffect(() => {
    if (!!location && selectedTab === '') {
      setSelectedTab(getDefaultTab(location));
    }
  }, [location, selectedTab]);

  if (!!location && query.isSuccess) {
    return (
      <Box sx={container}>
        <Stack sx={{ p: 2, pb: 4, px: 4 }}>
          <Box sx={headerRow}>
            <Typography
              component="h1"
              variant="h2"
              sx={{ color: white, mb: 1 }}
            >
              {location.name}
            </Typography>
          </Box>
          {location.parent && (
            <Box sx={headerRow}>
              <Box sx={chipBlock}>
                <Link
                  sx={{ color: '#fff' }}
                  component={RouterLink}
                  to={`/field-guide/locations/${location.parent.id}`}
                >
                  {location.parent.name}
                </Link>
              </Box>
            </Box>
          )}
        </Stack>
        <Paper
          sx={{
            borderRadius: `${largeCornerRadius} ${largeCornerRadius} 0 0`,
            height: '100%',
            mt: -2,
            p: 2,
          }}
          elevation={3}
        >
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
              <TabList onChange={handleTabClick} aria-label="tabs">
                {location.digimon.length > 0 && (
                  <Tab
                    label={
                      <Translate path="Pages.FieldGuide.Locations.tabs.digimon" />
                    }
                    value="1"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {location.itemsSold.length > 0 && (
                  <Tab
                    label={
                      <Translate path="Pages.FieldGuide.Locations.tabs.items" />
                    }
                    value="2"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {location.children.length > 0 && (
                  <Tab
                    label={
                      <Translate path="Pages.FieldGuide.Locations.tabs.subLocations" />
                    }
                    value="3"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
              </TabList>
            </Box>
            {location.digimon.length > 0 && (
              <TabPanel value="1">
                <Grid container spacing={2}>
                  {location.digimon.map((digimon) => (
                    <Grid
                      xs={12}
                      sm={6}
                      lg={3}
                      xl={2}
                      item
                      key={`digimon-${digimon.id}`}
                    >
                      <DigimonListingItem digimon={digimon} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
            {location.itemsSold.length > 0 && (
              <TabPanel value="2">
                <Grid container spacing={2}>
                  {items}
                </Grid>
              </TabPanel>
            )}
            {location.children.length > 0 && (
              <TabPanel value="3">
                <Grid container spacing={2}>
                  {childLocations}
                </Grid>
              </TabPanel>
            )}
          </TabContext>
        </Paper>
      </Box>
    );
  }

  return <></>;
};

export default LocationDetail;
