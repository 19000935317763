import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import { useUpdateMeMutation } from 'Modules/Profile';
import { largeCornerRadius, palette, themeContext, white } from 'Theme';
import { Translate, TranslateTypography, useTranslation } from 'Translation';
import React, { useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  name: string;
  username: string;
}

const InfoSettings: React.FC<Props> = ({ name, username }) => {
  const [updateProfile, { isLoading }] = useUpdateMeMutation();
  const context = useContext(themeContext);

  const { setValue, handleSubmit, reset, watch, register, control } = useForm({
    defaultValues: {
      name: name,
      username: username,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="round-up-content"
        id="round-up-header"
      >
        <TranslateTypography
          sx={{ fontWeight: 'bold' }}
          path="Pages.Profile.Account.settings.information.title"
        />
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <TranslateTypography path="Pages.Profile.Account.settings.information.description" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} width="100%">
              <Grid item xs={12} sm={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel htmlFor="name" shrink={true}>
                        <Translate path="Pages.Profile.Account.settings.information.fields.name" />
                      </InputLabel>
                      <TextField
                        id="name"
                        label={
                          <Translate path="Pages.Profile.Account.settings.information.fields.name" />
                        }
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue('name', e.target.value)
                        }
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel htmlFor="username" shrink={true}>
                        <Translate path="Pages.Profile.Account.settings.information.fields.username" />
                      </InputLabel>
                      <TextField
                        id="username"
                        label={
                          <Translate path="Pages.Profile.Account.settings.information.fields.username" />
                        }
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue('username', e.target.value)
                        }
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default InfoSettings;
