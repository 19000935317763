/** @jsxImportSource @emotion/react */
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import type { Roster } from 'Modules/Digilab';
import {
  generateStats,
  getStatIncreasedByValue,
  getTeamMemberPersonalityLabel,
} from 'Modules/Digilab';
import type { Digimon } from 'Modules/FieldGuide';
import {
  getAttributeColour,
  getAttributeLabel,
  getDigimonStatLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide';
import { useModalProps } from 'Modules/Shared';
import { Translate } from 'Translation';
import React, { useMemo } from 'react';

import { ChangeStatsModal } from '../../modals';
import styles from '../../styles';

interface TeamMemberStat {
  id: number;
  name: string;
  value: number;
  increasedBy: number;
  maxValue: number;
}

interface ProcessedStats {
  basic: {
    cam: TeamMemberStat;
    abi: TeamMemberStat;
  };
  progressable: TeamMemberStat[];
}

interface Props {
  roster: Roster;
}

const getProgressValue = (value: number, maxValue: number): number =>
  (value / maxValue) * 100;

const About: React.FC<Props> = ({ roster }) => {
  const statsModalProps = useModalProps();
  const digimon = roster.digimon as Digimon;
  const {
    definitionList,
    definitionListRow,
    definitionListDefinitionCell,
    definitionListCell,
    definitionListProgressBarCell,
  } = styles(getAttributeColour(digimon.attribute));
  const attributeLabel = getAttributeLabel(digimon.attribute);
  const digimonTypeLabel = getDigimonTypeLabel(digimon.type);

  const processedStats = useMemo(() => generateStats(roster), [roster]);

  return (
    <Stack>
      <Typography component="p" sx={{ mb: 4 }}>
        {digimon.description}
      </Typography>
      <Accordion sx={{ mb: 4 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="round-up-content"
          id="round-up-header"
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.title" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <dl css={definitionList}>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.type" />
              </dt>
              <dd css={definitionListCell}>
                <Translate path={digimonTypeLabel} />
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.attribute" />
              </dt>
              <dd css={definitionListCell}>
                <Translate path={attributeLabel} />
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.ability" />
              </dt>
              <dd css={definitionListCell}>{processedStats.basic.abi.value}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.camaradarie" />
              </dt>
              <dd css={definitionListCell}>
                {processedStats.basic.cam.value}%
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.personality" />
              </dt>
              <dd css={definitionListCell}>
                <Translate
                  path={getTeamMemberPersonalityLabel(roster.personality)}
                />
              </dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.equipmentSlots" />
              </dt>
              <dd css={definitionListCell}>{digimon.equipmentSlot}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListDefinitionCell}>
                <Translate path="Pages.Digilab.DigimonTeamMember.about.accordion.roundUp.memoryUsage" />
              </dt>
              <dd css={definitionListCell}>{digimon.memoryUsage}</dd>
            </div>
          </dl>
        </AccordionDetails>
      </Accordion>

      <Stack flexDirection="row" alignItems="center">
        <Typography sx={{ fontWeight: 'bold', marginRight: '5px' }}>
          <Translate path="Pages.Digilab.DigimonTeamMember.about.stats.title" />
        </Typography>
        <IconButton
          sx={{ color: 'white' }}
          aria-label="edit"
          onClick={statsModalProps.onOpen}
        >
          <EditIcon width={10} />
        </IconButton>
      </Stack>

      <Box
        sx={{
          width: {
            md: '50%',
            sm: '100%',
          },
        }}
      >
        <dl css={definitionList}>
          {processedStats.progressable.map((stat) => (
            <div css={definitionListRow} key={`stat-${stat.id}`}>
              <dt css={definitionListDefinitionCell}>
                <Translate path={getDigimonStatLabel(stat.name)} />
              </dt>
              <dd css={definitionListCell} style={{ textAlign: 'right' }}>
                {stat.value}
              </dd>
              <dd css={definitionListCell} style={{ textAlign: 'right' }}>
                {getStatIncreasedByValue(stat.increasedBy)}
              </dd>
              <dd
                css={definitionListProgressBarCell}
                style={{ width: '200px' }}
              >
                <LinearProgress
                  sx={{ height: '8px' }}
                  variant="determinate"
                  value={getProgressValue(stat.value, stat.maxValue)}
                />
              </dd>
            </div>
          ))}
        </dl>
      </Box>
      <ChangeStatsModal
        isOpen={statsModalProps.isOpen}
        onClose={statsModalProps.onClose}
        roster={roster}
      />
    </Stack>
  );
};

export default About;
