import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { Item, ItemsPaginatedListFilter } from 'Modules/FieldGuide/Types';
import type { PaginatedList, PaginationOptions, StoreEntities } from 'Store';
import { api, providesList } from 'Store';
import urlcat from 'urlcat';

export const itemsApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    getItemByID: build.query<Item, string | number>({
      providesTags: (result, error, arg) => [{ id: arg, type: 'Items' }],
      query: (id) => `items/${id}`,
    }),
    getItems: build.query<
      PaginatedList<Item>,
      PaginationOptions<ItemsPaginatedListFilter>
    >({
      providesTags: (result, error, arg) => providesList(result, 'Items'),
      query: (options = { limit: 40 }) => urlcat('/items', options),
    }),
  }),
});

export const { useGetItemsQuery, useGetItemByIDQuery } = itemsApiSlice;
