import { DigimonStage } from 'Modules/FieldGuide/Types';
import type { PaginationFilterOption } from 'Modules/Shared/Components';
import type { TranslationPath } from 'Translation';

import getDigimonStageLabel from './getDigimonStageLabel';

function getDigimonStagesFilterOptions(): PaginationFilterOption[] {
  const stages = Object.values(DigimonStage);

  const allItem: PaginationFilterOption = {
    id: 'stage-all',
    label: 'Components.Select.all',
    value: 'all',
  };
  const stageMenuItem: PaginationFilterOption[] = stages.map((stage) => ({
    id: `stage-${stage}`,
    label: getDigimonStageLabel(stage) as TranslationPath,
    value: stage as string,
  }));

  return [allItem, ...stageMenuItem];
}

export default getDigimonStagesFilterOptions;
