import DeleteIcon from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import { authContext } from 'Modules/Auth';
import {
  SearchDomains,
  clearPersistedObject,
  getPersistedObject,
  persistObject,
  useSearchQuery,
} from 'Modules/Shared';
import { largeCornerRadius } from 'Theme';
import { Translate, TranslateTypography, useTranslation } from 'Translation';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SearchItem } from './SubComponents';
import SearchList from './SubComponents/SearchList';

interface CachedQueries {
  key: string;
  term: string;
  categories: SearchDomains[];
  count: number;
}

interface Props {}

const persistenceKey = 'universal-search-history';

function getSearchKey(term: string, categories: SearchDomains[]) {
  return `${term}-${categories.join('-')}`;
}

const UniversalSearch: React.FC<Props> = () => {
  const [previousQueries, setPreviousQueries] = useState<CachedQueries[]>([]);
  const [categories, setCategories] = useState<SearchDomains[]>([]);
  const [term, setTerm] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shouldSkip, setShouldSkip] = useState<boolean>(true);
  const searchRTK = useSearchQuery(
    { categories: categories, simplified: false, term: term },
    { skip: categories.length === 0 || term.length === 0 || shouldSkip },
  );

  const authCtx = useContext(authContext);

  const debounced = useMemo(
    () => debounce(() => setShouldSkip(false), 500),
    [],
  );

  const uniSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setShouldSkip(true);
    setTerm(e.target.value);
    debounced();
  }, []);

  const handleAllCheck = useCallback(() => {
    setShouldSkip(false);
    setCategories([SearchDomains.All]);
  }, []);

  const handleCheck = useCallback(
    (value: SearchDomains) => {
      setShouldSkip(false);
      if (categories.includes(SearchDomains.All)) {
        setCategories([value]);
      } else if (categories.includes(value)) {
        setCategories(categories.filter((el) => el !== value).sort());
      } else {
        setCategories([...categories, value].sort());
      }
    },
    [categories],
  );

  const removePreviousQuery = useCallback(
    (key: string) => {
      const updatedHistory = previousQueries.filter((el) => el.key !== key);

      setPreviousQueries(updatedHistory);
      persistObject(persistenceKey, updatedHistory);
    },
    [previousQueries],
  );

  const results = useMemo(() => {
    if (searchRTK.data && searchRTK.data.total > 0) {
      return searchRTK.data;
    }

    return [];
  }, [searchRTK.data]);

  useEffect(() => {
    if (searchRTK.data && !searchRTK.isFetching && !searchRTK.isError) {
      const key = getSearchKey(term, categories);
      const existingQueries = previousQueries.map((el) => el.key);

      if (!existingQueries.includes(key)) {
        const updatedHistory = [
          ...previousQueries,
          {
            categories,
            count: searchRTK.data.total,
            key,
            term,
          },
        ];

        persistObject(persistenceKey, updatedHistory);
        setPreviousQueries(updatedHistory);
        setSearchKey(key);
      }
    }
  }, [
    searchRTK.isFetching,
    searchRTK.isError,
    searchRTK.data,
    term,
    categories,
  ]);

  useEffect(() => {
    const persistedHistory =
      getPersistedObject<CachedQueries[]>(persistenceKey);

    if (persistedHistory && persistedHistory.length > 0) {
      setPreviousQueries(persistedHistory);
    }
  }, []);

  return (
    <>
      <TextField
        id="outlined-basic"
        label={useTranslation(
          'Pages.FieldGuide.DigimonListing.paginationFilters.search.label',
        )}
        variant="outlined"
        placeholder={useTranslation(
          'Pages.FieldGuide.DigimonListing.paginationFilters.search.placeholder',
        )}
        sx={{
          width: '90%',
        }}
        onClick={() => setIsOpen(true)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => {
          setIsOpen(false);
          setTimeout(() => {
            setTerm('');
            setCategories([]);
          }, 500);
        }}
        open={isOpen}
        sx={{
          overflow: 'hidden',
          top: 0,
        }}
        PaperProps={{
          sx: {
            alignSelf: 'flex-start',
            borderRadius: largeCornerRadius,
            top: '50px',
          },
        }}
      >
        <Stack flexDirection="row" alignItems="center" p={2}>
          <Search sx={{ mr: 2 }} />
          <InputBase
            id="universal-search"
            placeholder={useTranslation(
              'Components.UniversalSearch.searchBox.placeholder',
            )}
            sx={{ width: '100%' }}
            onChange={uniSearch}
            value={term}
          />
        </Stack>
        <Divider />
        <Stack p={2}>
          <TranslateTypography path="Components.UniversalSearch.sections.categories.title" />
          <Stack flexDirection="row" mt={1}>
            <Chip
              label={useTranslation(
                'Components.UniversalSearch.sections.categories.chips.all',
              )}
              onClick={handleAllCheck}
              variant={
                categories.includes(SearchDomains.All) ? 'filled' : 'outlined'
              }
              sx={{ mr: 1 }}
            />
            <Chip
              label={useTranslation(
                'Components.UniversalSearch.sections.categories.chips.digimon',
              )}
              onClick={() => handleCheck(SearchDomains.Digimon)}
              variant={
                categories.includes(SearchDomains.Digimon)
                  ? 'filled'
                  : 'outlined'
              }
              sx={{ mr: 1 }}
            />
            <Chip
              label={useTranslation(
                'Components.UniversalSearch.sections.categories.chips.items',
              )}
              onClick={() => handleCheck(SearchDomains.Item)}
              variant={
                categories.includes(SearchDomains.Item) ? 'filled' : 'outlined'
              }
              sx={{ mr: 1 }}
            />
            <Chip
              label={useTranslation(
                'Components.UniversalSearch.sections.categories.chips.locations',
              )}
              onClick={() => handleCheck(SearchDomains.Location)}
              variant={
                categories.includes(SearchDomains.Location)
                  ? 'filled'
                  : 'outlined'
              }
              sx={{ mr: 1 }}
            />
            <Chip
              label={useTranslation(
                'Components.UniversalSearch.sections.categories.chips.moves',
              )}
              onClick={() => handleCheck(SearchDomains.Move)}
              variant={
                categories.includes(SearchDomains.Move) ? 'filled' : 'outlined'
              }
              sx={{ mr: 1 }}
            />
            {authCtx.isAuthenticated && (
              <>
                <Chip
                  label={
                    <Translate path="Components.UniversalSearch.sections.categories.chips.roster" />
                  }
                  onClick={() => handleCheck(SearchDomains.Roster)}
                  variant={
                    categories.includes(SearchDomains.Roster)
                      ? 'filled'
                      : 'outlined'
                  }
                  sx={{ mr: 1 }}
                />
                <Chip
                  label={
                    <Translate path="Components.UniversalSearch.sections.categories.chips.team" />
                  }
                  onClick={() => handleCheck(SearchDomains.Team)}
                  variant={
                    categories.includes(SearchDomains.Team)
                      ? 'filled'
                      : 'outlined'
                  }
                />
              </>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={2}>
          <Stack flexDirection="row" justifyContent="space-between" p={2}>
            <TranslateTypography path="Components.UniversalSearch.sections.items.title" />
            {searchRTK.data && searchRTK.data.total > 0 && (
              <TranslateTypography
                path="Components.UniversalSearch.sections.items.resultsTotal"
                values={{ total: searchRTK.data.total }}
              />
            )}
          </Stack>
          {searchRTK.data && searchRTK.data.total > 0 && (
            <Stack
              px={2}
              pb={2}
              spacing={2}
              sx={{ maxHeight: '30vh', overflowY: 'auto' }}
              alignItems="center"
            >
              <SearchList items={searchRTK.data} />
              {searchRTK.data.total > 10 && (
                <Button>
                  <Translate path="Components.UniversalSearch.sections.items.seeAll" />
                </Button>
              )}
            </Stack>
          )}
          {searchRTK.isFetching ||
            (searchRTK.isLoading && <CircularProgress color="inherit" />)}
        </Stack>
        {previousQueries.length > 0 && (
          <>
            <Divider />
            <Stack alignItems="center" mb={2}>
              <Box p={2} alignSelf="flex-start">
                <TranslateTypography path="Components.UniversalSearch.sections.recent.title" />
              </Box>
              <List
                sx={{ maxHeight: '15vh', overflowY: 'auto', width: '100%' }}
              >
                {previousQueries.map((el) => (
                  <ListItem
                    key={el.key}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => removePreviousQuery(el.key)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      selected={el.key === searchKey}
                      onClick={() => {
                        setTerm(el.term);
                        setCategories(el.categories);
                        setShouldSkip(false);
                        setSearchKey(el.key);
                      }}
                    >
                      <ListItemText
                        primary={el.term}
                        secondary={el.categories.join(', ')}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Button
                onClick={() => {
                  setPreviousQueries([]);
                  clearPersistedObject(persistenceKey);
                }}
              >
                <Translate path="Components.UniversalSearch.sections.recent.clearHistory" />
              </Button>
            </Stack>
          </>
        )}
      </Dialog>
    </>
  );
};

export default UniversalSearch;
