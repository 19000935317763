import { List, ListItem, ListSubheader } from '@mui/material';
import type { SearchResponse, SearchResponseItem } from 'Modules/Shared';
import type { TranslationPath } from 'Translation';
import { Translate, TranslateTypography, useTranslation } from 'Translation';

import SearchItem from '../SearchItem';

interface Props {
  items: SearchResponse;
}

function Section({
  title,
  items,
  url,
}: {
  title: TranslationPath;
  items: SearchResponseItem[];
  url: string;
}): JSX.Element {
  return (
    <li>
      <ul style={{ padding: 0 }}>
        <ListSubheader>{useTranslation(title)}</ListSubheader>
        {items.map((el) => (
          <ListItem key={`${el.type}-${el.id}`}>
            <SearchItem item={el} url={`${url}/${el.id}`} />
          </ListItem>
        ))}
      </ul>
    </li>
  );
}

const SearchList: React.FC<Props> = ({ items }) => (
  <List sx={{ bgcolor: 'background.paper', width: '100%' }}>
    {items.digimon && items.digimon.length > 0 && (
      <Section
        title="Components.UniversalSearch.sections.categories.chips.digimon"
        items={items.digimon}
        url="/field-guide/digimon"
      />
    )}
    {items.item && items.item.length > 0 && (
      <Section
        title="Components.UniversalSearch.sections.categories.chips.items"
        items={items.item}
        url="/field-guide/items"
      />
    )}
    {items.move && items.move.length > 0 && (
      <Section
        title="Components.UniversalSearch.sections.categories.chips.moves"
        items={items.move}
        url="/field-guide/moves"
      />
    )}
    {items.location && items.location.length > 0 && (
      <Section
        title="Components.UniversalSearch.sections.categories.chips.locations"
        items={items.location}
        url="/field-guide/locations"
      />
    )}
    {items.roster && items.roster.length > 0 && (
      <Section
        title="Components.UniversalSearch.sections.categories.chips.roster"
        items={items.roster}
        url="/digilab/roster"
      />
    )}
    {items.team && items.team.length > 0 && (
      <Section
        title="Components.UniversalSearch.sections.categories.chips.team"
        items={items.team}
        url="/digilab/teams"
      />
    )}
  </List>
);

export default SearchList;
