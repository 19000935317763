import { Attribute } from 'Modules/FieldGuide/Types';
import type { PaginationFilterOption } from 'Modules/Shared/Components';
import type { TranslationPath } from 'Translation';

import getAttributeLabel from './getAttributeLabel';

function getDigimonAttributesFilterOptions(): PaginationFilterOption[] {
  const attributes = Object.values(Attribute);

  const allItem: PaginationFilterOption = {
    id: 'attribute-all',
    label: 'Components.Select.all',
    value: 'all',
  };
  const attributeMenuItem: PaginationFilterOption[] = attributes.map(
    (attribute) => ({
      id: `attribute-${attribute}`,
      label: getAttributeLabel(attribute) as TranslationPath,
      value: attribute as string,
    }),
  );

  return [allItem, ...attributeMenuItem];
}

export default getDigimonAttributesFilterOptions;
