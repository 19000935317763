import { Avatar, Chip, TableCell, TableRow } from '@mui/material';
import {
  DigimonListingItem,
  DigimonListingItemSkeleton,
} from 'Modules/FieldGuide/Components';
import {
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getDigimonAttributesFilterOptions,
  getDigimonNumber,
  getDigimonStageLabel,
  getDigimonStagesFilterOptions,
  getDigimonTypeLabel,
  getDigimonTypesFilterOptions,
} from 'Modules/FieldGuide/Lib';
import { useGetDigimonQuery } from 'Modules/FieldGuide/Store';
import type {
  Attribute,
  Digimon,
  DigimonPaginatedListFilter,
  DigimonStage,
  DigimonType,
} from 'Modules/FieldGuide/Types';
import type { PaginationFilter, PaginationFilterOption } from 'Modules/Shared';
import {
  NewListingView,
  PaginationFilterType,
  TableLink,
  usePagination,
} from 'Modules/Shared';
import type { DigimonListingRTK, ListingEntities } from 'Store';
import type { TranslationPath } from 'Translation';
import { Translate, useTranslation } from 'Translation';
import React, { useCallback, useMemo } from 'react';

function DigimonListing(): JSX.Element {
  const {
    clearFilters,
    clearState,
    entities,
    isLoading,
    options,
    paginate,
    setOptions,
  } = usePagination<DigimonListingRTK, DigimonPaginatedListFilter>(
    useGetDigimonQuery,
    40,
  );

  const title = useTranslation('Pages.FieldGuide.DigimonListing.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    { label: useTranslation('Pages.FieldGuide.DigimonListing.breadcrumb') },
  ];

  const searchProps = useMemo(
    () => ({
      id: 'digimon-search',
      label:
        'Pages.FieldGuide.DigimonListing.paginationFilters.search.label' as TranslationPath,
      placeholder:
        'Pages.FieldGuide.DigimonListing.paginationFilters.search.placeholder' as TranslationPath,
    }),
    [],
  );

  const getLabelValue = (value?: string | null): string => {
    if (value === null) {
      return 'all';
    }

    return value ?? '';
  };

  const renderItem = useCallback((item: ListingEntities) => {
    const digimon = item as unknown as Digimon;
    return <DigimonListingItem key={digimon.id} digimon={digimon} />;
  }, []);

  const renderRow = useCallback((item: ListingEntities) => {
    const digimon = item as unknown as Digimon;
    return (
      <TableRow key={digimon.id}>
        <TableCell>{getDigimonNumber(digimon.number)}</TableCell>
        <TableCell>
          <Avatar src={digimon.image} />
        </TableCell>
        <TableCell>
          <TableLink to={`/field-guide/digimon/${digimon.id}`}>
            {digimon.name}
          </TableLink>
        </TableCell>
        <TableCell>{digimon.memoryUsage}</TableCell>
        <TableCell>
          <Translate path={getDigimonStageLabel(digimon.stage)} />
        </TableCell>
        <TableCell>
          <Translate path={getDigimonTypeLabel(digimon.type)} />
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              bgcolor: getAttributeColour(digimon.attribute),
              color: getAttributeFontColour(digimon.attribute),
            }}
            label={<Translate path={getAttributeLabel(digimon.attribute)} />}
          />
        </TableCell>
      </TableRow>
    );
  }, []);

  const filters: PaginationFilter[] = useMemo(() => {
    const setAttributeFilter = (
      e: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ attribute: null });
      } else {
        setOptions({ attribute: value as Attribute });
      }
    };

    const setStageFilter = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ stage: null });
      } else {
        setOptions({ stage: value as DigimonStage });
      }
    };

    const setTypeFilter = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ type: null });
      } else {
        setOptions({ type: value as DigimonType });
      }
    };

    const digimonAttributes: PaginationFilterOption[] =
      getDigimonAttributesFilterOptions();
    const digimonStages: PaginationFilterOption[] =
      getDigimonStagesFilterOptions();
    const digimonTypes: PaginationFilterOption[] =
      getDigimonTypesFilterOptions();

    return [
      {
        id: 'digimon-attribute',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.attribute.label' as TranslationPath,
        onChange: setAttributeFilter,
        options: digimonAttributes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.attribute),
      },
      {
        id: 'digimon-stage',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.stage.label' as TranslationPath,
        onChange: setStageFilter,
        options: digimonStages,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.stage),
      },
      {
        id: 'digimon-type',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.type.label' as TranslationPath,
        onChange: setTypeFilter,
        options: digimonTypes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.type),
      },
    ];
  }, [options.attribute, options.stage, options.type]);

  return (
    <NewListingView
      entity="digimon"
      entities={entities}
      paginate={paginate}
      breadcrumbs={breadcrumbs}
      title={title}
      listingSkeletonComponent={<DigimonListingItemSkeleton />}
      itemRender={renderItem}
      filters={filters}
      limit={40}
      searchProps={searchProps}
      clearFilters={clearFilters}
      rowRender={renderRow}
      isLoading={isLoading}
      headings={[
        {
          dataKey: 'number',
          label: 'Pages.FieldGuide.DigimonListing.tableListing.headings.number',
          width: 100,
        },
        {
          dataKey: 'avatar',
          label: 'Pages.FieldGuide.DigimonListing.tableListing.headings.icon',
          width: 100,
        },
        {
          dataKey: 'name',
          label: 'Pages.FieldGuide.DigimonListing.tableListing.headings.name',
        },
        {
          dataKey: 'memoryUsage',
          label:
            'Pages.FieldGuide.DigimonListing.tableListing.headings.memoryUsage',
        },
        {
          dataKey: 'stage',
          label: 'Pages.FieldGuide.DigimonListing.tableListing.headings.stage',
        },
        {
          dataKey: 'type',
          label: 'Pages.FieldGuide.DigimonListing.tableListing.headings.type',
        },
        {
          dataKey: 'attribute',
          label:
            'Pages.FieldGuide.DigimonListing.tableListing.headings.attribute',
        },
      ]}
    />
  );
}

export default DigimonListing;
