/** @jsxImportSource @emotion/react */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { MoveListingItem } from 'Modules/FieldGuide/Components';
import {
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getAvailableAtLabel,
  getDigimonNumber,
  getDigimonStageLabel,
  getDigimonStatLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide/Lib';
import { useGetDigimonByIDQuery } from 'Modules/FieldGuide/Store';
import { Attribute, DigimonType } from 'Modules/FieldGuide/Types';
import { useEntityPage } from 'Modules/Shared';
import { largeCornerRadius, theme } from 'Theme';
import { Translate, useTranslation } from 'Translation';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import DigivolutionConditions from './DigivolutionConditions';
import DigivolutionRow, { Direction } from './DigivolutionRow';
import styles from './styles';

const DigimonDetail: React.FC = () => {
  const params = useParams<{ digimon?: string | undefined }>();
  const digimonId = params.digimon ?? 0;
  const query = useGetDigimonByIDQuery(digimonId);
  const digimon = query.data;

  const [selectedTab, setSelectedTab] = useState<string>('1');
  const {
    chipBlock,
    container,
    definitionList,
    definitionListRow,
    definitionListDefinitionCell,
    definitionListCell,
    definitionListProgressBarCell,
    headerRow,
    imageContainer,
  } = styles(
    getAttributeColour(digimon ? digimon.attribute : Attribute.Neutral),
  );

  const bgColor = useMemo(
    () => getAttributeColour(digimon ? digimon.attribute : Attribute.Neutral),
    [digimon],
  );
  const fontColor = useMemo(
    () =>
      getAttributeFontColour(digimon ? digimon.attribute : Attribute.Neutral),
    [digimon],
  );
  const title = useTranslation('Pages.FieldGuide.title');

  const getTitle = (): string =>
    query.data ? `${title} - ${query.data.name}` : '';

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    {
      label: useTranslation('Pages.FieldGuide.DigimonListing.breadcrumb'),
      to: '/field-guide/digimon',
    },
  ];

  useEntityPage(query.data, getTitle, breadcrumbs, !!query.isLoading, {
    backgroundImage: 'none',
    bgcolor: bgColor,
    boxShadow: 'none',
    color: fontColor,
  });

  const handleTabClick = (event: any, newValue: string): void => {
    setSelectedTab(newValue);
  };

  const getProgressValue = (value: number, maxValue: number): number =>
    (value / maxValue) * 100;

  if (digimon && query.isSuccess) {
    const attributeLabel = getAttributeLabel(digimon.attribute);
    const digimonTypeLabel = getDigimonTypeLabel(digimon.type);

    return (
      <Box sx={container}>
        <Helmet>
          <title>
            {title} - {digimon.name}
          </title>
        </Helmet>
        <Stack sx={{ bgcolor: bgColor, p: 2, pb: 4, px: 4 }}>
          <Box sx={headerRow}>
            <Typography
              component="h1"
              variant="h2"
              sx={{ color: fontColor, mb: 1 }}
            >
              {digimon.name}
            </Typography>
            <Typography
              component="span"
              variant="h3"
              sx={{ color: fontColor, mb: 1 }}
            >
              {getDigimonNumber(digimon.number)}
            </Typography>
          </Box>
          <Box sx={headerRow}>
            <Box sx={chipBlock}>
              <Chip
                sx={{ color: fontColor, mr: 1 }}
                label={<Translate path={digimonTypeLabel} />}
              />
              <Chip
                sx={{ color: fontColor }}
                label={<Translate path={attributeLabel} />}
              />
            </Box>
            <Typography component="span" sx={{ color: fontColor }}>
              <Translate path={getDigimonStageLabel(digimon.stage)} />
            </Typography>
          </Box>
          <Box sx={imageContainer}>
            <img src={digimon.image} alt={digimon.name} />
          </Box>
        </Stack>
        <Paper
          sx={{
            borderRadius: `${largeCornerRadius} ${largeCornerRadius} 0 0`,
            minHeight: `calc(100vh - calc(384px - ${theme.spacing(2)}))`,
            mt: -2,
            p: 2,
          }}
          elevation={3}
        >
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
              <TabList onChange={handleTabClick} aria-label="tabs">
                <Tab
                  label={
                    <Translate path="Pages.FieldGuide.Digimon.tabs.about" />
                  }
                  value="1"
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  label={
                    <Translate path="Pages.FieldGuide.Digimon.tabs.baseStats" />
                  }
                  value="2"
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  label={
                    <Translate path="Pages.FieldGuide.Digimon.tabs.digivolution" />
                  }
                  value="3"
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  label={
                    <Translate path="Pages.FieldGuide.Digimon.tabs.moves" />
                  }
                  value="4"
                  sx={{ fontWeight: 'bold' }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Stack>
                <Typography component="p" sx={{ mb: 4 }}>
                  {digimon.description}
                </Typography>
                <Paper sx={{ mb: 4, p: 2 }}>
                  <Stack>
                    <Typography
                      component="p"
                      sx={{ fontWeight: 'bold', mb: 1 }}
                    >
                      {digimon.supportSkill.name}
                    </Typography>
                    <Typography component="p">
                      {digimon.supportSkill.description}
                    </Typography>
                  </Stack>
                </Paper>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="round-up-content"
                    id="round-up-header"
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      <Translate path="Pages.FieldGuide.Digimon.about.roundUp.title" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <dl css={definitionList}>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Digimon.about.roundUp.type" />
                        </dt>
                        <dd css={definitionListCell}>
                          <Translate path={digimonTypeLabel} />
                        </dd>
                      </div>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Digimon.about.roundUp.attribute" />
                        </dt>
                        <dd css={definitionListCell}>
                          <Translate path={attributeLabel} />
                        </dd>
                      </div>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Digimon.about.roundUp.equipmentSlots" />
                        </dt>
                        <dd css={definitionListCell}>
                          {digimon.equipmentSlot}
                        </dd>
                      </div>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Digimon.about.roundUp.memoryUsage" />
                        </dt>
                        <dd css={definitionListCell}>{digimon.memoryUsage}</dd>
                      </div>
                    </dl>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="spawn-locations-content"
                    id="spawn-locations-header"
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      <Translate path="Pages.FieldGuide.Digimon.about.location.title" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {digimon.spawnLocations.map((location) => (
                      <Paper
                        sx={{ mb: 2, p: 2 }}
                        elevation={3}
                        key={`location-${location.id}`}
                      >
                        <dl css={definitionList}>
                          <div css={definitionListRow}>
                            <dt css={definitionListDefinitionCell}>
                              <Translate path="Pages.FieldGuide.Digimon.about.location.location" />
                            </dt>
                            <dd css={definitionListCell}>{location.name}</dd>
                          </div>
                          <div css={definitionListRow}>
                            <dt css={definitionListDefinitionCell}>
                              <Translate path="Pages.FieldGuide.Digimon.about.location.availableAt" />
                            </dt>
                            <dd css={definitionListCell}>
                              <Translate
                                path={getAvailableAtLabel(location.availableAt)}
                              />
                            </dd>
                          </div>
                        </dl>
                      </Paper>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="drops-content"
                    id="drops-header"
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      <Translate path="Pages.FieldGuide.Digimon.about.drops.title" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <dl css={definitionList}>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Digimon.about.drops.exp" />
                        </dt>
                        <dd css={definitionListCell}>{digimon.dropExp}</dd>
                      </div>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Digimon.about.drops.money" />
                        </dt>
                        <dd css={definitionListCell}>¥{digimon.dropMoney}</dd>
                      </div>
                    </dl>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="item-drops-content"
                    id="item-drops-header"
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      <Translate path="Pages.FieldGuide.Digimon.about.itemDrops.title" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {digimon.drops.map((drop) => (
                      <Paper
                        sx={{ mb: 2, p: 2 }}
                        elevation={3}
                        key={`drop-${drop.id}`}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <dl css={definitionList}>
                            <div css={definitionListRow}>
                              <dt css={definitionListDefinitionCell}>
                                <Translate path="Pages.FieldGuide.Digimon.about.itemDrops.name" />
                              </dt>
                              <dd css={definitionListCell}>{drop.name}</dd>
                            </div>
                            <div css={definitionListRow}>
                              <dt css={definitionListDefinitionCell}>
                                <Translate path="Pages.FieldGuide.Digimon.about.itemDrops.category" />
                              </dt>
                              <dd css={definitionListCell}>{drop.category}</dd>
                            </div>
                          </dl>
                          <Avatar
                            sx={{ alignSelf: 'flex-end' }}
                            src={drop.icon}
                          />
                        </Box>
                      </Paper>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </TabPanel>
            <TabPanel value="2">
              <Stack>
                <Box sx={{ mb: 4 }}>
                  <dl css={definitionList}>
                    {digimon.stats.map((stat) => (
                      <div css={definitionListRow} key={`stat-${stat.id}`}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path={getDigimonStatLabel(stat.name)} />
                        </dt>
                        <dd
                          css={definitionListCell}
                          style={{ textAlign: 'right' }}
                        >
                          {stat.value}
                        </dd>
                        <dd
                          css={definitionListProgressBarCell}
                          style={{ width: '200px' }}
                        >
                          <LinearProgress
                            sx={{ height: '8px' }}
                            variant="determinate"
                            value={getProgressValue(stat.value, stat.maxValue)}
                          />
                        </dd>
                      </div>
                    ))}
                  </dl>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    component="h3"
                    variant="h6"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    <Translate path="Pages.FieldGuide.Digimon.baseStats.effectiveness.attributeEffectiveness" />
                  </Typography>
                  {digimon.attribute === Attribute.Neutral && (
                    <Typography>
                      <Translate
                        path="Pages.FieldGuide.Digimon.baseStats.effectiveness.noAttributeEffectiveness"
                        subTranslations={[
                          { key: 'value', path: attributeLabel },
                        ]}
                      />
                    </Typography>
                  )}
                  {digimon.attribute !== Attribute.Neutral && (
                    <>
                      <Typography>
                        <Translate
                          path="Pages.FieldGuide.Digimon.baseStats.effectiveness.attributeEffectivenessOn"
                          values={{ target: digimon.name }}
                        />
                      </Typography>
                      <dl css={definitionList}>
                        <div css={definitionListRow}>
                          <dt css={definitionListDefinitionCell}>
                            <Translate path="Pages.FieldGuide.Digimon.baseStats.effectiveness.strongAgainst" />
                          </dt>
                          <dd css={definitionListCell}>
                            <Translate
                              path={getAttributeLabel(
                                digimon.attributeEffectiveness.strength,
                              )}
                            />
                          </dd>
                        </div>
                        <div css={definitionListRow}>
                          <dt css={definitionListDefinitionCell}>
                            <Translate path="Pages.FieldGuide.Digimon.baseStats.effectiveness.weakAgainst" />
                          </dt>
                          <dd css={definitionListCell}>
                            <Translate
                              path={getAttributeLabel(
                                digimon.attributeEffectiveness.weakness,
                              )}
                            />
                          </dd>
                        </div>
                      </dl>
                    </>
                  )}
                </Box>
                <Box>
                  <Typography
                    component="h3"
                    variant="h6"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    <Translate path="Pages.FieldGuide.Digimon.baseStats.effectiveness.typeEffectiveness" />
                  </Typography>
                  {digimon.type === DigimonType.Free && (
                    <Typography>
                      <Translate
                        path="Pages.FieldGuide.Digimon.baseStats.effectiveness.noTypeEffectiveness"
                        subTranslations={[
                          { key: 'value', path: digimonTypeLabel },
                        ]}
                      />
                    </Typography>
                  )}
                  {digimon.type !== DigimonType.Free && (
                    <>
                      <Typography>
                        <Translate
                          path="Pages.FieldGuide.Digimon.baseStats.effectiveness.typeEffectivenessOn"
                          values={{ target: digimon.name }}
                        />
                      </Typography>
                      <dl css={definitionList}>
                        <div css={definitionListRow}>
                          <dt css={definitionListDefinitionCell}>
                            <Translate path="Pages.FieldGuide.Digimon.baseStats.effectiveness.strongAgainst" />
                          </dt>
                          <dd css={definitionListCell}>
                            <Translate
                              path={getDigimonTypeLabel(
                                digimon.typeEffectiveness.strength,
                              )}
                            />
                          </dd>
                        </div>
                        <div css={definitionListRow}>
                          <dt css={definitionListDefinitionCell}>
                            <Translate path="Pages.FieldGuide.Digimon.baseStats.effectiveness.weakAgainst" />
                          </dt>
                          <dd css={definitionListCell}>
                            <Translate
                              path={getDigimonTypeLabel(
                                digimon.typeEffectiveness.weakness,
                              )}
                            />
                          </dd>
                        </div>
                      </dl>
                    </>
                  )}
                </Box>
              </Stack>
            </TabPanel>
            <TabPanel value="3">
              <Stack>
                {digimon.digivolutionConditions && (
                  <>
                    <DigivolutionConditions
                      conditions={digimon.digivolutionConditions}
                    />
                    <Box sx={{ mb: 2 }} />
                  </>
                )}
                {digimon.digivolutionHistory &&
                  digimon.digivolutionHistory.length > 0 && (
                    <Stack sx={{ mb: 4 }}>
                      <Typography component="h3" variant="h6" sx={{ mb: 1 }}>
                        <Translate path="Pages.FieldGuide.Digimon.digivolution.digivolvesFrom" />
                      </Typography>
                      {digimon.digivolutionHistory.map((history) => (
                        <DigivolutionRow
                          digimon={digimon}
                          digivolution={history}
                          direction={Direction.Left}
                          key={`digivultion-history-${history.id}`}
                        />
                      ))}
                    </Stack>
                  )}
                {digimon.digivolutionPotential &&
                  digimon.digivolutionPotential.length > 0 && (
                    <Stack>
                      <Typography component="h3" variant="h6" sx={{ mb: 1 }}>
                        <Translate path="Pages.FieldGuide.Digimon.digivolution.digivolvesFrom" />
                      </Typography>
                      {digimon.digivolutionPotential.map((potential) => (
                        <DigivolutionRow
                          digimon={digimon}
                          digivolution={potential}
                          direction={Direction.Right}
                          key={`digivultion-potential-${potential.id}`}
                        />
                      ))}
                    </Stack>
                  )}
              </Stack>
            </TabPanel>
            <TabPanel value="4">
              <Grid container spacing={2}>
                {digimon.moves.map((move) => (
                  <Grid
                    xs={12}
                    sm={6}
                    lg={3}
                    xl={2}
                    item
                    key={`move-${move.id}`}
                  >
                    <MoveListingItem move={move} />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </TabContext>
        </Paper>
      </Box>
    );
  }

  return <></>;
};

export default DigimonDetail;
