import { PersistMethod } from '../Types';
import { removefromPersistedObjectsMap } from './handlePersistedItems';

function clearPersistedObject(
  key: string,
  method: PersistMethod = PersistMethod.LocalStorage,
) {
  try {
    if (method === PersistMethod.LocalStorage) {
      window.localStorage.removeItem(key);
    } else if (method === PersistMethod.SessionStorage) {
      window.sessionStorage.removeItem(key);
    }

    removefromPersistedObjectsMap(key);
  } catch (error) {
    console.log(error);
  }
}

export default clearPersistedObject;
