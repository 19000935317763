import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
} from '@mui/material';
import type { TranslationPath } from 'Translation';
import { Translate } from 'Translation';
import React, { useMemo } from 'react';

export interface SpeedDialItem {
  icon: any;
  tooltipTitle: TranslationPath;
  onClick: () => void;
}

interface Props {
  items: SpeedDialItem[];
  speedDialRight: number;
}

function renderAction(action: SpeedDialItem) {
  return (
    <SpeedDialAction
      key={action.tooltipTitle}
      icon={action.icon}
      tooltipTitle={<Translate path={action.tooltipTitle} />}
      onClick={action.onClick}
    />
  );
}

const ListingSpeedDial: React.FC<Props> = ({ items, speedDialRight = 2 }) => {
  const theme = useTheme();
  const actions = useMemo(() => items.map(renderAction), [items]);

  return (
    <SpeedDial
      ariaLabel="filters"
      sx={{
        bottom: theme.spacing(2),
        position: 'fixed',
        right: theme.spacing(speedDialRight),
        transition: 'right 0.25s',
      }}
      icon={<SpeedDialIcon />}
    >
      {actions}
    </SpeedDial>
  );
};

export default ListingSpeedDial;
