import type { TranslationPath } from 'Translation/Types';

import type { RosterPersonality } from '../Types';

function getTeamMemberPersonalityLabel(
  personality?: RosterPersonality | null,
): TranslationPath {
  switch (personality) {
    case 'brainy':
      return 'Pages.Digilab.Roster.stats.personality.brainy.label';

    case 'builder':
      return 'Pages.Digilab.Roster.stats.personality.builder.label';

    case 'defender':
      return 'Pages.Digilab.Roster.stats.personality.defender.label';

    case 'durable':
      return 'Pages.Digilab.Roster.stats.personality.durable.label';

    case 'fighter':
      return 'Pages.Digilab.Roster.stats.personality.fighter.label';

    case 'lively':
      return 'Pages.Digilab.Roster.stats.personality.lively.label';

    case 'nimble':
      return 'Pages.Digilab.Roster.stats.personality.nimble.label';

    case 'searcher':
      return 'Pages.Digilab.Roster.stats.personality.searcher.label';

    default:
      return 'Pages.Digilab.Roster.stats.personality.brainy.label';
  }
}

export default getTeamMemberPersonalityLabel;
