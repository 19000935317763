import i18n from 'i18next';
import type { ReactNode } from 'react';
import React from 'react';
import { initReactI18next } from 'react-i18next';

import { en } from '../../Lang';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    resources: {
      en: {
        translation: en,
      },
    },
  });

function TranslationWrapper({
  children,
}: {
  children?: ReactNode;
}): JSX.Element {
  if (!children) {
    return <></>;
  }

  return <>{children}</>;
}

export default TranslationWrapper;
