/** @jsxImportSource @emotion/react */
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import type { Roster } from 'Modules/Digilab';
import { useGetRosterByIDQuery } from 'Modules/Digilab';
import type { Digimon } from 'Modules/FieldGuide';
import {
  Attribute,
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getDigimonNumber,
  getDigimonStageLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide';
import {
  setAppBarStyling,
  setPage,
  useEntityPage,
  useModalProps,
} from 'Modules/Shared';
import { largeCornerRadius, theme } from 'Theme';
import { Translate, TranslateTypography, useTranslation } from 'Translation';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Body from './Body';
import { ChangeMetaInformationModal } from './modals';
import styles from './styles';

export interface TeamMemberStat {
  id: number;
  name: string;
  value: number;
  increasedBy: number;
  maxValue: number;
}

function RosterDetail({ roster }: { roster: Roster }): JSX.Element {
  const metaModalProps = useModalProps();
  const digimon: Digimon = roster.digimon as Digimon;

  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const { chipBlock, container, headerRow, imageContainer } = styles(
    getAttributeColour(digimon ? digimon.attribute : Attribute.Neutral),
  );

  const bgColor = useMemo(
    () => getAttributeColour(digimon ? digimon.attribute : Attribute.Neutral),
    [digimon],
  );
  const fontColor = useMemo(
    () =>
      getAttributeFontColour(digimon ? digimon.attribute : Attribute.Neutral),
    [digimon],
  );
  const title = useTranslation('Pages.FieldGuide.title');

  const getTitle = () => `${title} - ${roster.nickname}`;

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.Digilab.breadcrumb'), to: '/digilab' },
    {
      label: useTranslation('Pages.Digilab.Roster.breadcrumb'),
      to: '/digilab/roster',
    },
  ];

  useEntityPage({ name: roster.nickname }, getTitle, breadcrumbs, false, {
    backgroundImage: 'none',
    bgcolor: bgColor,
    boxShadow: 'none',
    color: fontColor,
  });

  if (digimon) {
    const attributeLabel = getAttributeLabel(digimon.attribute);
    const digimonTypeLabel = getDigimonTypeLabel(digimon.type);

    return (
      <Box sx={container}>
        <Helmet>
          <title>
            {title} - {roster.nickname}
          </title>
        </Helmet>
        <Stack sx={{ bgcolor: bgColor, p: 2, pb: 4, px: 4 }}>
          <Box sx={headerRow}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography
                component="h1"
                variant="h2"
                sx={{ color: fontColor, mb: 1 }}
              >
                {roster.nickname}
              </Typography>
              <IconButton
                sx={{ color: fontColor, height: 10, width: 10 }}
                aria-label="edit"
                onClick={metaModalProps.onOpen}
              >
                <EditIcon />
              </IconButton>
            </Stack>

            <Typography
              component="span"
              variant="h3"
              sx={{ color: fontColor, mb: 1 }}
            >
              {getDigimonNumber(digimon.number)}
            </Typography>
          </Box>
          <Box sx={headerRow}>
            <Box sx={chipBlock}>
              <Chip
                sx={{ color: fontColor, mr: 1 }}
                label={<Translate path={digimonTypeLabel} />}
              />
              <Chip
                sx={{ color: fontColor }}
                label={<Translate path={attributeLabel} />}
              />
            </Box>
            <Stack flexDirection="row">
              <TranslateTypography
                sx={{ color: fontColor, mr: 1 }}
                path="Pages.Digilab.Roster.meta.level"
                values={{ level: roster.level }}
              />
              <TranslateTypography
                component="span"
                sx={{ color: fontColor }}
                path={getDigimonStageLabel(digimon.stage)}
              />
            </Stack>
          </Box>
          <Box sx={imageContainer}>
            <img src={digimon.image} alt={digimon.name} />
          </Box>
        </Stack>
        <Paper
          sx={{
            borderRadius: `${largeCornerRadius} ${largeCornerRadius} 0 0`,
            minHeight: `calc(100vh - calc(384px - ${theme.spacing(2)}))`,
            mt: -2,
            p: 2,
          }}
          elevation={3}
        >
          <Body digimon={digimon} roster={roster} isEditMode={isEditMode} />
        </Paper>
        <ChangeMetaInformationModal
          isOpen={metaModalProps.isOpen}
          onClose={metaModalProps.onClose}
          roster={roster}
        />
      </Box>
    );
  }

  return <></>;
}

function Wrapper(): JSX.Element {
  const dispatch = useDispatch();
  const params = useParams<{ roster?: string | undefined }>();
  const digimonId = params.roster ? Number(params.roster) : 0;
  const query = useGetRosterByIDQuery(digimonId);
  const roster = query.data;
  const title = useTranslation('Pages.FieldGuide.title');

  const getTitle = () =>
    query.data ? `${title} - ${query.data.nickname}` : '';

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.Digilab.breadcrumb'), to: '/digilab' },
    {
      label: useTranslation('Pages.Digilab.Roster.breadcrumb'),
      to: '/digilab/roster',
    },
  ];

  if (roster !== undefined) {
    return <RosterDetail roster={roster} />;
  }

  dispatch(
    setPage({
      breadcrumbs: [...breadcrumbs, { label: getTitle() }],
      title,
    }),
  );

  dispatch(
    setAppBarStyling({
      loading: true,
      styles: {
        backgroundImage: 'none',
        boxShadow: 'none',
      },
    }),
  );

  return <></>;
}

export default Wrapper;
