import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import {
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide/Lib';
import type { Digimon } from 'Modules/FieldGuide/Types';
import { Attribute } from 'Modules/FieldGuide/Types';
import type { TranslationPath } from 'Translation';
import { Translate } from 'Translation';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

interface Props {
  digimon: Digimon;
  disabled?: boolean;
  showButtons?: boolean;
  primaryButton?: {
    label: TranslationPath;
    onClick: () => void;
  };
}

const DigimonLisitingItem: React.FC<Props> = ({
  digimon,
  primaryButton,
  disabled = false,
  showButtons = true,
  ...rest
}) => {
  const {
    avatar,
    avatarContainer,
    button,
    card,
    contents,
    chipContainer,
    footer,
    title,
  } = styles;
  const navigate = useNavigate();

  const bgColor = useMemo(
    () => getAttributeColour(digimon ? digimon.attribute : Attribute.Neutral),
    [digimon],
  );
  const fontColor = useMemo(
    () =>
      getAttributeFontColour(digimon ? digimon.attribute : Attribute.Neutral),
    [digimon],
  );

  const navigateTo = () => {
    navigate(`/field-guide/digimon/${digimon.id}`);
  };

  return (
    <Card
      sx={{ ...card, bgcolor: bgColor, opacity: disabled ? 0.5 : 1 }}
      {...rest}
    >
      <CardContent>
        <Typography sx={{ ...title, color: fontColor }} gutterBottom>
          {digimon.name}
        </Typography>
        <Box sx={contents}>
          <Box sx={chipContainer}>
            <Chip
              sx={{ color: fontColor, mb: 1 }}
              label={<Translate path={getDigimonTypeLabel(digimon.type)} />}
            />
            <Chip
              sx={{ color: fontColor }}
              label={<Translate path={getAttributeLabel(digimon.attribute)} />}
            />
          </Box>
          <Box sx={avatarContainer}>
            <Avatar sx={avatar} alt={digimon.name} src={digimon.image} />
          </Box>
        </Box>
      </CardContent>
      {showButtons && (
        <CardActions sx={footer}>
          {!primaryButton && (
            <Button
              disabled={disabled}
              onClick={navigateTo}
              sx={{ ...button, color: fontColor }}
              size="small"
            >
              See more
            </Button>
          )}
          {primaryButton && (
            <Button
              disabled={disabled}
              onClick={primaryButton.onClick}
              sx={{ ...button, color: fontColor }}
              size="small"
            >
              <Translate path={primaryButton.label} />
            </Button>
          )}
          <Button sx={{ ...button, color: fontColor }} size="small">
            Like
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default DigimonLisitingItem;
