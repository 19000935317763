import { red as colourRed } from '../constants';

const red50 = '#feedeb';
const red100 = '#fed1cc';
const red200 = '#fdb2aa';
const red300 = '#fc9388';
const red400 = '#fb7c6f';
const red600 = '#f95d4e';
const red700 = '#f95344';
const red800 = '#f8493b';
const red900 = '#f6372a';
const redA100 = '#ffffff';
const redA200 = '#ffffff';
const redA400 = '#ffd1cf';
const redA700 = '#ffb9b5';

const red = {
  100: red100,
  200: red200,
  300: red300,
  400: red400,
  50: red50,
  500: colourRed,
  600: red600,
  700: red700,
  800: red800,
  900: red900,
  A100: redA100,
  A200: redA200,
  A400: redA400,
  A700: redA700,
};

export default red;
