import { authSlice } from 'Modules/Auth';
import { digilabSlices } from 'Modules/Digilab';
import { fieldGuideSlices } from 'Modules/FieldGuide';
import { profileSlices } from 'Modules/Profile';
import { sharedSlices } from 'Modules/Shared';
import { combineReducers } from 'redux';

const reducers = combineReducers({
  authSlice,
  ...fieldGuideSlices,
  ...digilabSlices,
  ...profileSlices,
  ...sharedSlices,
});

export default reducers;
