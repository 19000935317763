import type { TranslationPath } from 'Translation/Types';

import { ItemCategory } from '../Types';

function getItemCategoryLabel(
  itemCategory: ItemCategory | null,
): TranslationPath {
  switch (itemCategory) {
    case ItemCategory.Consumable:
      return 'FieldGuide.Items.consumable';

    case ItemCategory.Equipment:
      return 'FieldGuide.Items.equipment';

    case ItemCategory.KeyItem:
      return 'FieldGuide.Items.keyItem';

    case ItemCategory.Medal:
      return 'FieldGuide.Items.medal';

    default:
      return 'FieldGuide.Items.consumable';
  }
}

export default getItemCategoryLabel;
