import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import type { Digimon } from 'Modules/FieldGuide/Types';
import { getTruncatedText } from 'Modules/Shared';
import { Translate } from 'Translation';
import React from 'react';

import DigivolutionConditions from './DigivolutionConditions';

export enum Direction {
  Left = 'left',
  Right = 'right',
}

interface Props {
  direction: Direction;
  digimon: Digimon;
  digivolution: Digimon;
}

const styles = {
  digivolutionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const DigivolutionRow: React.FC<Props> = ({
  direction,
  digimon,
  digivolution,
}) => {
  const [open, setOpen] = React.useState(false);
  const { digivolutionContainer, wrapper } = styles;

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      {direction === Direction.Left ? (
        <Box sx={wrapper} onClick={openDialog}>
          <Box sx={digivolutionContainer}>
            <img alt={digivolution.name} src={digivolution.image} />
            <Typography>{digivolution.name}</Typography>
          </Box>
          <ArrowBack />
          <Box sx={digivolutionContainer}>
            <img alt={digimon.name} src={digimon.image} />
            <Typography>{digimon.name}</Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={wrapper} onClick={openDialog}>
          <Box sx={digivolutionContainer}>
            <img alt={digimon.name} src={digimon.image} />
            <Typography>{digimon.name}</Typography>
          </Box>
          <ArrowForward />
          <Box sx={digivolutionContainer}>
            <img alt={digivolution.name} src={digivolution.image} />
            <Typography>{digivolution.name}</Typography>
          </Box>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ fontWeight: 'bold' }} id="alert-dialog-title">
          {digivolution.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }} id="alert-dialog-description">
            {getTruncatedText(digivolution.description, 100)}
          </DialogContentText>
          <DigivolutionConditions
            conditions={digivolution.digivolutionConditions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            <Translate path="Pages.FieldGuide.Digimon.digivolution.dialog.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DigivolutionRow;
