import type { Theme } from '@mui/material';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import type { ProcessedTeam } from 'Modules/Digilab';
import { getAttributeColour, getAttributeFontColour } from 'Modules/FieldGuide';
import { white } from 'Theme';
import { Translate } from 'Translation';

import generateDefensiveTable from './generateDefensiveTable';

interface Props {
  team: ProcessedTeam;
}

function getCellStyle(
  isMostOfTeamStrong: boolean,
  isMostOfTeamWeak: boolean,
  totalStrength: number,
  totalWeaknesses: number,
  theme: Theme,
) {
  if (isMostOfTeamStrong) {
    return {
      bgcolor: theme.palette.success.dark,
      color: white,
    };
  }

  if (isMostOfTeamWeak) {
    return {
      bgcolor: theme.palette.error.dark,
      color: white,
    };
  }

  if (totalStrength >= 2) {
    return {
      bgcolor: theme.palette.success.main,
      color: white,
    };
  }

  if (totalStrength >= 1) {
    return {
      bgcolor: theme.palette.success.light,
      color: white,
    };
  }

  if (totalWeaknesses >= 2) {
    return {
      bgcolor: theme.palette.error.main,
      color: white,
    };
  }

  if (totalWeaknesses >= 1) {
    return {
      bgcolor: theme.palette.error.light,
      color: white,
    };
  }
}

const DefensiveCoverage: React.FC<Props> = ({ team }) => {
  const theme = useTheme();
  const { data, headings, teamMakeup } = generateDefensiveTable(team);

  console.log(teamMakeup);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate path="Pages.Digilab.Team.analysis.defenceTable.headings.attribute" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.analysis.defenceTable.headings.type" />
            </TableCell>
            {headings.map((heading) => (
              <TableCell key={`${heading.name}-defense-analysis-header`}>
                <Stack spacing={0.5}>
                  <img src={heading.icon} width={40} height={40} />
                  <Typography mb={0}>{heading.name}</Typography>
                  {heading.altname && (
                    <Typography
                      mb={0}
                      sx={{ fontSize: '0.75rem' }}
                    >{`(${heading.altname})`}</Typography>
                  )}
                </Stack>
              </TableCell>
            ))}
            <TableCell>
              <Translate path="Pages.Digilab.Team.analysis.defenceTable.headings.totalStrengths" />
            </TableCell>
            <TableCell>
              <Translate path="Pages.Digilab.Team.analysis.defenceTable.headings.totalWeaknesses" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((element) =>
            element.types.map((type, index) => (
              <TableRow key={`${element.element}-${type.type}`}>
                {index === 0 && (
                  <TableCell
                    rowSpan={element.types.length}
                    sx={{
                      bgcolor: getAttributeColour(element.key),
                      color: getAttributeFontColour(element.key),
                    }}
                  >
                    <Translate path={element.element} />
                  </TableCell>
                )}
                <TableCell>
                  <Translate path={type.type} />
                </TableCell>
                {type.digimon.map((val, valIndex) => (
                  <TableCell
                    key={`${element.element}-${type.type}-${headings[valIndex].name}`}
                  >
                    {val && `${val}x`}
                  </TableCell>
                ))}
                <TableCell
                  sx={getCellStyle(
                    element.isMostOfTeamStrong,
                    element.isMostOfTeamWeak,
                    type.totalStrengths,
                    0,
                    theme,
                  )}
                >
                  {type.totalStrengths}
                </TableCell>
                <TableCell
                  sx={getCellStyle(
                    element.isMostOfTeamStrong,
                    element.isMostOfTeamWeak,
                    0,
                    type.totalWeaknesses,
                    theme,
                  )}
                >
                  {type.totalWeaknesses}
                </TableCell>
              </TableRow>
            )),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DefensiveCoverage;
