import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import {
  getAttributeColour,
  getAttributeFontColour,
} from 'Modules/FieldGuide/Lib';
import { Attribute } from 'Modules/FieldGuide/Types';
import { white } from 'Theme';
import { Translate } from 'Translation';

function AttributeTable(): JSX.Element {
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Dark),
                color: getAttributeFontColour(Attribute.Dark),
              }}
            >
              <Translate path="Common.attributes.dark" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Light),
                color: getAttributeFontColour(Attribute.Light),
              }}
            >
              <Translate path="Common.attributes.light" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Earth),
                color: getAttributeFontColour(Attribute.Earth),
              }}
            >
              <Translate path="Common.attributes.earth" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Electric),
                color: getAttributeFontColour(Attribute.Electric),
              }}
            >
              <Translate path="Common.attributes.electric" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Fire),
                color: getAttributeFontColour(Attribute.Fire),
              }}
            >
              <Translate path="Common.attributes.fire" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Plant),
                color: getAttributeFontColour(Attribute.Plant),
              }}
            >
              <Translate path="Common.attributes.plant" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Water),
                color: getAttributeFontColour(Attribute.Water),
              }}
            >
              <Translate path="Common.attributes.water" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Wind),
                color: getAttributeFontColour(Attribute.Wind),
              }}
            >
              <Translate path="Common.attributes.wind" />
            </TableCell>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Neutral),
                color: getAttributeFontColour(Attribute.Neutral),
              }}
            >
              <Translate path="Common.attributes.neutral" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Dark),
                color: getAttributeFontColour(Attribute.Dark),
              }}
            >
              <Translate path="Common.attributes.dark" />
            </TableCell>
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Light),
                color: getAttributeFontColour(Attribute.Light),
              }}
            >
              <Translate path="Common.attributes.light" />
            </TableCell>
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Earth),
                color: getAttributeFontColour(Attribute.Earth),
              }}
            >
              <Translate path="Common.attributes.earth" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Electric),
                color: getAttributeFontColour(Attribute.Electric),
              }}
            >
              <Translate path="Common.attributes.electric" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Fire),
                color: getAttributeFontColour(Attribute.Fire),
              }}
            >
              <Translate path="Common.attributes.fire" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Plant),
                color: getAttributeFontColour(Attribute.Plant),
              }}
            >
              <Translate path="Common.attributes.plant" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Water),
                color: getAttributeFontColour(Attribute.Water),
              }}
            >
              <Translate path="Common.attributes.water" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Wind),
                color: getAttributeFontColour(Attribute.Wind),
              }}
            >
              <Translate path="Common.attributes.wind" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell>
              <Chip
                sx={{
                  bgcolor: theme.palette.success.dark,
                  color: white,
                  width: '3.5rem',
                }}
                label="1.5x"
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: getAttributeColour(Attribute.Neutral),
                color: getAttributeFontColour(Attribute.Neutral),
              }}
            >
              <Translate path="Common.attributes.neutral" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AttributeTable;
