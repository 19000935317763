import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import type { SearchResponseItem } from 'Modules/Shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function SearchItem({
  item,
  url,
}: {
  item: SearchResponseItem;
  url: string;
}): JSX.Element {
  const navigate = useNavigate();

  return (
    <ListItemButton onClick={(): void => navigate(url)}>
      {item.image && (
        <ListItemAvatar>
          <Avatar alt={item.name} src={item.image} />
        </ListItemAvatar>
      )}
      <ListItemText id={`${item.id}-${item.name}`} primary={item.name} />
    </ListItemButton>
  );
}

export default SearchItem;
