import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import {
  AttributeTable,
  CombinedAttributeTypeTable,
  TypeTable,
} from 'Modules/FieldGuide/Components';
import { setPage } from 'Modules/Shared';
import { useTranslation } from 'Translation';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Charts: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const title = useTranslation('Pages.FieldGuide.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    { label: useTranslation('Pages.FieldGuide.Charts.breadcrumb') },
  ];

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  return (
    <Stack p={2} spacing={2}>
      <AttributeTable />
      <TypeTable />
      <CombinedAttributeTypeTable />
    </Stack>
  );
};

export default Charts;
