import type { Digimon } from 'Modules/FieldGuide';

import type { ProcessedTeam } from './team';

export enum BattleType {
  AI,
  Friendly,
}

export enum BattleParticipantType {
  Digimon,
  Roster,
}

export interface BattleParticipant {
  id: number;
  rosterId?: number;
  name: string;
  digimon: Digimon;
  type: BattleParticipantType;
}

export interface Battle {
  id: number;
  name: string;
  team: ProcessedTeam;
  participants: BattleParticipant[];
  enemies: BattleParticipant[];
  type: BattleType;
  enemyTrainer?: any;
  enemyTeam?: ProcessedTeam;
}
