/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { getAvailableAtLabel } from 'Modules/FieldGuide/Lib';
import type { Location } from 'Modules/FieldGuide/Types';
import { Translate } from 'Translation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

const LocationLisitingItem: React.FC<{ location: Location }> = ({
  location,
}) => {
  const {
    button,
    card,
    contents,
    definitionList,
    definitionListRow,
    definitionListCell,
    footer,
    title,
  } = styles;
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(`/field-guide/locations/${location.id}`);
  };

  return (
    <Card sx={{ ...card }}>
      <CardContent>
        <Typography sx={title} gutterBottom>
          {location.name}
        </Typography>
        <Box sx={contents}>
          <Box css={definitionList}>
            {location.availableAt && (
              <div css={definitionListRow}>
                <dt css={definitionListCell}>
                  <Translate path="Pages.FieldGuide.LocationsListing.listingItem.availableAt" />
                  :
                </dt>
                <dd css={definitionListCell}>
                  <Translate path={getAvailableAtLabel(location.availableAt)} />
                </dd>
              </div>
            )}
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={footer}>
        <Button onClick={navigateTo} sx={button} size="small">
          See more
        </Button>
        <Button sx={button} size="small">
          Like
        </Button>
      </CardActions>
    </Card>
  );
};

export default React.memo(LocationLisitingItem);
