import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import type { ComboBoxOption } from 'Modules/Shared';
import type { TranslationPath } from 'Translation';
import { useTranslation } from 'Translation';
import { intersection } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import Header from '../Header';

interface Props {
  checked: readonly number[];
  title: string;
  searchLabel: TranslationPath;
  subtitle: string;
  items: ComboBoxOption[];
  handleSearch?(e: any, newValue: ComboBoxOption | null): void;
  handleChecked: (value: number) => void;
}

const Column: React.FC<Props> = ({
  checked,
  title,
  searchLabel,
  subtitle,
  items,
  handleSearch,
  handleChecked,
}) => (
  <Card>
    <CardHeader
      sx={{ px: 2, py: 1 }}
      title={title}
      subheader={subtitle}
      avatar={
        <Header
          getNumberChecked={() => items.length}
          items={items}
          searchLabel={searchLabel}
          handleSearch={handleSearch}
          hasSearch
        />
      }
    />
    <Divider />
    <List
      sx={{
        bgcolor: 'background.paper',
        height: 400,
        overflow: 'auto',
        width: 400,
      }}
      dense
      component="div"
      role="list"
    >
      {items.map((item: ComboBoxOption, index: number) => {
        const labelId = `transfer-list-all-item-${item.value}-label`;

        return (
          <ListItem
            key={item.value}
            disabled={item.disabled}
            role="listitem"
            button
            onClick={(e) => handleChecked(index)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checked.includes(index)}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={item.label}
              secondary={item.secondaryLabel}
            />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  </Card>
);

export default Column;
