import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import type { ProcessedTeamMember, Roster } from 'Modules/Digilab';
import { TeamSlotState, getTeamMemberPersonalityLabel } from 'Modules/Digilab';
import type { DigimonType } from 'Modules/FieldGuide';
import {
  Attribute,
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide';
import type { MenuItemProps as MenuItem } from 'Modules/Shared';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListRow,
  DescriptionTerm,
  SlotMenu,
} from 'Modules/Shared';
import { Translate } from 'Translation';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

function TeamMemberSlot({
  addMember,
  slot,
  removeFromTeam,
}: {
  slot: ProcessedTeamMember;
  navigate: (id: number) => void;
  addMember: (id: number, memberID: number) => void;
  removeFromTeam: (id?: number) => void;
}): JSX.Element {
  const roster = slot.member as Roster;
  const {
    avatar,
    avatarContainer,
    button,
    card,
    contents,
    footer,
    metaLabel,
    subtitle,
    title,
  } = styles;

  const attribute = useMemo(
    () =>
      roster && !!roster.digimon.attribute
        ? roster.digimon.attribute
        : Attribute.Neutral,
    [roster],
  );
  const bgColor = useMemo(() => getAttributeColour(attribute), [roster]);
  const fontColor = useMemo(() => getAttributeFontColour(attribute), [roster]);
  const showName = useMemo(
    () =>
      roster && roster.digimon ? roster.nickname !== roster.digimon.name : true,
    [roster],
  );

  const menu: MenuItem[] = [
    {
      action: () => null,
      label: 'Pages.Digilab.Team.slot.menu.edit',
    },
    {
      action: () => removeFromTeam(slot.id),
      label: 'Pages.Digilab.Team.slot.menu.removeFromTeam',
    },
    {
      actions: [
        {
          action: () => null,
          isDisabled: slot.number === 1,
          label: 'Pages.Digilab.Team.slot.menu.move.moveUp',
        },
        {
          action: () => null,
          isDisabled: slot.number === 11,
          label: 'Pages.Digilab.Team.slot.menu.move.moveDown',
        },
        {
          action: () => null,
          isDisabled:
            slot.number === 1 || slot.number === 2 || slot.number === 3,
          label: 'Pages.Digilab.Team.slot.menu.move.moveToActive',
        },
        {
          action: () => null,
          isDisabled:
            slot.number !== 1 && slot.number !== 2 && slot.number !== 3,
          label: 'Pages.Digilab.Team.slot.menu.move.moveToReserve',
        },
      ],
      label: 'Pages.Digilab.Team.slot.menu.move.title',
    },
  ];

  return (
    <Card sx={{ ...card, bgcolor: bgColor }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={1}
        >
          <Stack direction="row" alignItems="flex-end">
            <Typography sx={{ ...title, color: fontColor }}>
              {roster ? roster.nickname : 'Empty'}
            </Typography>
            {showName && (
              <Typography sx={{ ...subtitle, color: fontColor, ml: 1 }}>
                {roster && roster.digimon.name}
              </Typography>
            )}
          </Stack>
          <Stack>
            {slot.state === TeamSlotState.Used ? (
              <SlotMenu
                ariaLabel="Pages.Digilab.Team.slot.menu.accessibility"
                menuItems={menu}
                sx={{ padding: 0 }}
              >
                <EditIcon />
              </SlotMenu>
            ) : (
              <IconButton
                id="add-button"
                color="inherit"
                aria-label="edit"
                sx={{ padding: 0 }}
                onClick={() => addMember(slot.number, Number(slot.id ?? 0))}
              >
                <AddIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
        {slot.state === TeamSlotState.Used && !!roster && (
          <Stack direction="row" spacing={1}>
            <DescriptionList>
              <DescriptionListRow>
                <DescriptionTerm>
                  <Typography sx={{ ...metaLabel, color: fontColor }}>
                    Lv.
                  </Typography>
                </DescriptionTerm>
                <DescriptionDetails>
                  <Typography
                    sx={{ ...metaLabel, color: fontColor }}
                    gutterBottom
                  >
                    {roster.level}
                  </Typography>
                </DescriptionDetails>
              </DescriptionListRow>
              <DescriptionListRow>
                <DescriptionTerm>
                  <Typography sx={{ ...metaLabel, color: fontColor }}>
                    Mem.
                  </Typography>
                </DescriptionTerm>
                <DescriptionDetails>
                  <Typography sx={{ ...metaLabel, color: fontColor }}>
                    {roster.digimon.memoryUsage}
                  </Typography>
                </DescriptionDetails>
              </DescriptionListRow>
            </DescriptionList>
            <Stack sx={contents}>
              <Stack direction="row" spacing={1}>
                <Stack>
                  <Chip
                    sx={{ color: fontColor, mb: 1 }}
                    label={
                      <Translate
                        path={getDigimonTypeLabel(
                          roster.digimon.type as DigimonType,
                        )}
                      />
                    }
                  />
                  <Chip
                    sx={{ color: fontColor }}
                    label={<Translate path={getAttributeLabel(attribute)} />}
                  />
                </Stack>
                <Stack>
                  <Chip
                    sx={{ color: fontColor }}
                    label={
                      <Translate
                        path={getTeamMemberPersonalityLabel(roster.personality)}
                      />
                    }
                  />
                </Stack>
              </Stack>
              <Stack sx={avatarContainer}>
                <Avatar
                  sx={avatar}
                  alt={roster.nickname}
                  src={roster.digimon.image}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

export default TeamMemberSlot;
