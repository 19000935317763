import type { ProcessedTeam } from 'Modules/Digilab';
import { TeamSlotState } from 'Modules/Digilab';
import {
  Attribute,
  DigimonType,
  getAttributeLabel,
  getDigimonTypeLabel,
} from 'Modules/FieldGuide';
import type { TranslationPath } from 'Translation';

export interface TableHeading {
  name: string;
  altname?: string;
  type: DigimonType;
  attribute: Attribute;
  icon: string;
}

interface TableTypeData {
  key: DigimonType;
  type: TranslationPath;
  digimon: Array<number | undefined>;
  totalWeaknesses: number;
  totalStrengths: number;
}

export interface TableData {
  element: TranslationPath;
  key: Attribute;
  types: TableTypeData[];
  isMostOfTeamWeak: boolean;
  isMostOfTeamStrong: boolean;
}

interface StrengthWeaknessType {
  id: number;
  name: DigimonType;
  strength: DigimonType;
  weakness: DigimonType;
}

interface StrengthWeaknessAttr {
  id: number;
  name: Attribute;
  strength: Attribute;
  weakness: Attribute;
}

interface StrengthWeaknessTied {
  id: number;
  digimon: string;
  name: Attribute | DigimonType;
  strength: Attribute | DigimonType;
  weakness: Attribute | DigimonType;
}

const attributeEffectiveness: StrengthWeaknessAttr[] = [
  {
    id: 1,
    name: 'dark',
    strength: 'light',
    weakness: 'light',
  },
  {
    id: 2,
    name: 'light',
    strength: 'dark',
    weakness: 'dark',
  },
  {
    id: 3,
    name: 'earth',
    strength: 'electric',
    weakness: 'wind',
  },
  {
    id: 4,
    name: 'electric',
    strength: 'wind',
    weakness: 'earth',
  },
  {
    id: 5,
    name: 'wind',
    strength: 'earth',
    weakness: 'electric',
  },
  {
    id: 6,
    name: 'water',
    strength: 'fire',
    weakness: 'plant',
  },
  {
    id: 7,
    name: 'fire',
    strength: 'plant',
    weakness: 'water',
  },
  {
    id: 8,
    name: 'plant',
    strength: 'water',
    weakness: 'fire',
  },
  {
    id: 9,
    name: 'neutral',
    strength: null,
    weakness: null,
  },
] as StrengthWeaknessAttr[];

const typeEffectiveness: StrengthWeaknessType[] = [
  {
    id: 1,
    name: 'data',
    strength: 'vaccine',
    weakness: 'virus',
  },
  {
    id: 2,
    name: 'vaccine',
    strength: 'virus',
    weakness: 'data',
  },
  {
    id: 3,
    name: 'virus',
    strength: 'data',
    weakness: 'vaccine',
  },
  {
    id: 4,
    name: 'free',
    strength: null,
    weakness: null,
  },
] as StrengthWeaknessType[];

function getTypeEffectiveness(
  type: DigimonType,
  typeSW: StrengthWeaknessTied,
  attrEffectiveness?: boolean,
) {
  if (attrEffectiveness !== undefined) {
    if (typeSW.strength === type) {
      return attrEffectiveness ? 0.25 : 0.75;
    } else if (typeSW.weakness === type) {
      return attrEffectiveness ? 0.25 : 3;
    }

    return attrEffectiveness ? 0.5 : 1.5;
  }

  if (typeSW.strength === type) {
    return 0.5;
  } else if (typeSW.weakness === type) {
    return 2;
  }

  return 1;
}

function generateDefensiveTable(team: ProcessedTeam) {
  const header: TableHeading[] = [];
  const attrEffectiveness: StrengthWeaknessTied[] = [];
  const tyEffectiveness: StrengthWeaknessTied[] = [];

  let teamLength = 0;
  const teamMakeup = {
    elements: {
      dark: 0,
      earth: 0,
      electric: 0,
      fire: 0,
      light: 0,
      neutral: 0,
      plant: 0,
      water: 0,
      wind: 0,
    },
    types: {
      data: 0,
      free: 0,
      vaccine: 0,
      virus: 0,
    },
  };

  team.members.forEach((member) => {
    if (member.state === TeamSlotState.Used && !!member.member) {
      const teamMember = member.member;
      teamLength = teamLength += 1;

      header.push({
        altname:
          teamMember.digimon.name !== teamMember.nickname
            ? teamMember.digimon.name
            : undefined,
        attribute: teamMember.digimon.attribute ?? Attribute.Neutral,
        icon: teamMember.digimon.image ?? '',
        name: teamMember.nickname,
        type: teamMember.digimon.type ?? DigimonType.Free,
      });

      const myAttr = attributeEffectiveness.find(
        (el) => el.name === teamMember.digimon.attribute,
      );
      const myType = typeEffectiveness.find(
        (el) => el.name === teamMember.digimon.type,
      );

      if (myAttr) {
        teamMakeup.elements[myAttr.name] +=
          teamMakeup.elements[myAttr.name] + 1;
        attrEffectiveness.push({
          ...myAttr,
          digimon: teamMember.digimon.name as string,
        });
      }

      if (myType) {
        teamMakeup.types[myType.name] += teamMakeup.types[myType.name] + 1;
        tyEffectiveness.push({
          ...myType,
          digimon: teamMember.digimon.name as string,
        });
      }
    }
  });

  const data: TableData[] = attributeEffectiveness.map((attr) => {
    const attribute = attr.name as Attribute;
    const tEffective: TableTypeData[] = [];
    const halfOfTeam = teamLength / 2;
    let isMostOfTeamWeak = false;
    let isMostOfTeamStrong = false;

    typeEffectiveness.forEach((type) => {
      let totalStrengths = 0;
      let totalWeaknesses = 0;

      const typeKey = type.name as DigimonType;

      if (typeKey !== DigimonType.Free) {
        tEffective.push({
          digimon: attrEffectiveness.map((dAttr) => {
            const dType = tyEffectiveness.find(
              (el) => dAttr.digimon === el.digimon,
            );
            const isAttributeLight = attribute === Attribute.Light;
            const isAttributeDark = attribute === Attribute.Dark;
            const isDoubleWeak =
              (dAttr.name === Attribute.Light && isAttributeDark) ||
              (dAttr.name === Attribute.Dark && isAttributeLight);

            if (dType) {
              if (isDoubleWeak) {
                const effectiveness = getTypeEffectiveness(typeKey, dType);

                if (dAttr.strength === attribute) {
                  if (effectiveness === 1) {
                    totalWeaknesses = totalWeaknesses += 1;
                    if (totalWeaknesses > halfOfTeam) {
                      isMostOfTeamWeak = true;
                    }

                    return 1.5;
                  } else if (effectiveness === 0.5) {
                    totalStrengths = totalStrengths += 1;

                    if (totalStrengths > halfOfTeam) {
                      isMostOfTeamStrong = true;
                    }

                    return 0.75;
                  } else if (effectiveness === 2) {
                    totalWeaknesses = totalWeaknesses += 1;

                    if (totalWeaknesses > halfOfTeam) {
                      isMostOfTeamWeak = true;
                    }

                    return 3;
                  }
                }

                if (effectiveness === 1) {
                  return undefined;
                } else if (effectiveness === 0.5) {
                  totalStrengths = totalStrengths += 1;

                  if (totalStrengths > halfOfTeam) {
                    isMostOfTeamStrong = true;
                  }

                  return 0.75;
                }
              }

              if (dAttr.strength === attribute) {
                const effectiveness = getTypeEffectiveness(
                  typeKey,
                  dType,
                  true,
                );

                if (effectiveness > 1) {
                  totalWeaknesses = totalWeaknesses += 1;

                  if (totalWeaknesses > halfOfTeam) {
                    isMostOfTeamWeak = true;
                  }
                } else {
                  totalStrengths = totalStrengths += 1;

                  if (totalStrengths > halfOfTeam) {
                    isMostOfTeamStrong = true;
                  }
                }

                return effectiveness;
              } else if (dAttr.weakness === attribute) {
                const effectiveness = getTypeEffectiveness(
                  typeKey,
                  dType,
                  false,
                );

                if (effectiveness > 1) {
                  totalWeaknesses = totalWeaknesses += 1;

                  if (totalWeaknesses > halfOfTeam) {
                    isMostOfTeamWeak = true;
                  }
                } else {
                  totalStrengths = totalStrengths += 1;

                  if (totalStrengths > halfOfTeam) {
                    isMostOfTeamStrong = true;
                  }
                }

                return effectiveness;
              }

              const effectiveness = getTypeEffectiveness(typeKey, dType);

              if (effectiveness === 1) {
                return undefined;
              } else {
                if (effectiveness > 1) {
                  totalWeaknesses = totalWeaknesses += 1;

                  if (totalWeaknesses > halfOfTeam) {
                    isMostOfTeamWeak = true;
                  }
                } else {
                  totalStrengths = totalStrengths += 1;

                  if (totalStrengths > halfOfTeam) {
                    isMostOfTeamStrong = true;
                  }
                }

                return effectiveness;
              }
            }

            return undefined;
          }),
          key: typeKey,
          totalStrengths,
          totalWeaknesses,
          type: getDigimonTypeLabel(typeKey),
        });
      }
    });

    return {
      element: getAttributeLabel(attribute),
      isMostOfTeamStrong,
      isMostOfTeamWeak,
      key: attribute,
      types: tEffective,
    };
  });

  return {
    data,
    headings: header,
    teamMakeup,
  };
}

export default generateDefensiveTable;
