/** @jsxImportSource @emotion/react */
import { Stack, Typography } from '@mui/material';
import type { DigivolutionConditionChecks } from 'Modules/Digilab/types';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListRow,
  DescriptionTerm,
} from 'Modules/Shared';
import type { TranslationPath } from 'Translation';
import { TranslateTypography } from 'Translation';
import React, { useMemo } from 'react';

interface Props {
  target: string;
  conditions: DigivolutionConditionChecks;
}

const DigivolutionConditionsTooltip: React.FC<Props> = ({
  conditions,
  target,
}) => {
  const label: TranslationPath = useMemo(() => {
    if (conditions.matches.all) {
      return 'Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditionsMet';
    }

    return 'Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditionsNotMet';
  }, [conditions, target]);

  return (
    <Stack spacing={2}>
      <TranslateTypography path={label} values={{ target }} variant="h6" />

      {!conditions.matches.all && (
        <DescriptionList>
          {!conditions.matches.ability && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.ability" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.ability} < ${conditions.requirements.ability}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
          {!conditions.matches.attack && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.attack" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.attack} < ${conditions.requirements.attack}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
          {!conditions.matches.defence && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.defence" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.defence} < ${conditions.requirements.defence}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
          {!conditions.matches.intelligence && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.intelligence" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.intelligence} < ${conditions.requirements.intelligence}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
          {!conditions.matches.speed && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.speed" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.speed} < ${conditions.requirements.speed}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
          {!conditions.matches.sp && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.sp" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.sp} < ${conditions.requirements.sp}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
          {!conditions.matches.hp && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.hp" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.hp} < ${conditions.requirements.hp}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
          {!conditions.matches.level && (
            <DescriptionListRow>
              <DescriptionTerm>
                <TranslateTypography path="Pages.Digilab.Roster.digivolutions.potential.conditionsTooltip.conditions.level" />
              </DescriptionTerm>
              <DescriptionDetails>
                <Typography
                  gutterBottom
                >{`${conditions.values.level} < ${conditions.requirements.level}`}</Typography>
              </DescriptionDetails>
            </DescriptionListRow>
          )}
        </DescriptionList>
      )}
    </Stack>
  );
};

export default DigivolutionConditionsTooltip;
