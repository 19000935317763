import type { Theme } from '@mui/material';
import { Chip, TableCell, TableRow, useTheme } from '@mui/material';
import { attributeList, typeList } from 'Modules/FieldGuide/Const';
import {
  getAttributeColour,
  getAttributeFontColour,
} from 'Modules/FieldGuide/Lib';
import {
  Attribute,
  DigimonType,
  Effectiveness,
} from 'Modules/FieldGuide/Types';
import { white } from 'Theme';
import { Translate } from 'Translation';

const attributeStrengthKey = {
  [Attribute.Dark]: {
    strength: Attribute.Light,
    weakness: Attribute.Light,
  },
  [Attribute.Light]: {
    strength: Attribute.Dark,
    weakness: Attribute.Dark,
  },
  [Attribute.Earth]: {
    strength: Attribute.Electric,
    weakness: Attribute.Wind,
  },
  [Attribute.Electric]: {
    strength: Attribute.Wind,
    weakness: Attribute.Earth,
  },
  [Attribute.Fire]: {
    strength: Attribute.Plant,
    weakness: Attribute.Water,
  },
  [Attribute.Neutral]: {
    strength: null,
    weakness: null,
  },
  [Attribute.Plant]: {
    strength: Attribute.Water,
    weakness: Attribute.Fire,
  },
  [Attribute.Water]: {
    strength: Attribute.Fire,
    weakness: Attribute.Plant,
  },
  [Attribute.Wind]: {
    strength: Attribute.Earth,
    weakness: Attribute.Electric,
  },
};

const typeStrengthKey = {
  [DigimonType.Data]: {
    strength: DigimonType.Vaccine,
    weakness: DigimonType.Virus,
  },
  [DigimonType.Free]: {
    strength: null,
    weakness: null,
  },
  [DigimonType.Vaccine]: {
    strength: DigimonType.Virus,
    weakness: DigimonType.Data,
  },
  [DigimonType.Virus]: {
    strength: DigimonType.Data,
    weakness: DigimonType.Vaccine,
  },
};

function getAttributeEffectiveness(
  rowAttribute: Attribute,
  attribute: Attribute,
): Effectiveness {
  const matchedAttribute = attributeStrengthKey[rowAttribute];

  if (matchedAttribute.strength === attribute) {
    return Effectiveness.SuperEffective;
  } else if (matchedAttribute.weakness === attribute) {
    return Effectiveness.NotEffective;
  }

  return Effectiveness.Effective;
}

function getTypeEffectiveness(rowType: DigimonType, type: DigimonType): number {
  const matchedType = typeStrengthKey[rowType];

  if (matchedType.strength === type) {
    return Effectiveness.SuperEffective;
  } else if (matchedType.weakness === type) {
    return Effectiveness.NotEffective;
  }

  return Effectiveness.Effective;
}

function getCellValue(
  attributeEffectiveness: number,
  typeEffectiveness: number,
): number {
  if (typeEffectiveness === Effectiveness.Effective) {
    if (attributeEffectiveness === Effectiveness.SuperEffective) {
      return 1.5;
    }

    return 1;
  } else if (typeEffectiveness === Effectiveness.NotEffective) {
    if (attributeEffectiveness === Effectiveness.SuperEffective) {
      return 0.75;
    }

    return 0.5;
  } else if (typeEffectiveness === Effectiveness.SuperEffective) {
    if (attributeEffectiveness === Effectiveness.SuperEffective) {
      return 3;
    }

    return 2;
  }

  return 1;
}

function generateAttributeBlock(
  rowAttribute: Attribute,
  attribute: Attribute,
  type: DigimonType,
): number[] {
  const attributeEffectiveness = getAttributeEffectiveness(
    rowAttribute,
    attribute,
  );

  return typeList.map((el) =>
    getCellValue(attributeEffectiveness, getTypeEffectiveness(type, el)),
  );
}

function generateRow(attribute: Attribute, type: DigimonType): number[] {
  const blocks = attributeList.map((attr) =>
    generateAttributeBlock(attribute, attr, type),
  );

  return blocks.flat();
}

function getBgColor(value: number, theme: Theme): string {
  switch (value) {
    case 3:
      return theme.palette.success.dark;

    case 2:
      return theme.palette.success.main;

    case 1.5:
      return theme.palette.success.light;

    case 0.75:
      return theme.palette.error.light;

    case 0.5:
      return theme.palette.error.dark;

    default:
      return '';
  }
}

function MultiplyerRow({
  attr,
  rowIndex,
  type,
}: {
  attr: Attribute;
  rowIndex: number;
  type: DigimonType;
}): JSX.Element {
  const theme = useTheme();
  const data = generateRow(attr, type);

  return (
    <TableRow key={`${attr}-${type}`}>
      {rowIndex === 0 && (
        <TableCell
          rowSpan={4}
          sx={{
            bgcolor: getAttributeColour(attr),
            color: getAttributeFontColour(attr),
          }}
        >
          <Translate path={`Common.attributes.${attr}`} />
        </TableCell>
      )}
      <TableCell>
        <Translate path={`Common.types.${type}`} />
      </TableCell>
      {data.map((cell) => {
        if (cell !== 1) {
          return (
            <TableCell>
              <Chip
                sx={{
                  bgcolor: getBgColor(cell, theme),
                  color: white,
                  width: '3.75rem',
                }}
                label={`${cell}x`}
              />
            </TableCell>
          );
        }

        return <TableCell />;
      })}
    </TableRow>
  );
}

export default MultiplyerRow;
