import { Button, Grid } from '@mui/material';
import type { TranslationPath } from 'Translation';
import { useTranslation } from 'Translation';
import { intersection } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import type { ComboBoxOption } from '../ComboBox';
import { Column } from './SubComponents';

interface ColumnProps {
  items: ComboBoxOption[];
  maxCount?: number;
  title: TranslationPath;
  searchLabel: TranslationPath;
  subtitle: TranslationPath;
}

interface Props {
  leftColumn: ColumnProps;
  rightColumn: ColumnProps;
  updateLists: (movedItems: ComboBoxOption[], right: boolean) => void;
}

const TransferList: React.FC<Props> = ({
  updateLists,
  leftColumn,
  rightColumn,
}) => {
  const [checkedItems, setCheckedItems] = useState<readonly number[]>([]);
  const [leftSideItems, setLeftSideItems] = useState<readonly number[]>([]);
  const [rightSideItems, setRightSideItems] = useState<readonly number[]>([]);

  const handleCheckedLeft = useCallback(
    (value: number) => {
      if (leftSideItems.includes(value)) {
        setLeftSideItems(leftSideItems.filter((el) => el !== value));
      } else {
        setLeftSideItems([...leftSideItems, value].sort());
      }
    },
    [leftColumn.items, leftSideItems],
  );

  const handleCheckedRight = useCallback(
    (value: number) => {
      if (rightSideItems.includes(value)) {
        setRightSideItems(rightSideItems.filter((el) => el !== value));
      } else {
        setRightSideItems([...rightSideItems, value].sort());
      }
    },
    [rightColumn.items, rightSideItems],
  );

  const handleMoveLeft = useCallback(() => {
    const toMove: ComboBoxOption[] = rightColumn.items.filter((el, index) =>
      rightSideItems.includes(index),
    );
    updateLists(toMove, false);
    setRightSideItems([]);
  }, [updateLists, rightSideItems]);

  const handleMoveRight = useCallback(() => {
    const toMove: ComboBoxOption[] = leftColumn.items.filter((el, index) =>
      leftSideItems.includes(index),
    );
    updateLists(toMove, true);
    setLeftSideItems([]);
  }, [updateLists, leftSideItems]);

  const handleSearchLeft = useCallback(
    (e: any, newValue: ComboBoxOption) => {
      if (newValue) {
        updateLists([newValue], true);
      }
    },
    [updateLists],
  );

  const handleSearchRight = useCallback(
    (e: any, newValue: ComboBoxOption) => {
      if (newValue) {
        updateLists([newValue], false);
      }
    },
    [updateLists],
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Column
          title={useTranslation(leftColumn.title)}
          searchLabel={leftColumn.searchLabel}
          subtitle={useTranslation(leftColumn.subtitle, {
            count: leftSideItems.length,
            maxCount: leftColumn.maxCount ?? leftColumn.items.length,
          })}
          items={leftColumn.items}
          checked={leftSideItems}
          handleSearch={handleSearchLeft}
          handleChecked={handleCheckedLeft}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleMoveRight}
            disabled={leftSideItems.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleMoveLeft}
            disabled={rightSideItems.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Column
          title={useTranslation(rightColumn.title)}
          searchLabel={rightColumn.searchLabel}
          subtitle={useTranslation(rightColumn.subtitle, {
            count: rightColumn.items.length,
            maxCount: rightColumn.maxCount ?? leftColumn.items.length,
          })}
          items={rightColumn.items}
          checked={rightSideItems}
          handleSearch={handleSearchRight}
          handleChecked={handleCheckedRight}
        />
      </Grid>
    </Grid>
  );
};

export default TransferList;
