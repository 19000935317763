import { Avatar, Chip } from '@mui/material';
import type { Digimon } from 'Modules/FieldGuide';
import {
  Attribute,
  getAttributeColour,
  getAttributeFontColour,
} from 'Modules/FieldGuide';
import React, { useMemo } from 'react';

interface Member {
  digimon?: Digimon;
  name?: string;
}

function TeamMemberChip({ member }: { member: Member }): JSX.Element {
  const attribute = useMemo(
    () =>
      member && !!member.digimon?.attribute
        ? member.digimon.attribute
        : Attribute.Neutral,
    [member],
  );
  const bgColor = useMemo(() => getAttributeColour(attribute), [member]);
  const fontColor = useMemo(() => getAttributeFontColour(attribute), [member]);
  const showName = useMemo(
    () => member.name !== member.digimon?.name,
    [member],
  );

  return (
    <Chip
      avatar={
        <Avatar alt={member.name || 'Empty'} src={member.digimon?.image}>
          E
        </Avatar>
      }
      label={member.name || 'Empty'}
      sx={{ bgcolor: bgColor, color: fontColor }}
    />
  );
}

export default TeamMemberChip;
