import { blue as colourBlue } from '../constants';

const blue50 = '#ebf5fe';
const blue100 = '#cde6fc';
const blue200 = '#acd5fb';
const blue300 = '#8ac4f9';
const blue400 = '#71b8f7';
const blue600 = '#50a4f5';
const blue700 = '#479af3';
const blue800 = '#3d91f2';
const blue900 = '#2d80ef';
const blueA100 = '#ffffff';
const blueA200 = '#fcfdff';
const blueA400 = '#c9dfff';
const blueA700 = '#b0d0ff';

const blue = {
  100: blue100,
  200: blue200,
  300: blue300,
  400: blue400,
  50: blue50,
  500: colourBlue,
  600: blue600,
  700: blue700,
  800: blue800,
  900: blue900,
  A100: blueA100,
  A200: blueA200,
  A400: blueA400,
  A700: blueA700,
};

export default blue;
