import AddIcon from '@mui/icons-material/Add';
import { Avatar, Chip, TableCell, TableRow } from '@mui/material';
import type { Roster, RosterPaginatedListFilter } from 'Modules/Digilab';
import {
  RosterListingItem,
  getTeamMemberPersonalityLabel,
  useGetRosterQuery,
} from 'Modules/Digilab';
import type { DigimonStage, DigimonType } from 'Modules/FieldGuide';
import {
  Attribute,
  DigimonListingItemSkeleton,
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getDigimonAttributesFilterOptions,
  getDigimonNumber,
  getDigimonStageLabel,
  getDigimonStagesFilterOptions,
  getDigimonTypeLabel,
  getDigimonTypesFilterOptions,
} from 'Modules/FieldGuide';
import type { PaginationFilter, PaginationFilterOption } from 'Modules/Shared';
import {
  NewListingView,
  PaginationFilterType,
  TableLink,
  useModalProps,
  usePagination,
} from 'Modules/Shared';
import type { SpeedDialItem } from 'Modules/Shared/Components/ListingSpeedDial';
import type { ListingEntities, RosterListingRTK } from 'Store';
import type { TranslationPath } from 'Translation';
import { Translate, useTranslation } from 'Translation';
import React, { useCallback, useMemo } from 'react';

import { AddModal } from './modals';

function RosterListing(): JSX.Element {
  const addModalProps = useModalProps();

  const {
    clearFilters,
    clearState,
    entities,
    isLoading,
    options,
    paginate,
    setOptions,
  } = usePagination<RosterListingRTK, RosterPaginatedListFilter>(
    useGetRosterQuery,
    40,
  );

  const title = useTranslation('Pages.FieldGuide.DigimonListing.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.Digilab.breadcrumb'), to: '/digilab' },
    { label: useTranslation('Pages.Digilab.Roster.breadcrumb') },
  ];

  const searchProps = useMemo(
    () => ({
      id: 'digimon-search',
      label:
        'Pages.FieldGuide.DigimonListing.paginationFilters.search.label' as TranslationPath,
      placeholder:
        'Pages.FieldGuide.DigimonListing.paginationFilters.search.placeholder' as TranslationPath,
    }),
    [],
  );

  const getLabelValue = (value?: string | null): string => {
    if (value === null) {
      return 'all';
    }

    return value ?? '';
  };

  const renderItem = useCallback((item: ListingEntities) => {
    const entity = item as unknown as Roster;
    return <RosterListingItem key={entity.id} roster={entity} />;
  }, []);

  const renderRow = useCallback((item: ListingEntities) => {
    const roster = item as unknown as Roster;
    return (
      <TableRow key={roster.id}>
        <TableCell>{getDigimonNumber(roster.digimon.number)}</TableCell>
        <TableCell>
          <Avatar src={roster.digimon.image} />
        </TableCell>
        <TableCell>
          <TableLink to={`/digilab/roster/${roster.id}`}>
            {roster.nickname !== roster.digimon.name
              ? `${roster.nickname} - (${roster.digimon.name})`
              : roster.nickname}
          </TableLink>
        </TableCell>
        <TableCell>{roster.level}</TableCell>
        <TableCell>{roster.digimon.memoryUsage}</TableCell>
        <TableCell>
          <Translate path={getTeamMemberPersonalityLabel(roster.personality)} />
        </TableCell>
        <TableCell>
          <Translate path={getDigimonStageLabel(roster.digimon.stage)} />
        </TableCell>
        <TableCell>
          <Translate path={getDigimonTypeLabel(roster.digimon.type)} />
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              bgcolor: getAttributeColour(
                roster.digimon ? roster.digimon.attribute : Attribute.Neutral,
              ),
              color: getAttributeFontColour(
                roster.digimon ? roster.digimon.attribute : Attribute.Neutral,
              ),
            }}
            label={
              <Translate path={getAttributeLabel(roster.digimon.attribute)} />
            }
          />
        </TableCell>
      </TableRow>
    );
  }, []);

  const filters: PaginationFilter[] = useMemo(() => {
    const setAttributeFilter = (
      e: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ attribute: null });
      } else {
        setOptions({ attribute: value as Attribute });
      }
    };

    const setStageFilter = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ stage: null });
      } else {
        setOptions({ stage: value as DigimonStage });
      }
    };

    const setTypeFilter = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const value = e.target.value;
      clearState();

      if (value === 'all') {
        setOptions({ type: null });
      } else {
        setOptions({ type: value as DigimonType });
      }
    };

    const digimonAttributes: PaginationFilterOption[] =
      getDigimonAttributesFilterOptions();
    const digimonStages: PaginationFilterOption[] =
      getDigimonStagesFilterOptions();
    const digimonTypes: PaginationFilterOption[] =
      getDigimonTypesFilterOptions();

    return [
      {
        id: 'digimon-attribute',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.attribute.label' as TranslationPath,
        onChange: setAttributeFilter,
        options: digimonAttributes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.attribute),
      },
      {
        id: 'digimon-stage',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.stage.label' as TranslationPath,
        onChange: setStageFilter,
        options: digimonStages,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.stage),
      },
      {
        id: 'digimon-type',
        label:
          'Pages.FieldGuide.DigimonListing.paginationFilters.type.label' as TranslationPath,
        onChange: setTypeFilter,
        options: digimonTypes,
        type: PaginationFilterType.Select,
        value: getLabelValue(options.type),
      },
    ];
  }, [options.attribute, options.stage, options.type]);

  const speedDialItems: SpeedDialItem[] = useMemo(
    () => [
      {
        icon: <AddIcon />,
        onClick: () => addModalProps.onOpen(),
        tooltipTitle:
          'Pages.Digilab.RosterListing.speedDial.add' as TranslationPath,
      },
    ],
    [addModalProps],
  );

  return (
    <>
      <NewListingView
        entity="roster"
        isLoading={isLoading}
        paginate={paginate}
        entities={entities}
        breadcrumbs={breadcrumbs}
        title={title}
        listingSkeletonComponent={<DigimonListingItemSkeleton />}
        itemRender={renderItem}
        filters={filters}
        limit={40}
        searchProps={searchProps}
        clearFilters={clearFilters}
        speedDialItems={speedDialItems}
        rowRender={renderRow}
        headings={[
          {
            dataKey: 'number',
            label: 'Pages.Digilab.RosterListing.tableListing.headings.number',
            width: 100,
          },
          {
            dataKey: 'avatar',
            label: 'Pages.Digilab.RosterListing.tableListing.headings.icon',
            width: 100,
          },
          {
            dataKey: 'nickname',
            label: 'Pages.Digilab.RosterListing.tableListing.headings.name',
          },
          {
            dataKey: 'level',
            label: 'Pages.Digilab.RosterListing.tableListing.headings.level',
          },
          {
            dataKey: 'memoryUsage',
            label:
              'Pages.Digilab.RosterListing.tableListing.headings.memoryUsage',
          },
          {
            dataKey: 'personality',
            label:
              'Pages.Digilab.RosterListing.tableListing.headings.personality',
          },
          {
            dataKey: 'stage',
            label: 'Pages.Digilab.RosterListing.tableListing.headings.stage',
          },
          {
            dataKey: 'type',
            label: 'Pages.Digilab.RosterListing.tableListing.headings.type',
          },
          {
            dataKey: 'attribute',
            label:
              'Pages.Digilab.RosterListing.tableListing.headings.attribute',
          },
        ]}
      />
      <AddModal isOpen={addModalProps.isOpen} onClose={addModalProps.onClose} />
    </>
  );
}

export default RosterListing;
