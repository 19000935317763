/** @jsxImportSource @emotion/react */
import LoadingButton from '@mui/lab/LoadingButton';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  RosterPersonality,
  generateCreateRosterDTO,
  useCreateRosterMutation,
} from 'Modules/Digilab';
import { personalityList } from 'Modules/Digilab/Constants';
import type { Digimon } from 'Modules/FieldGuide';
import {
  DigimonListingItem,
  getDigimonStageLabel,
  useGetDigimonByIDQuery,
  useGetDigimonQuery,
} from 'Modules/FieldGuide';
import type { ComboBoxOption } from 'Modules/Shared';
import { ComboBox } from 'Modules/Shared';
import type { TranslationPath } from 'Translation';
import { Translate, TranslateTypography, useTranslation } from 'Translation';
import i18n from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface FormData {
  digimonId: number;
  nickname?: string;
  personality?: RosterPersonality;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function generateComboBoxOption(digimon: Digimon): ComboBoxOption {
  return {
    group: i18n.t(getDigimonStageLabel(digimon.stage)),
    label: digimon.name,
    value: digimon.id.toString(),
  };
}

const AddModal: React.FC<Props> = ({ isOpen = false, onClose }) => {
  const [createRoster, result] = useCreateRosterMutation();
  const rosterRTK = useGetDigimonQuery({ limit: 350 }, { skip: !isOpen });
  const { setValue, handleSubmit, reset, watch, register, control } = useForm({
    defaultValues: {
      digimonId: 0,
      nickname: '',
      personality: RosterPersonality.Brainy,
    },
  });

  const description = useTranslation(
    'Pages.Digilab.RosterListing.modals.add.description',
  );
  const digimon: Digimon[] = rosterRTK.data?.items ?? [];
  const digimonOptions: ComboBoxOption[] = digimon.map(generateComboBoxOption);

  const watchedDigimonId = watch('digimonId', 0);
  const selectedDigimonQuery = useGetDigimonByIDQuery(watchedDigimonId, {
    skip: watchedDigimonId === 0,
  });

  const selectedDigimon: Digimon | undefined = useMemo(() => {
    if (watchedDigimonId !== 0) {
      return digimon.find((monster) => monster.id === watchedDigimonId);
    }

    return undefined;
  }, [digimon, watchedDigimonId]);

  const watchedPersonality = watch('personality', RosterPersonality.Brainy);
  const selectedPersonality:
    | { description: TranslationPath; label: TranslationPath; value: string }
    | undefined = useMemo(
    () =>
      personalityList.find(
        (personality) => personality.value === watchedPersonality,
      ),
    [personalityList, watchedPersonality],
  );

  const onSubmit = (data: FormData) => {
    console.log(selectedDigimonQuery, watchedDigimonId);
    if (selectedDigimonQuery.data) {
      const newRosterMember = generateCreateRosterDTO(
        selectedDigimonQuery.data,
        data.nickname,
        data.personality,
      );
      createRoster(newRosterMember);
    }
  };

  useEffect(
    () => () => {
      if (!isOpen) {
        reset({
          digimonId: 0,
          nickname: '',
          personality: RosterPersonality.Brainy,
        });
      }
    },
    [isOpen],
  );

  useEffect(() => {
    if (!result.isUninitialized && result.isSuccess && watchedDigimonId !== 0) {
      reset({
        digimonId: 0,
        nickname: '',
        personality: RosterPersonality.Brainy,
      });
      onClose();
    }
  }, [reset, result, onClose]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {useTranslation('Pages.Digilab.Team.modals.add.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2} spacing={2}>
            <ComboBox
              label="Pages.Digilab.RosterListing.modals.add.fields.digimon"
              options={digimonOptions}
              value={`${watchedDigimonId ?? ''}`}
              grouped
              onChange={(e, newValue) => {
                if (newValue) {
                  setValue('digimonId', Number(newValue.value), {
                    shouldValidate: true,
                  });
                } else if (selectedDigimon) {
                  setValue('digimonId', 0, { shouldValidate: true });
                }
              }}
            />
            {!!selectedDigimon && (
              <DigimonListingItem
                digimon={selectedDigimon}
                showButtons={false}
              />
            )}
            <Grid container spacing={2} width="100%">
              <Grid item xs={12} sm={6} sx={{ pl: '0px !important' }}>
                <Controller
                  name="nickname"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel htmlFor="nickname" shrink={true}>
                        <Translate path="Pages.Digilab.Teams.modals.add.fields.name" />
                      </InputLabel>
                      <TextField
                        id="nickname"
                        label={
                          <Translate path="Pages.Digilab.Teams.modals.add.fields.name" />
                        }
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue('nickname', e.target.value)
                        }
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="personality-label">
                    {useTranslation(
                      'Pages.Digilab.RosterListing.modals.add.fields.personality',
                    )}
                  </InputLabel>
                  <Select
                    labelId="personality-label"
                    label={useTranslation(
                      'Pages.Digilab.RosterListing.modals.add.fields.personality',
                    )}
                    value={`${watchedPersonality ?? ''}`}
                    onChange={(e: SelectChangeEvent) =>
                      setValue(
                        'personality',
                        e.target.value as RosterPersonality,
                        { shouldValidate: true },
                      )
                    }
                  >
                    {personalityList.map((personality) => (
                      <MenuItem
                        value={personality.value}
                        key={personality.value}
                      >
                        <Translate path={personality.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedPersonality && (
                  <Paper sx={{ mt: 2, p: 2 }}>
                    <TranslateTypography
                      path={selectedPersonality.description}
                    />
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation('Pages.Digilab.Team.modals.add.buttons.cancel')}
        </Button>
        <LoadingButton
          loading={result.isLoading}
          disabled={!selectedDigimon}
          onClick={handleSubmit(onSubmit)}
        >
          {useTranslation('Pages.Digilab.Team.modals.add.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
