import { DigimonType } from '../Types';

const typeList = [
  DigimonType.Data,
  DigimonType.Vaccine,
  DigimonType.Virus,
  DigimonType.Free,
];

export default typeList;
