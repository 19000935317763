/** @jsxImportSource @emotion/react */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Chip, Grid, Paper, Stack, Tab, Typography } from '@mui/material';
import {
  DigimonListingItem,
  LocationListingItem,
} from 'Modules/FieldGuide/Components';
import { getItemCategoryLabel } from 'Modules/FieldGuide/Lib';
import { useGetItemByIDQuery } from 'Modules/FieldGuide/Store';
import { useEntityPage } from 'Modules/Shared';
import { largeCornerRadius, theme, white } from 'Theme';
import { Translate, useTranslation } from 'Translation';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './styles';

const ItemDetail: React.FC = () => {
  const params = useParams<{ item?: string | undefined }>();
  const itemId = params.item ?? 0;
  const query = useGetItemByIDQuery(itemId);
  const item = query.data;

  const [selectedTab, setSelectedTab] = useState<string>('1');
  const { chipBlock, container, headerRow, imageContainer } = styles;

  const title = useTranslation('Pages.FieldGuide.title');
  const getTitle = (): string =>
    query.data ? `${title} - ${query.data.name}` : '';

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    {
      label: useTranslation('Pages.FieldGuide.ItemsListing.breadcrumb'),
      to: '/field-guide/items',
    },
  ];

  useEntityPage(query.data, getTitle, breadcrumbs, !!query.isLoading);

  const handleTabClick = (event: any, newValue: string) => {
    setSelectedTab(newValue);
  };

  if (!!item && query.isSuccess) {
    const categoryLabel = getItemCategoryLabel(item.category);

    return (
      <Box sx={container}>
        <Stack sx={{ p: 2, pb: 4, px: 4 }}>
          <Box sx={headerRow}>
            <Typography
              component="h1"
              variant="h2"
              sx={{ color: white, mb: 1 }}
            >
              {item.name}
            </Typography>
          </Box>
          <Box sx={headerRow}>
            <Box sx={chipBlock}>
              <Chip
                sx={{ color: white }}
                label={<Translate path={categoryLabel} />}
              />
            </Box>
          </Box>
          <Box sx={imageContainer}>
            <img src={item.icon} alt={item.name} />
          </Box>
        </Stack>
        <Paper
          sx={{
            borderRadius: `${largeCornerRadius} ${largeCornerRadius} 0 0`,
            height: `calc(100% + ${theme.spacing(2)})`,
            mt: -2,
            p: 2,
          }}
          elevation={3}
        >
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
              <TabList onChange={handleTabClick} aria-label="tabs">
                <Tab
                  label={<Translate path="Pages.FieldGuide.Items.tabs.about" />}
                  value="1"
                  sx={{ fontWeight: 'bold' }}
                />
                {item.droppedBy.length > 0 && (
                  <Tab
                    label={
                      <Translate path="Pages.FieldGuide.Items.tabs.digimon" />
                    }
                    value="2"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {item.soldAt.length > 0 && (
                  <Tab
                    label={
                      <Translate path="Pages.FieldGuide.Items.tabs.areas" />
                    }
                    value="3"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Stack>
                <Typography component="p" sx={{ mb: 4 }}>
                  {item.description}
                </Typography>
              </Stack>
            </TabPanel>
            {item.droppedBy.length > 0 && (
              <TabPanel value="2">
                <Grid container spacing={2}>
                  {item.droppedBy.map((digimon) => (
                    <Grid xs={2} item key={`digimon-${digimon.id}`}>
                      <DigimonListingItem digimon={digimon} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
            {item.soldAt.length > 0 && (
              <TabPanel value="3">
                <Grid container spacing={2}>
                  {item.soldAt.map((location) => (
                    <Grid xs={2} item key={`location-${location.id}`}>
                      <LocationListingItem location={location} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
          </TabContext>
          <p />
        </Paper>
      </Box>
    );
  }

  return <></>;
};

export default ItemDetail;
