/** @jsxImportSource @emotion/react */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import type { ProcessedTeam, ProcessedTeamMember, Team } from 'Modules/Digilab';
import {
  TeamMemberSlot,
  processTeam,
  useGetTeamByIDQuery,
} from 'Modules/Digilab';
import {
  setAppBarStyling,
  setPage,
  useEntityPage,
  useModalProps,
} from 'Modules/Shared';
import { largeCornerRadius, theme, white } from 'Theme';
import { Translate, useTranslation } from 'Translation';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Analysis, Members } from './tabs';
import BattleHistory from './tabs/BattleHistory';

interface Props {
  team: ProcessedTeam;
}

const TeamDetail: React.FC<Props> = ({ team }) => {
  const [selectedTab, setSelectedTab] = useState<string>('1');
  const title = useTranslation('Pages.Digilab.Team.title');
  const getTitle = () => `${title} - ${team.name}`;

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.Digilab.breadcrumb'), to: '/digilab' },
    {
      label: useTranslation('Pages.Digilab.Teams.breadcrumb'),
      to: '/digilab/teams',
    },
  ];

  useEntityPage({ name: team.name }, getTitle, breadcrumbs, false);

  const handleTabClick = (event: any, newValue: string) => {
    setSelectedTab(newValue);
  };

  const teamMemoryUsed = useMemo(
    () =>
      team.members.reduce<number>(
        (prevVal: number, member: ProcessedTeamMember): number => {
          if (member.member?.digimon) {
            return member.member.digimon.memoryUsage ?? 0 + prevVal;
          }

          return prevVal ? prevVal : 0;
        },
        0,
      ),
    [team],
  );

  return (
    <Stack alignItems="stretch">
      <Helmet>
        <title>
          {title} - {team.name}
        </title>
      </Helmet>
      <Stack
        height="320px"
        sx={{ p: 2, pb: { md: 4, xs: 2 }, px: { md: 4, xs: 2 } }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography component="h1" variant="h2" sx={{ color: white, mb: 1 }}>
            {team.name}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ color: white, mb: 1 }}>
            {useTranslation('Pages.Digilab.Team.memoryUsed', {
              total: team.maxPartyMemory,
              used: teamMemoryUsed,
            })}
          </Typography>
        </Stack>
      </Stack>
      <Paper
        sx={{
          borderRadius: `${largeCornerRadius} ${largeCornerRadius} 0 0`,
          minHeight: `calc(100vh - calc(384px - ${theme.spacing(2)}))`,
          mt: -2,
          p: 2,
        }}
        elevation={3}
      >
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
            <TabList onChange={handleTabClick} aria-label="tabs">
              <Tab
                label={<Translate path="Pages.Digilab.Team.tabs.roster" />}
                value="1"
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                label={<Translate path="Pages.Digilab.Team.tabs.analysis" />}
                value="2"
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                label={
                  <Translate path="Pages.Digilab.Team.tabs.battleHistory" />
                }
                value="3"
                sx={{ fontWeight: 'bold' }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Members team={team} />
          </TabPanel>
          <TabPanel value="2">
            <Analysis team={team} />
          </TabPanel>
          <TabPanel value="3">
            <BattleHistory team={team} />
          </TabPanel>
        </TabContext>
      </Paper>
    </Stack>
  );
};

const Wrapper: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ team?: string | undefined }>();
  const teamId = params.team ? Number(params.team) : 0;
  const query = useGetTeamByIDQuery(teamId);
  const team = query.data as Team;

  const title = useTranslation('Pages.FieldGuide.title');

  const getTitle = () => (query.data ? `${title} - ${query.data.name}` : '');

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.Digilab.breadcrumb'), to: '/digilab' },
    {
      label: useTranslation('Pages.Digilab.Teams.breadcrumb'),
      to: '/digilab/teams',
    },
  ];

  useEntityPage(query.data, getTitle, breadcrumbs, true);

  if (team !== undefined) {
    const processedTeam = processTeam(team);
    return <TeamDetail team={processedTeam} />;
  }

  dispatch(
    setPage({
      breadcrumbs: [...breadcrumbs, { label: getTitle() }],
      title,
    }),
  );

  dispatch(
    setAppBarStyling({
      loading: true,
      styles: {
        backgroundImage: 'none',
        boxShadow: 'none',
      },
    }),
  );

  return <></>;
};

export default Wrapper;
