import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import type { Team, TeamMember } from 'Modules/Digilab';
import { TeamMemberType } from 'Modules/Digilab';
import { useGetThemeAccent } from 'Theme';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

function TeamsListingItem({ team }: { team: Team }): JSX.Element {
  const {
    avatar,
    avatarSmall,
    avatarContainer,
    button,
    card,
    contents,
    chipContainer,
    footer,
    metaBlock,
    metaLabel,
    metaTitle,
    subtitle,
    title,
  } = styles;
  const getAppThemeAccent = useGetThemeAccent();
  const navigate = useNavigate();
  const accent = getAppThemeAccent();

  const navigateTo = () => {
    navigate(`/digilab/teams/${team.id}`);
  };

  const activeMembers = useMemo(() => {
    const members: TeamMember[] = [];

    team.members.forEach((member) => {
      if (member.type === TeamMemberType.Active) {
        members.push(member);
      }
    });

    return members;
  }, [team]);

  const reserveMembers = useMemo(() => {
    const members: TeamMember[] = [];

    team.members.forEach((member) => {
      if (member.type === TeamMemberType.Reserve) {
        members.push(member);
      }
    });

    const half = Math.ceil(members.length / 2);

    return {
      firstHalf: members.slice(0, half),
      secondHalf: members.slice(-half),
    };
  }, [team]);

  const bgColor = accent.main;
  const fontColor = accent.contrastText;

  return (
    <Card sx={{ ...card, bgcolor: bgColor }}>
      <CardContent>
        <Stack direction="row" alignItems="flex-end">
          <Typography sx={{ ...title, color: fontColor }}>
            {team.name}
          </Typography>
        </Stack>
        <Stack sx={contents}>
          <AvatarGroup sx={{ marginRight: '10px' }}>
            {activeMembers.map((member) => (
              <Avatar
                key={`team-${team.name}-member-${member.type}-${member.number}`}
                sx={{ ...avatar, borderColor: `${bgColor} !important` }}
                alt={member.member?.nickname}
                src={member.member?.digimon.image}
              >
                E
              </Avatar>
            ))}
          </AvatarGroup>
          <Stack>
            <AvatarGroup>
              {reserveMembers.firstHalf.map((member) => (
                <Avatar
                  key={`team-${team.name}-member-${member.type}-${member.number}`}
                  sx={{
                    ...avatarSmall,
                    borderColor: `${bgColor} !important`,
                  }}
                  alt={member.member?.nickname}
                  src={member.member?.digimon.image}
                >
                  E
                </Avatar>
              ))}
            </AvatarGroup>
            <AvatarGroup>
              {reserveMembers.secondHalf.map((member) => (
                <Avatar
                  key={`team-${team.name}-member-${member.type}-${member.number}`}
                  sx={{
                    ...avatarSmall,
                    borderColor: `${bgColor} !important`,
                  }}
                  alt={member.member?.nickname}
                  src={member.member?.digimon.image}
                >
                  E
                </Avatar>
              ))}
            </AvatarGroup>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions sx={footer}>
        <Button
          onClick={navigateTo}
          sx={{ ...button, color: fontColor }}
          size="small"
        >
          See more
        </Button>
        <Button sx={{ ...button, color: fontColor }} size="small">
          Like
        </Button>
      </CardActions>
    </Card>
  );
}

export default TeamsListingItem;
