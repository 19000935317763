import type { ComboBoxOption } from 'Modules/Shared/Components';

function getUniqueOptions(options: ComboBoxOption[]) {
  return options.reduce((unique, option) => {
    if (!unique.some((obj) => obj.value === option.value)) {
      unique.push(option);
    }

    return unique;
  }, [] as ComboBoxOption[]);
}

export default getUniqueOptions;
