import type {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/react';
import type { User } from 'Modules/Profile';
import type { StoreEntities } from 'Store';
import { api } from 'Store';

export const profileApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    // favouriteDigimonAdd: build.mutation<User, DigimonFavouriteRequest>({
    //   query: (digimon) => ({
    //     body: {
    //       digimon,
    //     },
    //     method: 'POST',
    //     url: 'users/favourite/digimon/add',
    //   }),
    // }),
    // favouriteDigimonRemove: build.mutation<User, DigimonFavouriteRequest>({
    //   query: (digimon) => ({
    //     body: {
    //       digimon,
    //     },
    //     method: 'POST',
    //     url: 'users/favourite/digimon/remove',
    //   }),
    // }),
    getMe: build.query<User, Record<string, string>>({
      query: () => 'users/me',
    }),
    updateMe: build.mutation<User, Partial<User>>({
      query: (updatedUser) => ({
        body: updatedUser,
        method: 'PATCH',
        url: 'users/me',
      }),
    }),
  }),
});

export const { useGetMeQuery, useUpdateMeMutation } = profileApiSlice;
