import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type {
  CreateTeamDTO,
  PatchTeamDTO,
  Team,
  TeamPaginatedListFilter,
} from 'Modules/Digilab/Types';
import type { PaginatedList, PaginationOptions, StoreEntities } from 'Store';
import { api, providesList } from 'Store';
import urlcat from 'urlcat';

export const teamsApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    createTeam: build.mutation<Team, CreateTeamDTO>({
      invalidatesTags: [{ id: 'LIST', type: 'Teams' }],
      query: (dto) => ({
        body: dto,
        method: 'POST',
        url: `/teams`,
      }),
    }),
    getTeamByID: build.query<Team, number>({
      providesTags: (result, error, arg) => [
        {
          id: result?.id ?? arg,
          type: 'Teams',
        },
      ],
      query: (id) => `teams/${id}`,
    }),
    getTeams: build.query<
      PaginatedList<Team>,
      PaginationOptions<TeamPaginatedListFilter>
    >({
      providesTags: (result, error, arg) => providesList(result, 'Teams'),
      query: (options = { limit: 40 }) => urlcat('/teams', options),
    }),
    patchTeam: build.mutation<Team, PatchTeamDTO>({
      invalidatesTags: (result, error, arg) => [{ id: 'LIST', type: 'Teams' }],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          // @ts-ignore
          teamsApiSlice.util.updateQueryData('getTeamByID', id, (draft) => {
            Object.assign(draft, patch);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      query: ({ id, ...dto }) => ({
        body: dto,
        method: 'PATCH',
        url: `/teams/${id}`,
      }),
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useGetTeamByIDQuery,
  useCreateTeamMutation,
  usePatchTeamMutation,
} = teamsApiSlice;
