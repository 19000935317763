import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import type { PaginationFilter, SearchField } from 'Modules/Shared';
import {
  ClassicPaginationFilters,
  ListingSpeedDial,
  PaginationFilters,
  setPage,
} from 'Modules/Shared';
import type { ListingEntities } from 'Store';
import type { TranslationPath } from 'Translation';
import { Translate } from 'Translation';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { SpeedDialItem } from '../ListingSpeedDial';

interface TableHeading {
  dataKey: string;
  label?: TranslationPath;
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
  width?: string | number;
}

interface Props {
  entity: string;
  entities: ListingEntities[];
  title: string;
  breadcrumbs: Array<{ label: string; to?: string }>;
  listingSkeletonComponent: React.ReactElement;
  getEntitiesCount: number;
  headings: TableHeading[];
  rowRender: (entity: ListingEntities) => React.ReactElement;
  clearFilters(): void;
  paginate(): void;
  isLoading: boolean;
  filters?: PaginationFilter[];
  searchProps?: SearchField;
  noResultsComponent?: React.ReactElement;
}

function ClassicListingView({
  entity,
  entities,
  breadcrumbs,
  title,
  listingSkeletonComponent,
  headings,
  rowRender,
  clearFilters,
  paginate,
  isLoading = false,
  filters = [],
  getEntitiesCount = 20,
  searchProps = {
    id: 'listing-search',
    label: 'Components.PaginationFilters.search.label',
    placeholder: 'Components.PaginationFilters.search.placeholder',
  },
  noResultsComponent = <p>Nothing to see</p>,
}: Props): JSX.Element {
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: windowHeight / 1.5,
  });
  const dispatch = useDispatch();
  const theme = useTheme();

  const [speedDialRight, setSpeedDialRight] = useState<number>(2);
  const [containerScrollbarWidth, setContainerScrollbarWidth] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const closeFilterModal = useCallback(() => setIsFilterOpen(false), []);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  const loadingArray = useMemo(() => {
    // @ts-ignore
    const numMap: number[] = [...Array(getEntitiesCount).keys()];

    return numMap.map((index) => (
      <Grid
        xs={12}
        sm={6}
        lg={4}
        xl={3}
        key={`${entity}-loading-${index}`}
        item
      >
        {listingSkeletonComponent}
      </Grid>
    ));
  }, [getEntitiesCount]);

  useLayoutEffect(() => {
    if (trigger) {
      setWindowHeight(window.innerHeight + windowHeight);
      console.log();
    }
  }, [trigger]);

  useLayoutEffect(() => {
    /**
     * We calculate the scrollbar width so we can calculate the computed value of the container (padding, width, scrollbar)
     * We need to do this because by using CSS Grid and taking the lists out of the regular flow their widths are not constrained by the container
     * useLayoutEffect fires synchronously after all DOM mutations so we use this to read the DOM layout - https://reactjs.org/docs/hooks-reference.html#uselayouteffect
     */
    if (containerRef.current) {
      const width =
        containerRef.current.offsetWidth - containerRef.current.clientWidth;
      setSpeedDialRight(width === 0 ? 2 : 4);
      if (width !== containerScrollbarWidth) {
        setContainerScrollbarWidth(width);
      }
    }
    // @ts-ignore
  }, [containerRef, containerScrollbarWidth]);

  const handleScroll = useCallback(async () => {
    paginate();
  }, [isLoading, paginate]);

  useEffect(() => {
    if (trigger) {
      debouncedScroll();
    }
  }, [trigger]);

  const debouncedScroll = debounce(handleScroll, 250);

  return (
    <Stack
      sx={{ height: '100%', overflowY: 'auto', p: 2 }}
      alignItems="center"
      ref={containerRef}
    >
      {entities.length !== 0 && (
        <Stack
          spacing={2}
          sx={{ maxWidth: 1600, width: '100%' }}
          alignItems="center"
        >
          <ClassicPaginationFilters
            filters={filters}
            search={searchProps}
            clearFilters={clearFilters}
          />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headings.map((heading) => (
                    <TableCell
                      key={heading.dataKey}
                      align={heading.align}
                      width={heading.width}
                    >
                      {heading.label && <Translate path={heading.label} />}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{entities.map(rowRender)}</TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
      {entities.length === 0 && !isLoading && noResultsComponent}
    </Stack>
  );
}

export default ClassicListingView;
