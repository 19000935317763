import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type {
  Location,
  LocationsPaginatedListFilter,
} from 'Modules/FieldGuide/Types';
import type { PaginatedList, PaginationOptions, StoreEntities } from 'Store';
import { api, providesList } from 'Store';
import urlcat from 'urlcat';

export const locationsApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    getLocationByID: build.query<Location, string | number>({
      providesTags: (result, error, arg) => [{ id: arg, type: 'Locations' }],
      query: (id) => `locations/${id}`,
    }),
    getLocations: build.query<
      PaginatedList<Location>,
      PaginationOptions<LocationsPaginatedListFilter>
    >({
      providesTags: (result, error, arg) => providesList(result, 'Locations'),
      query: (options = { limit: 40 }) => urlcat('/locations', options),
    }),
  }),
});

export const { useGetLocationsQuery, useGetLocationByIDQuery } =
  locationsApiSlice;
