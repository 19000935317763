import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import type { SxProps, Theme } from '@mui/material';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import type { TranslationPath } from 'Translation';
import { useTranslation } from 'Translation';
import * as React from 'react';

import type { ComboBoxOption } from './types';

interface Props {
  disablePortal?: boolean;
  fullWidth?: boolean;
  label: TranslationPath;
  id?: string;
  options: ComboBoxOption[];
  sx?: SxProps<Theme> | undefined;
  value?: any[];
  onChange?: (e: any, newValue: ComboBoxOption[] | null) => void;
  grouped?: boolean;
}

function groupedByFunc(option: ComboBoxOption) {
  return option.group ?? '';
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiComboBox: React.FC<Props> = ({
  disablePortal = false,
  fullWidth = false,
  id,
  options,
  label,
  sx,
  grouped = false,
  value,
  onChange,
}) => {
  const translatedLabel = useTranslation(label);

  return (
    <Autocomplete
      multiple
      disablePortal={disablePortal}
      fullWidth={fullWidth}
      id={id}
      options={options}
      getOptionDisabled={(option) => option.disabled === true}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={sx}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label={translatedLabel} />
      )}
      onChange={onChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      groupBy={grouped ? groupedByFunc : undefined}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
    />
  );
};

export default MultiComboBox;
