import { Stack } from '@mui/material';
import { BattleHistoryTable } from 'Modules/Digilab/Components';
import type { BattleParticipant, ProcessedTeam } from 'Modules/Digilab/Types';
import {
  Battle,
  BattleParticipantType,
  BattleType,
} from 'Modules/Digilab/Types';
import type { Digimon } from 'Modules/FieldGuide';
import { Attribute } from 'Modules/FieldGuide';

interface Props {
  team: ProcessedTeam;
}

const BattleHistory: React.FC<Props> = ({ team }) => {
  const data = [
    {
      enemies: [
        {
          digimon: {
            attribute: Attribute.Fire,
            id: 47,
            image:
              'https://digimon-assets.s3.eu-west-2.amazonaws.com/digimon/47_hackmon.png',
            name: 'Hackmon',
          } as Digimon,
          id: 8,
          name: 'Hackmon',
          type: BattleParticipantType.Digimon,
        } as BattleParticipant,
        {
          digimon: {
            attribute: Attribute.Wind,
            id: 67,
            image:
              'https://digimon-assets.s3.eu-west-2.amazonaws.com/digimon/67_aquilamon.png',
            name: 'Aquilamon',
          } as Digimon,
          id: 9,
          name: 'Aquilamon',
          type: BattleParticipantType.Digimon,
        } as BattleParticipant,
      ],
      id: 1,
      name: 'Test Battle',
      participants: team.members
        .map((el, index) => {
          if (el && el.member) {
            return {
              digimon: el.member.digimon,
              id: index,
              name: el.member.nickname,
              rosterId: el.member.id,
              type: BattleParticipantType.Roster,
            } as BattleParticipant;
          }

          return undefined;
        })
        .filter((el) => el !== undefined) as BattleParticipant[],
      team,
      type: BattleType.AI,
    },
  ];

  return (
    <Stack>
      <BattleHistoryTable data={data} />
    </Stack>
  );
};

export default BattleHistory;
