import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import type { Theme as NotifyTheme } from 'react-toastify';

import { Accent, Theme } from '../Types';

interface ThemeContext {
  accent: Accent;
  theme: Theme;
  notificationTheme: NotifyTheme;
  setAccent: (accent: Accent) => void;
  setNotificationTheme: (theme: NotifyTheme) => void;
  setTheme: (theme: Theme) => void;
}

export const themeContext = React.createContext<ThemeContext>({
  accent: Accent.Blue,
  notificationTheme: 'colored',
  setAccent: (accent) => null,
  setNotificationTheme: (theme) => null,
  setTheme: (theme) => null,
  theme: Theme.System,
});

export function ThemeProvider({
  children,
}: {
  children?: ReactNode;
}): JSX.Element {
  const [theme, setTheme] = useState<Theme>(Theme.System);
  const [notificationTheme, setNotificationTheme] =
    useState<NotifyTheme>('colored');
  const [accent, setAccent] = useState<Accent>(Accent.Blue);
  const [shouldLoadFromCache, setShouldLoadFromCache] = useState<boolean>(true);

  useEffect(() => {
    const cachedAccent = localStorage.getItem('accent');
    const cachedTheme = localStorage.getItem('theme');

    if (shouldLoadFromCache && accent !== cachedAccent && !!cachedAccent) {
      setAccent(cachedAccent as Accent);
      console.log('accent', cachedAccent);
    }

    if (shouldLoadFromCache && theme !== cachedTheme && !!cachedTheme) {
      setTheme(cachedTheme as Theme);
      console.log('theme', cachedTheme);
    }
  }, [accent, shouldLoadFromCache, theme]);

  const setAppAccent = useCallback(
    (newAccent: Accent) => {
      if (newAccent !== accent) {
        setAccent(newAccent);
        setShouldLoadFromCache(false);
        localStorage.setItem('accent', newAccent);
      }
    },
    [accent],
  );

  const setAppTheme = useCallback(
    (newTheme: Theme) => {
      if (newTheme !== theme) {
        setTheme(newTheme);
        setShouldLoadFromCache(false);
        localStorage.setItem('theme', theme);
      }
    },
    [theme],
  );

  const setAppNotificationTheme = useCallback(
    (newTheme: NotifyTheme) => {
      if (newTheme !== notificationTheme) {
        setNotificationTheme(newTheme);
        setShouldLoadFromCache(false);
        localStorage.setItem('notificationTheme', notificationTheme);
      }
    },
    [notificationTheme],
  );

  return (
    <themeContext.Provider
      value={{
        accent,
        notificationTheme,
        setAccent: setAppAccent,
        setNotificationTheme: setAppNotificationTheme,
        setTheme: setAppTheme,
        theme,
      }}
    >
      {children}
    </themeContext.Provider>
  );
}
