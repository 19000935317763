import { createEntityAdapter } from '@reduxjs/toolkit';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type {
  Roster,
  RosterCreateDTO,
  RosterPaginatedListFilter,
  RosterPatchDTO,
} from 'Modules/Digilab';
import type { PaginatedList, PaginationOptions, StoreEntities } from 'Store';
import { api } from 'Store';
import urlcat from 'urlcat';

const rosterAdapter = createEntityAdapter();
const initialState = rosterAdapter.getInitialState();

export const rosterApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    createRoster: build.mutation<Roster, RosterCreateDTO>({
      invalidatesTags: ['Roster'],
      query: (dto) => ({
        body: dto,
        method: 'POST',
        url: '/roster',
      }),
    }),
    digivolve: build.mutation<Roster, { id: number; digimonID: number }>({
      invalidatesTags: (result, error, arg) => [{ id: arg.id, type: 'Roster' }],
      query: (options) => ({
        method: 'POST',
        url: `/roster/${options.id}/digivolve/${options.digimonID}`,
      }),
    }),
    getRoster: build.query<
      PaginatedList<Roster>,
      PaginationOptions<RosterPaginatedListFilter>
    >({
      providesTags: (result, error, page) =>
        result
          ? [
              // Provides a tag for each post in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.items.map(({ id }) => ({
                id,
                type: 'Roster' as const,
              })),
              { id: 'PARTIAL-LIST', type: 'Roster' },
            ]
          : [{ id: 'PARTIAL-LIST', type: 'Roster' }],
      query: (options = { limit: 40 }) => urlcat('/roster', options),
    }),
    getRosterByID: build.query<Roster, number>({
      providesTags: (result, error, arg) => [{ id: arg, type: 'Roster' }],
      query: (id) => `roster/${id}`,
    }),
    patchRoster: build.mutation<Roster, RosterPatchDTO>({
      invalidatesTags: (result, error, arg) => [
        { id: 'PARTIAL-LIST', type: 'Roster' },
      ],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          // @ts-ignore
          rosterApiSlice.util.updateQueryData('getRosterByID', id, (draft) => {
            Object.assign(draft, patch);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      query: ({ id, ...dto }) => ({
        body: dto,
        method: 'PATCH',
        url: `/roster/${id}`,
      }),
    }),
  }),
});

export const {
  useGetRosterQuery,
  useGetRosterByIDQuery,
  useCreateRosterMutation,
  usePatchRosterMutation,
} = rosterApiSlice;
