enum Stage {
  TrainingLower = 'training-lower',
  TrainingUpper = 'training-upper',
  Rookie = 'rookie',
  Champion = 'champion',
  Ultimate = 'ultimate',
  Mega = 'mega',
  Ultra = 'ultra',
  Armor = 'armor',
}

export default Stage;
