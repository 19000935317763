const en = {
  Common: {
    attributes: {
      dark: 'Dark',
      earth: 'Earth',
      electric: 'Electric',
      fire: 'Fire',
      light: 'Light',
      neutral: 'Neutral',
      plant: 'Plant',
      water: 'Water',
      wind: 'Wind',
    },
    errors: {
      fetch: 'Unable to fetch {{entity}}',
    },
    types: {
      data: 'Data',
      free: 'Free',
      vaccine: 'Vaccine',
      virus: 'Virus',
    },
  },
  Components: {
    ListingSpeedDial: {
      favourite: 'Favourite',
      gridView: 'Grid View',
      search: 'Search',
      tableView: 'Table View',
    },
    LoginModal: {
      actions: {
        close: 'Close',
        login: 'Login',
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
    },
    PaginationFilters: {
      actions: {
        close: 'Close',
        reset: 'Reset',
      },
      search: {
        label: 'Search',
        placeholder: 'Search',
      },
    },
    Select: {
      all: 'All',
    },
    Slot: {
      emptyTitle: 'Empty',
    },
    UniversalSearch: {
      searchBox: {
        placeholder: 'Search by name, type or attributes',
      },
      sections: {
        categories: {
          chips: {
            all: 'All',
            digimon: 'Digimon',
            items: 'Items',
            locations: 'Locations',
            moves: 'Moves',
            roster: 'Roster',
            team: 'Teams',
          },
          title: 'Categories',
        },
        items: {
          resultsTotal: 'See all ({{total}} results)',
          seeAll: 'See all results',
          title: 'Items',
        },
        recent: {
          clearHistory: 'Clear previous searches',
          title: 'Recent searches',
        },
      },
    },
  },
  FieldGuide: {
    Digimon: {
      moveType: {
        magic: 'Magic',
        physical: 'Physical',
        support: 'Support',
      },
      stage: {
        armor: 'Armor',
        champion: 'Champion',
        mega: 'Mega',
        rookie: 'Rookie',
        trainingLower: 'Training Lower',
        trainingUpper: 'Training Upper',
        ultimate: 'Ultimate',
        ultra: 'Ultra',
      },
      type: {
        data: 'Data',
        free: 'Free',
        vaccine: 'Vaccine',
        virus: 'Virus',
      },
    },
    Items: {
      consumable: 'Consumable',
      equipment: 'Equipment',
      keyItem: 'Key Item',
      medal: 'Medal',
    },
    attribute: {
      dark: 'Dark',
      earth: 'Earth',
      electric: 'Electric',
      fire: 'Fire',
      light: 'Light',
      neutral: 'Neutral',
      plant: 'Plant',
      water: 'Water',
      wind: 'Wind',
    },
    chapters: {
      chapterEight: 'Chapter 8',
      chapterEighteen: 'Chapter 18',
      chapterEleven: 'Chapter 11',
      chapterFifteen: 'Chapter 15',
      chapterFive: 'Chapter 5',
      chapterFour: 'Chapter 4',
      chapterFourteen: 'Chapter 14',
      chapterNine: 'Chapter 9',
      chapterNineteen: 'Chapter 19',
      chapterOne: 'Chapter 1',
      chapterSeven: 'Chapter 7',
      chapterSeventeen: 'Chapter 17',
      chapterSix: 'Chapter 6',
      chapterSixteen: 'Chapter 16',
      chapterTen: 'Chapter 10',
      chapterThirteen: 'Chapter 13',
      chapterThree: 'Chapter 3',
      chapterTwelve: 'Chapter 12',
      chapterTwenty: 'Chapter 20',
      chapterTwo: 'Chapter 2',
      postGame: 'Post Game',
    },
  },
  Navigation: {
    Drawer: {
      digilab: {
        name: 'Digilab',
        roster: 'Roster',
        teams: 'My Teams',
      },
      fieldGuide: {
        digimon: 'Digimon',
        items: 'Items',
        locations: 'Locations',
        moves: 'Moves',
        name: 'Field Guide',
      },
      home: 'Home',
      profile: {
        name: 'Profile',
        settings: 'Settings',
      },
    },
  },
  Pages: {
    About: {
      breadcrumb: 'About',
      credits: {
        assets:
          'Assets such as images and icons were ripped from Digimon Story Cyber Sleuth: Complete Edition and owned by BANDAI NAMCO Entertainment Inc.',
        bandai:
          'Digimon Story: Cyber Sleuth, Digimon Story: Cyber Sleuth – Hacker’s Memory and Digimon Story Cyber Sleuth: Complete Edition are trademarked or copyrighted by ©2019 BANDAI NAMCO Entertainment Inc.',
        data: 'The data used here is a combination pulled from the game and online resources like https://www.grindosaur.com/en',
        title: 'Credits',
      },
      overview: {
        title: 'Overview',
      },
      title: 'About',
    },
    Digilab: {
      DigimonTeamMember: {
        about: {
          accordion: {
            roundUp: {
              ability: 'Ability',
              attribute: 'Attribute',
              camaradarie: 'Camaradarie',
              equipmentSlots: 'Equipment Slots',
              memoryUsage: 'Memory Usage',
              personality: 'Personality',
              power: 'Power',
              spCost: 'SP Cost',
              title: 'Round up',
              type: 'Type',
            },
            stats: {
              title: 'Stats',
            },
          },
          stats: {
            title: 'Stats',
          },
        },
        contextMenu: {
          accessibilityLabel: 'Configure your digimon',
          iosLabel: 'More',
          options: {
            clone: 'Clone',
            delete: 'Delete',
            edit: 'Edit',
          },
        },
        digivolution: {
          buttons: {
            deDigivolve: 'De-digivolve',
            digivolve: 'Digivolve',
          },
          history: 'History',
        },
        equipment: {
          modals: {
            add: {
              buttons: {
                cancel: 'Cancel',
                equipItem: 'Equip item',
              },
              description: 'Add a piece of equipment to {{digimon}}',
              effects: {
                title: 'Effects',
              },
              title: 'Equip item',
            },
          },
          slot: {
            menu: {
              accessibility: 'Change your equipment',
              edit: 'Edit',
              remove: 'Remove equipment',
              view: 'View',
            },
          },
        },
        moves: {
          slot: {
            menu: {
              accessibility: 'Change your moves',
              edit: 'Edit',
              remove: 'Remove move',
              view: 'View',
            },
          },
        },
        tabs: {
          about: 'About',
          battleHistory: 'Battle History',
          digivolution: 'Digivolutions',
          equipment: 'Equipment',
          moves: 'Moves',
        },
      },
      Roster: {
        add: 'Add',
        breadcrumb: 'Roster',
        digivolutions: {
          buttons: {
            dedigivolve: 'De-digivolve',
            digivolve: 'Digivolve',
          },
          history: {
            title: 'Previous digivolutions',
          },
          modals: {
            dedigivolve: {
              buttons: {
                cancel: 'Cancel',
                save: 'De-digivolve',
              },
              fields: {
                target: 'De-digivolve to',
              },
              title: 'De-digivolve {{digimon}}',
            },
            digivolve: {
              buttons: {
                cancel: 'Cancel',
                save: 'Digivolve',
              },
              fields: {
                target: 'Digivolve to',
              },
              title: 'Digivolve {{digimon}}',
            },
          },
          potential: {
            conditionsTooltip: {
              conditions: {
                ability: 'Ability',
                attack: 'Attack',
                camaradarie: 'Camaradarie',
                defence: 'Defence',
                hp: 'HP',
                intelligence: 'Intelligence',
                level: 'Level',
                sp: 'SP',
                speed: 'Speed',
              },
              conditionsMet: 'Can digivolve to {{target}}',
              conditionsNotMet: 'Can not digivolve to {{target}}',
            },
            title: 'Potential digivolutions',
          },
        },
        meta: {
          level: 'Level: {{level}}',
          modal: {
            buttons: {
              cancel: 'Cancel',
              save: 'Update',
            },
            fields: {
              exp: 'Experience',
              level: 'Level',
              nickname: 'Nickname',
            },
            title: 'Update Roster',
          },
        },
        moves: {
          buttons: {
            setMoves: 'Update moves',
          },
          edit: {
            modal: {
              buttons: {
                cancel: 'Cancel',
                save: 'Set Moves',
              },
              description: "Set {{digimon}}'s moves in bulk",
              title: 'Change moves',
            },
            transferList: {
              add: {
                subtitle: '{{count}}/{{maxCount}} moves selected',
                title: 'Available moves',
              },
              remove: {
                subtitle: '{{count}}/{{maxCount}} moves added',
                title: 'Chosen moves',
              },
              search: 'Moves',
              secondaryLabel: {
                attribute: {
                  noPower: '{{type}}, {{attribute}}, Cost: {{cost}}',
                  power:
                    '{{type}}, {{attribute}}, Power: {{power}}, Cost: {{cost}}',
                },
                noAttribute: {
                  noPower: '{{type}}, Cost: {{cost}}',
                  power: '{{type}}, Power: {{power}}, Cost: {{cost}}',
                },
              },
            },
          },
        },
        slot: {
          menu: {
            accessibility: 'Change your roster member',
            clone: 'Clone',
            delete: 'Delete',
            edit: 'Edit',
            view: 'View',
          },
        },
        stats: {
          ability: 'Ability',
          attack: 'Attack',
          camaradarie: 'Camaradarie',
          defence: 'Defence',
          hp: 'HP',
          intelligence: 'Intelligence',
          modal: {
            buttons: {
              cancel: 'Cancel',
              save: 'Update',
            },
            form: {
              baseStat: 'Base Stat',
              increasedByTraining: 'Increased by Training',
            },
            title: 'Update Stats',
          },
          personality: {
            brainy: {
              description: 'Increases intelligence',
              label: 'Brainy',
            },
            builder: {
              description: 'Reduces item development time',
              label: 'Builder',
            },
            defender: {
              description: 'Increases defence',
              label: 'Defender',
            },
            durable: {
              description: 'Increases HP',
              label: 'Durable',
            },
            fighter: {
              description: 'Increases attack',
              label: 'Fighter',
            },
            label: 'Personality',
            lively: {
              description: 'Increases SP',
              label: 'Lively',
            },
            nimble: {
              description: 'Increases speed',
              label: 'Nimble',
            },
            searcher: {
              description: 'Reduces investigation time',
              label: 'Searcher',
            },
          },
          sp: 'SP',
          speed: 'Speed',
        },
        title: 'Roster',
      },
      RosterListing: {
        modals: {
          add: {
            buttons: {
              cancel: 'Cancel',
              save: 'Add to roster',
            },
            description: 'Add a digimon to your roster',
            fields: {
              digimon: 'Digimon',
              name: 'Name',
              personality: 'Personality',
            },
          },
        },
        speedDial: {
          add: 'Add to roster',
        },
        tableListing: {
          headings: {
            attribute: 'Attribute',
            icon: 'Icon',
            level: 'Level',
            memoryUsage: 'Memory usage',
            name: 'Name',
            number: 'No.',
            personality: 'Personality',
            stage: 'Stage',
            type: 'Type',
          },
        },
      },
      Team: {
        activeTeamLabel: 'Active',
        analysis: {
          defenceTable: {
            headings: {
              attribute: 'Attribute',
              totalStrengths: 'Total Strengths',
              totalWeaknesses: 'Total Weaknesses',
              type: 'Type',
            },
            legend: {},
            title: 'Defensive coverage',
          },
          statTable: {
            headings: {
              digimonTotals: 'Total per Digimon',
              stats: 'Stats',
              totals: 'Totals',
            },
            title: 'Team stats',
          },
        },
        battleHistory: {
          listingTable: {
            battleTypes: {
              friendly: 'Friendly',
              recorded: 'Recorded',
            },
            headings: {
              enemies: 'Enemies',
              enemyTeam: 'Enemy Team',
              enemyTrainer: 'Enemy Trainer',
              name: 'Name',
              participants: 'Participants',
              team: 'Team',
              type: 'Type',
            },
          },
        },
        breadcrumb: 'Team',
        memoryUsed: 'Memory used: {{used}} out of {{total}}',
        modals: {
          add: {
            buttons: {
              cancel: 'Cancel',
              save: 'Add to team',
            },
            description: 'Add a roster member to the team',
            effects: {
              title: 'Effects',
            },
            title: 'Add member to team',
          },
        },
        reservesTeamLabel: 'Reserve',
        slot: {
          menu: {
            accessibility: 'Change your team member',
            edit: 'Edit',
            move: {
              moveDown: 'Move down',
              moveToActive: 'Move to active',
              moveToReserve: 'Move to reserves',
              moveUp: 'Move up',
              title: 'Move',
            },
            removeFromTeam: 'Remove from Team',
            view: 'View',
          },
        },
        tabs: {
          analysis: 'Analysis',
          battleHistory: 'Battle History',
          roster: 'Roster',
        },
        title: 'Team',
      },
      Teams: {
        breadcrumb: 'Teams',
        modals: {
          add: {
            buttons: {
              cancel: 'Cancel',
              save: 'Create',
            },
            description: 'Create a team from yoour current roster',
            fields: {
              digimon: 'Digimon',
              memoryAvailable: 'Memory available',
              name: 'Name',
            },
            title: 'Create team',
          },
        },
        speedDial: {
          add: 'Create team',
        },
        tableListing: {
          headings: {
            activeMembers: 'Active Members',
            memoryUsage: 'Memory Usage',
            name: 'Name',
            reserveMembers: 'Reserve Members',
          },
        },
        title: 'Teams',
      },
      breadcrumb: 'Digilab',
    },
    FieldGuide: {
      Charts: {
        breadcrumb: 'Charts',
        charts: {
          attributes: {
            axis: {
              attacker: 'Attacker',
              defender: 'Defender',
            },
          },
        },
        title: 'Field Guide - Charts',
      },
      Digimon: {
        about: {
          drops: {
            exp: 'Exp',
            money: 'Money',
            title: 'Drops',
          },
          itemDrops: {
            category: 'Category',
            name: 'Name',
            title: 'Item Drops',
          },
          location: {
            availableAt: 'Available at',
            location: 'Location',
            title: 'Spawn Locations',
          },
          roundUp: {
            attribute: 'Attribute',
            equipmentSlots: 'Equipment Slots',
            memoryUsage: 'Memory Usage',
            title: 'Round up',
            type: 'Type',
          },
        },
        baseStats: {
          effectiveness: {
            attributeEffectiveness: 'Attribute Effectiveness',
            attributeEffectivenessOn:
              'The effectiveness of each attribute on {{target}}',
            noAttributeEffectiveness:
              'The {{value}} attribute has no strengths or weaknesses',
            noTypeEffectiveness:
              'The {{value}} type has no strengths or weaknesses',
            strongAgainst: 'Strong Against',
            typeEffectiveness: 'Type Effectiveness',
            typeEffectivenessOn: 'The effectiveness of each type on {{target}}',
            weakAgainst: 'Weak Against',
          },
          stats: {
            attack: 'Attack',
            defence: 'Defence',
            hp: 'HP',
            intelligence: 'Intelligence',
            sp: 'SP',
            speed: 'Speed',
          },
        },
        digivolution: {
          dialog: {
            close: 'Close',
          },
          digivolvesFrom: 'Digiolves From',
          digivolvesTo: 'Digivolves To',
          requirements: {
            ability: 'Ability',
            additionalConditions: 'Additional Conditions',
            attack: 'Attack',
            camaradarie: 'Camaradarie',
            defence: 'Defence',
            hp: 'HP',
            intelligence: 'Intelligence',
            level: 'Level',
            sp: 'SP',
            speed: 'Speed',
            title: 'Digivolution Requirements',
          },
        },
        tabs: {
          about: 'About',
          baseStats: 'Base Stats',
          digivolution: 'Digivolution',
          moves: 'Moves',
        },
      },
      DigimonListing: {
        breadcrumb: 'Digimon',
        paginationFilters: {
          attribute: {
            label: 'Attribute',
          },
          search: {
            label: 'Search',
            placeholder: 'Search digimon by name',
          },
          stage: {
            label: 'Stage',
          },
          type: {
            label: 'Type',
          },
        },
        speedDial: {
          clear: 'Clear',
          favourite: 'Favourite',
          search: 'Search',
        },
        tableListing: {
          headings: {
            attribute: 'Attribute',
            icon: 'Icon',
            memoryUsage: 'Memory usage',
            name: 'Name',
            number: 'No.',
            stage: 'Stage',
            type: 'Type',
          },
        },
        title: 'Field Guide - Digimon',
      },
      Items: {
        tabs: {
          about: 'About',
          areas: 'Areas',
          digimon: 'Digimon',
        },
      },
      ItemsListing: {
        breadcrumb: 'Items',
        listingItem: {
          price: 'Price',
          type: 'Type',
        },
        paginationFilters: {
          category: {
            label: 'Category',
          },
          search: {
            label: 'Search',
            placeholder: 'Search items by name',
          },
        },
        tableListing: {
          headings: {
            icon: 'Icon',
            name: 'Name',
            price: 'Price',
            type: 'Type',
          },
        },
        title: 'Field Guide - Items',
      },
      Locations: {
        tabs: {
          digimon: 'Digimon',
          items: 'Items',
          subLocations: 'Sub Areas',
        },
      },
      LocationsListing: {
        breadcrumb: 'Locations',
        listingItem: {
          availableAt: 'Available at',
        },
        paginationFilters: {
          parentsOnly: {
            label: 'Parents Only',
          },
          search: {
            label: 'Search',
            placeholder: 'Search locations by name',
          },
        },
        tableListing: {
          headings: {
            availableAt: 'Available at',
            name: 'Name',
          },
        },
        title: 'Field Guide - Locations',
      },
      Move: {
        about: {
          roundUp: {
            attribute: 'Attribute',
            power: 'Power',
            spCost: 'SP Cost',
            title: 'Round up',
            type: 'Type',
          },
        },
        tabs: {
          about: 'About',
          digimon: 'Digimon',
        },
      },
      MovesListing: {
        breadcrumb: 'Moves',
        listingItem: {
          levelAcquired: 'Level Acquired',
          power: 'Power',
          spUse: 'SP Use',
          type: 'Type',
        },
        paginationFilters: {
          attribute: {
            label: 'Attribute',
          },
          search: {
            label: 'Search',
            placeholder: 'Search moves by name',
          },
          type: {
            label: 'Type',
          },
        },
        speedDial: {
          clear: 'Clear',
          favourite: 'Favourite',
          search: 'Search',
        },
        tableListing: {
          headings: {
            attribute: 'Attribute',
            icon: 'Icon',
            name: 'Name',
            power: 'Power',
            spUsage: 'SP Usage',
            type: 'Type',
          },
        },
        title: 'Field Guide - Moves',
      },
      SupportSkillsListing: {
        breadcrumb: 'Support Skills',
        listingItem: {
          availableAt: 'Available at',
        },
        paginationFilters: {
          parentsOnly: {
            label: 'Parents Only',
          },
          search: {
            label: 'Search',
            placeholder: 'Search support skills by name',
          },
        },
        tableListing: {
          headings: {
            description: 'Description',
            name: 'Name',
          },
        },
        title: 'Field Guide - Support Skills',
      },
      breadcrumb: 'Field Guide',
      title: 'Field Guide',
    },
    Home: {
      breadcrumb: 'Home',
      title: 'Home',
    },
    Profile: {
      Account: {
        breadcrumb: 'Account',
        settings: {
          information: {
            description: 'Set my profile name and other details.',
            fields: {
              name: 'Name',
              username: 'Username',
            },
            title: 'About me',
          },
          theme: {
            accent: {
              buttons: {
                blue: 'Blue',
                green: 'Green',
                mauve: 'Mauve',
                purple: 'Purple',
                red: 'Red',
                yellow: 'Yellow',
              },
              title: 'Accent',
            },
            description: 'Change the accent colour and app theme.',
            theme: {
              buttons: {
                dark: 'Dark',
                light: 'Light',
                system: 'System',
              },
              title: 'Theme',
            },
            title: 'Accent & Theme',
          },
          title: 'Account settings',
        },
        title: 'Account',
      },
      Settings: {
        accent: {
          buttons: {
            blue: 'Blue',
            green: 'Green',
            mauve: 'Mauve',
            purple: 'Purple',
            red: 'Red',
            yellow: 'Yellow',
          },
          description: 'Set the accent colour throughout the app',
          title: 'Accent Colour',
        },
        breadcrumb: 'Settings',
        theme: {
          buttons: {
            dark: 'Dark',
            light: 'Light',
            system: 'System',
          },
          description: 'Set the app theme',
          title: 'Theme',
        },
        title: 'Settings',
      },
    },
  },
  Testing: {
    paragraph: 'This is a test paragraph for content',
    title: 'Testing',
  },
};

export default en;
