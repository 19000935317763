export const white = '#ffffff';
export const green = '#4FC1A6';
export const blue = '#58ABF6';
export const lightBlue = '#A1D0FA';
export const darkBlue = '#0f86f2';

export const red = '#FA6555';
export const yellow = '#F6C747';
export const purple = '#7C538C';
export const mauve = '#6C79DB';

export const blackFont = 'rgba(0, 0, 0, 0.87)';
export const black = '#000';

export const largeCornerRadius = '20px';

export const drawerWidth = 300;
