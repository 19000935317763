import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useGetMeQuery, useUpdateMeMutation } from 'Modules/Profile/Store';
import type { User } from 'Modules/Profile/Types';
import { setPage } from 'Modules/Shared';
import { largeCornerRadius, palette, themeContext, white } from 'Theme';
import { Translate, TranslateTypography, useTranslation } from 'Translation';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Info, Theme } from './blocks';

function Account() {
  const [hasRetried, setHasRetried] = useState<boolean>(false);
  const title = useTranslation('Pages.Profile.Account.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.title'), to: '/' },
    { label: title },
  ];

  const [updateProfile, { isLoading }] = useUpdateMeMutation();
  const userQuery = useGetMeQuery({});

  if (userQuery.isError && !hasRetried) {
    setTimeout(() => userQuery.refetch(), 10000);
    setHasRetried(true);
  }

  const user = userQuery.data as User;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  return (
    <Stack sx={{ p: 2 }}>
      <Paper
        elevation={3}
        sx={{ borderRadius: largeCornerRadius, mb: 4, p: 2 }}
      >
        <Stack>
          <Stack flexDirection="row">
            {user && (
              <>
                <Box
                  sx={{
                    borderRadius: '50px',
                    height: '100px',
                    overflow: 'hidden',
                    width: '100px',
                  }}
                  mr={2}
                >
                  <img height="100px" src={user.avatar} width="100px" />
                </Box>
                <Stack>
                  <Typography variant="h6">{user.name}</Typography>
                  <Typography>{user.email}</Typography>
                  <Typography>{user.type}</Typography>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Paper>

      <TranslateTypography
        path="Pages.Profile.Account.settings.title"
        sx={{ mb: 1 }}
      />

      <Theme />

      {user && <Info name={user.name} username={user.username} />}
    </Stack>
  );
}

export default Account;
