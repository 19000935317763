import { blackFont, white } from 'Theme';

import { Attribute } from '../Types';

function getAttributeFontColour(digimonAttribute?: Attribute): string {
  switch (digimonAttribute) {
    case Attribute.Dark:
      return white;

    case Attribute.Earth:
      return white;

    case Attribute.Electric:
      return blackFont;

    case Attribute.Fire:
      return white;

    case Attribute.Light:
      return blackFont;

    case Attribute.Neutral:
      return white;

    case Attribute.Plant:
      return white;

    case Attribute.Water:
      return white;

    case Attribute.Wind:
      return blackFont;

    default:
      return white;
  }
}

export default getAttributeFontColour;
