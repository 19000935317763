function getStatIncreasedByValue(increasedBy: number): string {
  if (increasedBy > 100) {
    return `(+ ${increasedBy})`;
  } else if (increasedBy > 10 && increasedBy < 100) {
    return `(+  ${increasedBy})`;
  }

  return `(+    ${increasedBy})`;
}

export default getStatIncreasedByValue;
