import { TableCell, TableRow } from '@mui/material';
import { MoveListingItemSkeleton } from 'Modules/FieldGuide/Components/MoveListingItem';
import SupportSkillListingItem from 'Modules/FieldGuide/Components/SupportSkillListingItem';
import { useGetSupportSkillsQuery } from 'Modules/FieldGuide/Store';
import type {
  SupportSkill,
  SupportSkillsPaginatedListFilter,
} from 'Modules/FieldGuide/Types';
import { NewListingView, TableLink, usePagination } from 'Modules/Shared';
import type { ListingEntities, SupportSkillsListingRTK } from 'Store';
import type { TranslationPath } from 'Translation';
import { useTranslation } from 'Translation';
import { useCallback, useMemo } from 'react';

function SupportSkillsListing(): JSX.Element {
  const { clearFilters, entities, isLoading, paginate } = usePagination<
    SupportSkillsListingRTK,
    SupportSkillsPaginatedListFilter
  >(useGetSupportSkillsQuery, 60);

  const title = useTranslation('Pages.FieldGuide.SupportSkillsListing.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    {
      label: useTranslation('Pages.FieldGuide.SupportSkillsListing.breadcrumb'),
    },
  ];

  const renderItem = useCallback((item: ListingEntities) => {
    const skill = item as SupportSkill;
    return <SupportSkillListingItem key={skill.id} skill={skill} />;
  }, []);

  const renderRow = useCallback((item: ListingEntities) => {
    const skill = item as SupportSkill;
    return (
      <TableRow key={skill.id}>
        <TableCell>
          <TableLink to={`/field-guide/support-skills/${skill.id}`}>
            {skill.name}
          </TableLink>
        </TableCell>
        <TableCell>{skill.description}</TableCell>
      </TableRow>
    );
  }, []);

  // const filters: PaginationFilter[] = useMemo(() => {
  //   return [
  //     {
  //       id: 'location-parents-only',
  //       label: 'Pages.FieldGuide.LocationsListing.paginationFilters.parentsOnly.label' as TranslationPath,
  //       type: PaginationFilterType.Switch,
  //       onChange: setParentsFilter,
  //       value: !!options.parents,
  //     }
  //   ];
  // }, [options.parents]);

  const searchProps = useMemo(
    () => ({
      id: 'support-skill-search',
      label:
        'Pages.FieldGuide.LocationsListing.paginationFilters.search.label' as TranslationPath,
      placeholder:
        'Pages.FieldGuide.LocationsListing.paginationFilters.search.placeholder' as TranslationPath,
    }),
    [],
  );

  return (
    <NewListingView
      entity="supportSkills"
      isLoading={isLoading}
      paginate={paginate}
      entities={entities}
      breadcrumbs={breadcrumbs}
      title={title}
      listingSkeletonComponent={<MoveListingItemSkeleton />}
      itemRender={renderItem}
      limit={40}
      searchProps={searchProps}
      clearFilters={clearFilters}
      rowRender={renderRow}
      headings={[
        {
          dataKey: 'name',
          label:
            'Pages.FieldGuide.SupportSkillsListing.tableListing.headings.name',
        },
        {
          dataKey: 'description',
          label:
            'Pages.FieldGuide.SupportSkillsListing.tableListing.headings.description',
        },
      ]}
    />
  );
}

export default SupportSkillsListing;
