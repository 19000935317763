import type { ThemeOptions } from '@mui/material';
import { Accent, Theme } from 'Theme/Types';

import { getAccent } from '.';
import { blue, green, mauve, purple, red, yellow } from '../colourPalette';
import { white } from '../constants';
import typography from '../typography';

function getAppTheme(
  accent: Accent = Accent.Blue,
  theme: Theme = Theme.Light,
): ThemeOptions {
  const primaryAccent = getAccent(accent);
  return {
    palette: {
      blue: {
        contrastText: white,
        dark: blue[900],
        light: blue[100],
        main: blue[500],
      },
      green: {
        contrastText: white,
        dark: green[900],
        light: green[100],
        main: green[500],
      },
      mauve: {
        contrastText: white,
        dark: mauve[900],
        light: mauve[100],
        main: mauve[500],
      },
      mode: theme,
      primary: {
        ...primaryAccent,
      },
      purple: {
        contrastText: white,
        dark: purple[900],
        light: purple[100],
        main: purple[500],
      },
      red: {
        contrastText: white,
        dark: red[900],
        light: red[100],
        main: red[500],
      },
      yellow: {
        contrastText: white,
        dark: yellow[900],
        light: yellow[100],
        main: yellow[500],
      },
    },
    typography: {
      ...typography,
    },
  } as ThemeOptions;
}

export default getAppTheme;
