import { PersistMethod } from '../Types';

function getData(
  key: string,
  method: PersistMethod,
): string | null | undefined {
  switch (method) {
    case PersistMethod.LocalStorage:
      return window.localStorage.getItem(key);

    case PersistMethod.SessionStorage:
      return window.sessionStorage.getItem(key);

    case PersistMethod.IndexedDB:
      return undefined;

    default:
      return undefined;
  }
}

function getPersistedObject<T>(
  key: string,
  method: PersistMethod = PersistMethod.LocalStorage,
): T | undefined {
  try {
    const stringifiedData = getData(key, method);

    if (!stringifiedData) {
      // console.log(`Local Persist: There is no data associated with ${key}`);
      return undefined;
    } else if (typeof stringifiedData !== 'string') {
      throw new Error('Not valid data');
    }

    const data = JSON.parse(stringifiedData);

    return data.data as T;
  } catch (error) {
    console.error(error);
  }
}

export default getPersistedObject;
