import type { Item, ItemsPaginatedListFilter } from 'Modules/FieldGuide';
import { ItemCategory, useGetItemsQuery } from 'Modules/FieldGuide';
import type { PaginationOptions } from 'Store';

interface Exports {
  items: Item[];
  isLoading: boolean;
  isSuccess: boolean;
}

function useGetEquipment(): Exports {
  const itemsRTK = useGetItemsQuery({
    category: ItemCategory.Equipment,
    limit: 200,
  } as PaginationOptions<ItemsPaginatedListFilter>);

  return {
    isLoading: itemsRTK.isLoading,
    isSuccess: itemsRTK.isSuccess,
    items: itemsRTK.data?.items ?? [],
  };
}

export default useGetEquipment;
