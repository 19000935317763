import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useGetActivityLogQuery } from 'Modules/Admin';
import { setPage } from 'Modules/Shared';
import { largeCornerRadius, theme, white } from 'Theme';
import { useTranslation } from 'Translation';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const styles = {
  button: {
    borderRadius: largeCornerRadius,
    color: white,
    fontWeight: 600,
    height: '75px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  fieldGuideButton: {
    '&:hover': {
      bgcolor: theme.palette.green.dark,
    },
    bgcolor: theme.palette.green.main,
  },
  itemsButton: {
    '&:hover': {
      bgcolor: theme.palette.yellow.dark,
    },
    bgcolor: theme.palette.yellow.main,
  },
  locationsButton: {
    '&:hover': {
      bgcolor: theme.palette.purple.dark,
    },
    bgcolor: theme.palette.purple.main,
  },
  movesButton: {
    '&:hover': {
      bgcolor: theme.palette.red.dark,
    },
    bgcolor: theme.palette.red.main,
  },
  newsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
};

function Landing(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    button,
    fieldGuideButton,
    movesButton,
    newsHeader,
    itemsButton,
    locationsButton,
  } = styles;
  const title = useTranslation('Pages.FieldGuide.title');
  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    { label: useTranslation('Pages.Digilab.breadcrumb') },
  ];

  const activityRTK = useGetActivityLogQuery({ limit: 200 }, { skip: false });

  useEffect(() => {
    dispatch(setPage({ breadcrumbs, title }));
  });

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={3} sx={{ borderRadius: largeCornerRadius, p: 2 }}>
        <Stack>
          <Typography
            component="h1"
            variant="h3"
            sx={{ fontWeight: 'bold', mb: 4 }}
          >
            What Digimon are you looking for?
          </Typography>
          <Box sx={{ mb: 2 }}>Seach goes here</Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} lg={2}>
              <Button
                sx={{ ...button, ...fieldGuideButton }}
                variant="contained"
                onClick={() => navigate('/digilab/roster')}
              >
                Digimon
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <Button
                sx={{ ...button, ...movesButton }}
                variant="contained"
                onClick={() => navigate('/digilab/teams')}
              >
                Teams
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Box sx={{ py: 2 }}>
        <Box sx={{ ...newsHeader }}>
          <Typography component="h2" variant="h4" sx={{ fontWeight: 'bold' }}>
            News
          </Typography>
          <Button
            variant="text"
            sx={{ alignSelf: 'flex-end', fontWeight: 'bold' }}
          >
            View All
          </Button>
        </Box>
        <Box>List</Box>
        <ul>
          {activityRTK.data?.items.map((el) => (
            <li key={el.id}>{`${el.activity}`}</li>
          ))}
        </ul>
      </Box>
    </Box>
  );
}

export default Landing;
