import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type {
  Digimon,
  DigimonPaginatedListFilter,
} from 'Modules/FieldGuide/Types';
import type { PaginatedList, PaginationOptions, StoreEntities } from 'Store';
import { api, providesList } from 'Store';
import urlcat from 'urlcat';

export type DigimonByIDQuery = {
  isLoading: boolean;
  isSuccess: boolean;
  data: Digimon;
};

export const digimonApiSlice = api.injectEndpoints({
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      StoreEntities,
      'api'
    >,
  ) => ({
    getDigimon: build.query<
      PaginatedList<Digimon>,
      PaginationOptions<DigimonPaginatedListFilter>
    >({
      providesTags: (result, error, arg) => providesList(result, 'Digimon'),
      query: (options = { limit: 40 }) => urlcat('/digimon', options),
    }),
    getDigimonByID: build.query<Digimon, string | number>({
      providesTags: (result, error, arg) => [{ id: arg, type: 'Digimon' }],
      query: (id) => `digimon/${id}`,
    }),
  }),
});

export const { useGetDigimonQuery, useGetDigimonByIDQuery } = digimonApiSlice;
