import { searchApiSlice, uiSlice } from './slices';

export { setPage, setAppBarStyling, toggleDrawer } from './slices/ui';

export const sharedSlices = {
  [searchApiSlice.reducerPath]: searchApiSlice.reducer,
  ui: uiSlice,
};

export { useSearchQuery } from './slices';
