/** @jsxImportSource @emotion/react */
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import type { Roster } from 'Modules/Digilab';
import {
  generateCreateRosterDTO,
  usePatchRosterMutation,
} from 'Modules/Digilab';
import {
  Translate,
  TranslateTypography,
  TranslationPath,
  useTranslation,
} from 'Translation';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface FormData {
  camaradarie: number;
  ability: number;
  attack: number;
  attackIncreasedBy: number;
  defence: number;
  defenceIncreasedBy: number;
  intelligence: number;
  intelligenceIncreasedBy: number;
  speed: number;
  speedIncreasedBy: number;
  sp: number;
  spIncreasedBy: number;
  hp: number;
  hpIncreasedBy: number;
}

function ChangeStatsModal({
  isOpen = false,
  onClose,
  roster,
}: {
  isOpen: boolean;
  onClose: () => void;
  roster: Roster;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [patchRoster, result] = usePatchRosterMutation();

  useEffect(() => {
    if (!result.isLoading) {
      if (result.isSuccess) {
        enqueueSnackbar('Stats updated', { variant: 'success' });
        onClose();
      } else if (result.isError) {
        enqueueSnackbar('Unable to update stats', { variant: 'error' });
      }
    }
  }, [result]);

  const statsMax: Record<string, number> = {
    ability: 0,
    attack: 0,
    attackIncreasedBy: 0,
    camaradarie: 0,
    defence: 0,
    defenceIncreasedBy: 0,
    hp: 0,
    hpIncreasedBy: 0,
    intelligence: 0,
    intelligenceIncreasedBy: 0,
    sp: 0,
    spIncreasedBy: 0,
    speed: 0,
    speedIncreasedBy: 0,
  };

  const stats: Record<string, number> = {
    ability: 0,
    attack: 0,
    attackIncreasedBy: 0,
    camaradarie: 0,
    defence: 0,
    defenceIncreasedBy: 0,
    hp: 0,
    hpIncreasedBy: 0,
    intelligence: 0,
    intelligenceIncreasedBy: 0,
    sp: 0,
    spIncreasedBy: 0,
    speed: 0,
    speedIncreasedBy: 0,
  };

  roster.stats.forEach((stat) => {
    switch (stat.name) {
      case 'camaradarie':
        stats.camaradarie = stat.value;
        statsMax.camaradarie = stat.maxValue;

        break;

      case 'ability':
        stats.ability = stat.value;
        statsMax.ability = stat.maxValue;

        break;

      default: {
        const incKey = `${stat.name}IncreasedBy`;
        stats[incKey] = stat.increasedBy;
        stats[stat.name] = stat.value;
        statsMax[stat.name] = stat.maxValue;

        break;
      }
    }
  });

  const { control, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      ...stats,
    },
  });

  const watchedAbilityStat = watch('ability', 0);
  const watchedHpIncreasedByStat = watch('hpIncreasedBy', 0);
  const increasedBy = watch([
    'attackIncreasedBy',
    'defenceIncreasedBy',
    'intelligenceIncreasedBy',
    'speedIncreasedBy',
    'spIncreasedBy',
  ]);

  const availablePoints = useMemo(() => {
    const spent =
      increasedBy.reduce(
        (prevValue, currentValue) => prevValue + currentValue,
      ) +
      watchedHpIncreasedByStat / 10;

    return watchedAbilityStat - spent;
  }, [increasedBy, watchedAbilityStat, watchedHpIncreasedByStat]);

  const onSubmit = async (data: any): Promise<void> => {
    const newStats = roster.stats.map((stat) => {
      switch (stat.name) {
        case 'camaradarie':
          return { ...stat, value: data.camaradarie };

        case 'ability':
          return { ...stat, value: data.ability };

        default:
          return {
            ...stat,
            increasedBy: data[`${stat.name}IncreasedBy`],
            value: data[stat.name],
          };
      }
    });

    const res = await patchRoster({
      id: roster.id,
      stats: newStats,
    });

    console.log(res);
  };

  useEffect(
    () => () => {
      console.log('wow', result);
      if (result.isSuccess) {
        reset();
        result.reset();
      }
    },
    [reset, result],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {useTranslation('Pages.Digilab.Roster.stats.modal.title')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2} spacing={2}>
            <Controller
              name="ability"
              control={control}
              render={({ field }): JSX.Element => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="ability" shrink={true}>
                    <Translate path="Pages.Digilab.Roster.stats.ability" />
                  </InputLabel>
                  <TextField
                    id="ability"
                    label={
                      <Translate path="Pages.Digilab.Roster.stats.ability" />
                    }
                    {...field}
                    InputProps={{ inputProps: { max: 200, min: 0 } }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setValue('ability', Number(e.target.value))
                    }
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              )}
            />
            <Controller
              name="camaradarie"
              control={control}
              render={({ field }): JSX.Element => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="camaradarie" shrink={true}>
                    <Translate path="Pages.Digilab.Roster.stats.camaradarie" />
                  </InputLabel>
                  <TextField
                    id="camaradarie"
                    label={
                      <Translate path="Pages.Digilab.Roster.stats.camaradarie" />
                    }
                    {...field}
                    InputProps={{ inputProps: { max: 100, min: 0 } }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setValue('camaradarie', Number(e.target.value))
                    }
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              )}
            />

            <Divider sx={{ marginTop: '36px !important' }} />

            <Grid container spacing={4} width="100%">
              <Grid item xs={12} sm={6} sx={{ paddingLeft: '0px !important' }}>
                <Stack flexDirection="row" alignItems="center">
                  <TranslateTypography
                    sx={{ marginRight: '20px', width: '120px' }}
                    path="Pages.Digilab.Roster.stats.attack"
                  />
                  <Controller
                    name="attack"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="attackBaseStat" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                        </InputLabel>
                        <TextField
                          id="attackBaseStat"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: statsMax.attack, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void => setValue('attack', Number(e.target.value))}
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack flexDirection="row" alignItems="center">
                  <Controller
                    name="attackIncreasedBy"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="attackIncreasedBy" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                        </InputLabel>
                        <TextField
                          id="attackIncreasedBy"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: availablePoints, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue(
                              'attackIncreasedBy',
                              Number(e.target.value),
                            )
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingLeft: '0px !important' }}>
                <Stack flexDirection="row" alignItems="center">
                  <TranslateTypography
                    sx={{ marginRight: '20px', width: '120px' }}
                    path="Pages.Digilab.Roster.stats.intelligence"
                  />
                  <Controller
                    name="intelligence"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="intelligenceBaseStat"
                          shrink={true}
                        >
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                        </InputLabel>
                        <TextField
                          id="intelligenceBaseStat"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: statsMax.intelligence, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue('intelligence', Number(e.target.value))
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack flexDirection="row" alignItems="center">
                  <Controller
                    name="intelligenceIncreasedBy"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="intelligenceIncreasedBy"
                          shrink={true}
                        >
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                        </InputLabel>
                        <TextField
                          id="intelligenceIncreasedBy"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: availablePoints, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue(
                              'intelligenceIncreasedBy',
                              Number(e.target.value),
                            )
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ paddingLeft: '0px !important' }}>
                <Stack flexDirection="row" alignItems="center">
                  <TranslateTypography
                    sx={{ marginRight: '20px', width: '120px' }}
                    path="Pages.Digilab.Roster.stats.speed"
                  />
                  <Controller
                    name="speed"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="speedBaseStat" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                        </InputLabel>
                        <TextField
                          id="speedBaseStat"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: statsMax.speed, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void => setValue('speed', Number(e.target.value))}
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack flexDirection="row" alignItems="center">
                  <Controller
                    name="speedIncreasedBy"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="attackIncreasedBy" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                        </InputLabel>
                        <TextField
                          id="speedIncreasedBy"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: availablePoints, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue('speedIncreasedBy', Number(e.target.value))
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingLeft: '0px !important' }}>
                <Stack flexDirection="row" alignItems="center">
                  <TranslateTypography
                    sx={{ marginRight: '20px', width: '120px' }}
                    path="Pages.Digilab.Roster.stats.defence"
                  />
                  <Controller
                    name="defence"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="defenceBaseStat" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                        </InputLabel>
                        <TextField
                          id="defenceBaseStat"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: statsMax.defence, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue('defence', Number(e.target.value))
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack flexDirection="row" alignItems="center">
                  <Controller
                    name="defenceIncreasedBy"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="defenceIncreasedBy" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                        </InputLabel>
                        <TextField
                          id="defenceIncreasedBy"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: availablePoints, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue(
                              'defenceIncreasedBy',
                              Number(e.target.value),
                            )
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ paddingLeft: '0px !important' }}>
                <Stack flexDirection="row" alignItems="center">
                  <TranslateTypography
                    sx={{ marginRight: '20px', width: '120px' }}
                    path="Pages.Digilab.Roster.stats.hp"
                  />
                  <Controller
                    name="hp"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="hpBaseStat" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                        </InputLabel>
                        <TextField
                          id="hpBaseStat"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: statsMax.hp, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void => setValue('hp', Number(e.target.value))}
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack flexDirection="row" alignItems="center">
                  <Controller
                    name="hpIncreasedBy"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="hpIncreasedBy" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                        </InputLabel>
                        <TextField
                          id="hpIncreasedBy"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: availablePoints * 10, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue('hpIncreasedBy', Number(e.target.value))
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingLeft: '0px !important' }}>
                <Stack flexDirection="row" alignItems="center">
                  <TranslateTypography
                    sx={{ marginRight: '20px', width: '120px' }}
                    path="Pages.Digilab.Roster.stats.sp"
                  />
                  <Controller
                    name="sp"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="spBaseStat" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                        </InputLabel>
                        <TextField
                          id="spBaseStat"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.baseStat" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: statsMax.sp, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void => setValue('sp', Number(e.target.value))}
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack flexDirection="row" alignItems="center">
                  <Controller
                    name="spIncreasedBy"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="spIncreasedBy" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                        </InputLabel>
                        <TextField
                          id="spIncreasedBy"
                          label={
                            <Translate path="Pages.Digilab.Roster.stats.modal.form.increasedByTraining" />
                          }
                          {...field}
                          InputProps={{
                            inputProps: { max: availablePoints, min: 0 },
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ): void =>
                            setValue('spIncreasedBy', Number(e.target.value))
                          }
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation('Pages.Digilab.Roster.stats.modal.buttons.cancel')}
        </Button>
        <LoadingButton
          loading={result.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {useTranslation('Pages.Digilab.Roster.stats.modal.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeStatsModal;
