/** @jsxImportSource @emotion/react */
import { ArrowForward } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import type { Roster, RosterMove } from 'Modules/Digilab';
import { usePatchRosterMutation } from 'Modules/Digilab';
import type { Digimon, DigimonMove } from 'Modules/FieldGuide';
import { getAttributeLabel, getMoveTypeLabel } from 'Modules/FieldGuide';
import type { ComboBoxOption } from 'Modules/Shared';
import { ComboBox, TransferList, getUniqueOptions } from 'Modules/Shared';
import { Translate, useTranslation } from 'Translation';
import i18n from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface FormData {
  camaradarie: number;
  ability: number;
  attack: number;
  attackIncreasedBy: number;
  defence: number;
  defenceIncreasedBy: number;
  intelligence: number;
  intelligenceIncreasedBy: number;
  speed: number;
  speedIncreasedBy: number;
  sp: number;
  spIncreasedBy: number;
  hp: number;
  hpIncreasedBy: number;
}

export enum DigivolutionModalMode {
  Digivolve,
  Dedigivolve,
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  roster: Roster;
  mode?: DigivolutionModalMode;
  digivolveTo?: number;
}

function getMoveSecondaryLabel(move: DigimonMove): string {
  if (move.attribute) {
    if (move.power) {
      return i18n.t(
        'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.attribute.power',
        {
          attribute: i18n.t(getAttributeLabel(move.attribute)),
          cost: move.spCost,
          power: move.power,
          type: i18n.t(getMoveTypeLabel(move.type)),
        },
      );
    }

    return i18n.t(
      'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.attribute.noPower',
      {
        attribute: i18n.t(getAttributeLabel(move.attribute)),
        cost: move.spCost,
        type: i18n.t(getMoveTypeLabel(move.type)),
      },
    );
  } else {
    if (move.power) {
      return i18n.t(
        'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.noAttribute.power',
        {
          cost: move.spCost,
          power: move.power,
          type: i18n.t(getMoveTypeLabel(move.type)),
        },
      );
    }

    return i18n.t(
      'Pages.Digilab.Roster.moves.edit.transferList.secondaryLabel.noAttribute.noPower',
      { cost: move.spCost, type: i18n.t(getMoveTypeLabel(move.type)) },
    );
  }
}

function generateMoveComboBoxOption(move: DigimonMove): ComboBoxOption {
  console.log(move);
  return {
    disabled: move.signature,
    label: move.name,
    secondaryLabel: getMoveSecondaryLabel(move),
    value: move.id.toString(),
  };
}

function generateAvailableMoveComboBoxOption(move: RosterMove): ComboBoxOption {
  const dm = move.move as DigimonMove;

  return {
    disabled: dm.signature === true,
    id: move.id,
    label: dm.name,
    secondaryLabel: getMoveSecondaryLabel(dm),
    value: dm.id.toString(),
  };
}

const DigivolutionModal: React.FC<Props> = ({
  onClose,
  roster,
  digivolveTo = 0,
  isOpen = false,
  mode = DigivolutionModalMode.Digivolve,
}) => {
  const [patchRoster, result] = usePatchRosterMutation();
  const [movesToSelect, setMovesToSelect] = useState<ComboBoxOption[]>([]);
  const [currentMoves, setCurrentMoves] = useState<ComboBoxOption[]>([]);

  const isDigivolve = useMemo(
    () => mode === DigivolutionModalMode.Digivolve,
    [mode],
  );

  const { control, setValue, handleSubmit, reset, watch, register } = useForm({
    defaultValues: {
      nickname: roster.nickname,
      target: digivolveTo,
    },
  });

  const watchedTarget = watch('target', digivolveTo);

  const updateLists = useCallback(
    (items: ComboBoxOption[], movedRight: boolean) => {
      const itms: string[] = items.map((el) => el.label);

      if (movedRight) {
        const availableMoves = movesToSelect.filter((el, index) => {
          if (!itms.includes(el.label)) {
            return el;
          }
        });

        const selectedMoves: ComboBoxOption[] = [...currentMoves, ...items];

        patchRoster({
          id: roster.id,
          moves: selectedMoves.map((el, index) => ({
            id: el.id,
            move: Number(el.value),
            number: index + 1,
          })),
        });

        setMovesToSelect(availableMoves);
        setCurrentMoves(selectedMoves);
      } else {
        const availableMoves: ComboBoxOption[] = [...movesToSelect, ...items];

        const selectedMoves = currentMoves.filter((el, index) => {
          if (!itms.includes(el.label)) {
            return el;
          }
        });

        patchRoster({
          id: roster.id,
          moves: selectedMoves.map((el, index) => ({
            id: el.id,
            move: Number(el.value),
            number: index + 1,
          })),
        });

        setMovesToSelect(
          availableMoves.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }

            if (a.label > b.label) {
              return 1;
            }

            return 0;
          }),
        );

        setCurrentMoves(selectedMoves);
      }
    },
    [movesToSelect, currentMoves],
  );

  const options: ComboBoxOption[] = useMemo(() => {
    const digimon = roster.digimon as Digimon;

    if (isDigivolve && digimon.digivolutionPotential) {
      return digimon.digivolutionPotential.map((option) => ({
        label: option.name,
        value: `${option.id}`,
      }));
    } else if (!isDigivolve && digimon.digivolutionHistory) {
      return digimon.digivolutionHistory.map((option) => ({
        label: option.name,
        value: `${option.id}`,
      }));
    }

    return [];
  }, [roster, isDigivolve]);

  const selectedTarget: Digimon | undefined = useMemo(() => {
    const digimon = roster.digimon as Digimon;
    let target: Digimon | undefined = undefined;

    if (watchedTarget !== 0) {
      if (isDigivolve && digimon.digivolutionPotential) {
        const id = Number(watchedTarget);

        digimon.digivolutionPotential.forEach((option) => {
          if (id === option.id) {
            target = option;
          }
        });
      } else if (!isDigivolve && digimon.digivolutionHistory) {
        const id = Number(watchedTarget);

        digimon.digivolutionHistory.forEach((option) => {
          if (id === option.id) {
            target = option;
          }
        });
      }
    }

    return target;
  }, [roster, watchedTarget, isDigivolve]) as Digimon | undefined;

  useEffect(() => {
    if (roster.moves && selectedTarget && selectedTarget.moves) {
      const newMoves: ComboBoxOption[] = [];
      const existingMoves: ComboBoxOption[] = [];

      selectedTarget.moves.forEach((move) => {
        if (move.signature) {
          newMoves.push(generateMoveComboBoxOption(move));
        } else {
          existingMoves.push(generateMoveComboBoxOption(move));
        }
      });

      roster.moves.forEach((move) => {
        if (move.move.signature === false) {
          existingMoves.push(generateAvailableMoveComboBoxOption(move));
        }
      });

      if (existingMoves.length + newMoves.length <= 20) {
        existingMoves.forEach((move, index) => {
          newMoves.push({
            ...move,
          });
        });

        setCurrentMoves(getUniqueOptions(newMoves));
        setMovesToSelect([]);
      } else {
        setCurrentMoves(newMoves);
        setMovesToSelect(existingMoves);
      }
    }

    if (roster.nickname === roster.digimon.name && selectedTarget) {
      setValue('nickname', selectedTarget.name);
    }
  }, [roster, selectedTarget, setValue]);

  const onSubmit = (data: any) => {
    const statsMap: Record<string, any> = {
      ability: 0,
      attack: {
        increasedBy: 0,
        maxValue: 0,
        value: 0,
      },
      camaradarie: 0,
      defence: {
        increasedBy: 0,
        maxValue: 0,
        value: 0,
      },
      hp: {
        increasedBy: 0,
        maxValue: 0,
        value: 0,
      },
      intelligence: {
        increasedBy: 0,
        maxValue: 0,
        value: 0,
      },
      sp: {
        increasedBy: 0,
        maxValue: 0,
        value: 0,
      },
      speed: {
        increasedBy: 0,
        maxValue: 0,
        value: 0,
      },
    };

    roster.stats.forEach((stat) => {
      switch (stat.name) {
        case 'camaradarie':
          statsMap.camaradarie = stat.value;
          break;

        case 'ability':
          statsMap.ability = stat.value;
          break;

        default:
          statsMap[stat.name].increasedBy = stat.increasedBy;
          break;
      }
    });

    selectedTarget?.stats.forEach((stat) => {
      statsMap[stat.name].value = stat.value;
      statsMap[stat.name].maxValue = stat.maxValue;
    });

    const newStats = roster.stats.map((stat) => {
      switch (stat.name) {
        case 'camaradarie':
          return {
            ...stat,
            increasedBy: 0,
            maxValue: 100,
            value: statsMap.camaradarie,
          };

        case 'ability':
          return {
            ...stat,
            increasedBy: 0,
            maxValue: 200,
            value: statsMap.ability,
          };

        default:
          return {
            ...stat,
            increasedBy: statsMap[stat.name].increasedBy,
            maxValue: statsMap[stat.name].maxValue,
            value: statsMap[stat.name].value,
          };
      }
    });

    const moves = currentMoves.map((move, index) => ({
      id: move.id,
      move: Number(move.value),
      number: index + 1,
    }));

    const digivolved = {
      digimon: data.target,
      id: roster.id,
      level: 1,
      moves,
      nickname: data.nickname,
      stats: newStats,
    };

    patchRoster(digivolved);
  };

  useEffect(
    () => () => {
      if (result.isSuccess) {
        reset();
        result.reset();
      }
    },
    [reset, result],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        {useTranslation(
          isDigivolve
            ? 'Pages.Digilab.Roster.digivolutions.modals.digivolve.title'
            : 'Pages.Digilab.Roster.digivolutions.modals.dedigivolve.title',
          { digimon: roster.nickname },
        )}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2} spacing={2}>
            <ComboBox
              label={
                isDigivolve
                  ? 'Pages.Digilab.Roster.digivolutions.modals.digivolve.fields.target'
                  : 'Pages.Digilab.Roster.digivolutions.modals.dedigivolve.fields.target'
              }
              options={options}
              value={`${watchedTarget ?? ''}`}
              onChange={(e, newValue) => {
                if (newValue) {
                  setValue('target', Number(newValue.value), {
                    shouldValidate: true,
                  });
                } else if (selectedTarget) {
                  setValue('target', 0, { shouldValidate: true });
                }
              }}
            />
            {selectedTarget && (
              <>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack>
                    <img alt={roster.digimon.name} src={roster.digimon.image} />
                    <Typography>{roster.digimon.name}</Typography>
                  </Stack>
                  <ArrowForward />
                  <Stack>
                    <img alt={selectedTarget.name} src={selectedTarget.image} />
                    <Typography>{selectedTarget.name}</Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection="row">
                  <Controller
                    name="nickname"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel htmlFor="nickname" shrink={true}>
                          <Translate path="Pages.Digilab.Roster.meta.modal.fields.nickname" />
                        </InputLabel>
                        <TextField
                          id="nickname"
                          label={
                            <Translate path="Pages.Digilab.Roster.meta.modal.fields.nickname" />
                          }
                          {...field}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setValue('nickname', e.target.value)
                          }
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
                <TransferList
                  updateLists={updateLists}
                  leftColumn={{
                    items: movesToSelect,
                    searchLabel:
                      'Pages.Digilab.Roster.moves.edit.transferList.search',
                    subtitle:
                      'Pages.Digilab.Roster.moves.edit.transferList.add.subtitle',
                    title:
                      'Pages.Digilab.Roster.moves.edit.transferList.add.title',
                  }}
                  rightColumn={{
                    items: currentMoves,
                    maxCount: 20,
                    searchLabel:
                      'Pages.Digilab.Roster.moves.edit.transferList.search',
                    subtitle:
                      'Pages.Digilab.Roster.moves.edit.transferList.remove.subtitle',
                    title:
                      'Pages.Digilab.Roster.moves.edit.transferList.remove.title',
                  }}
                />
              </>
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation(
            isDigivolve
              ? 'Pages.Digilab.Roster.digivolutions.modals.digivolve.buttons.cancel'
              : 'Pages.Digilab.Roster.digivolutions.modals.dedigivolve.buttons.cancel',
          )}
        </Button>
        <LoadingButton
          loading={result.isLoading}
          disabled={watchedTarget === 0}
          onClick={handleSubmit(onSubmit)}
        >
          {useTranslation(
            isDigivolve
              ? 'Pages.Digilab.Roster.digivolutions.modals.digivolve.buttons.save'
              : 'Pages.Digilab.Roster.digivolutions.modals.dedigivolve.buttons.save',
          )}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DigivolutionModal;
