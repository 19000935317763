/** @jsxImportSource @emotion/react */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import type { SupportSkill } from 'Modules/FieldGuide/Types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

const SupportSkillLisitingItem: React.FC<{ skill: SupportSkill }> = ({
  skill,
}) => {
  const { button, card, footer, title } = styles;
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(`/field-guide/support-skills/${skill.id}`);
  };

  return (
    <Card sx={{ ...card }}>
      <CardContent>
        <Typography sx={title} gutterBottom>
          {skill.name}
        </Typography>
      </CardContent>
      <CardActions sx={footer}>
        <Button onClick={navigateTo} sx={button} size="small">
          See more
        </Button>
        <Button sx={button} size="small">
          Like
        </Button>
      </CardActions>
    </Card>
  );
};

export default React.memo(SupportSkillLisitingItem);
