import '@fontsource/montserrat';
import type { LogtoConfig } from '@logto/react';
import { LogtoProvider } from '@logto/react';
import Landing from 'Landing';
import { store } from 'Store';
import { ThemeProvider } from 'Theme';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const el = document.getElementById('root');
if (el === null) throw new Error('Root container missing in index.html');

const root = ReactDOM.createRoot(el);

const config: LogtoConfig = {
  appId: process.env.REACT_APP_LOGTO_CLIENT_ID as string,
  endpoint: process.env.REACT_APP_LOGTO_DOMAIN as string,
  resources: [process.env.REACT_APP_LOGTO_RESOURCE as string],
};

const canHide =
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_FEATURE_FLAG_HIDDEN === 'true';

const flagSet = localStorage.getItem('showMeWhatYouGot');

const shouldHide = flagSet === 'true' ? !canHide : canHide;

root.render(
  <React.StrictMode>
    <>
      <LogtoProvider config={config}>
        <Provider store={store}>
          <ThemeProvider>{shouldHide ? <Landing /> : <App />}</ThemeProvider>
        </Provider>
      </LogtoProvider>
      <ToastContainer />
    </>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
