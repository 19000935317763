/** @jsxImportSource @emotion/react */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { DigimonListingItem } from 'Modules/FieldGuide/Components';
import {
  getAttributeColour,
  getAttributeFontColour,
  getAttributeLabel,
  getMoveTypeLabel,
} from 'Modules/FieldGuide/Lib';
import { useGetMoveByIDQuery } from 'Modules/FieldGuide/Store';
import { Attribute } from 'Modules/FieldGuide/Types';
import { useEntityPage } from 'Modules/Shared';
import { largeCornerRadius, theme } from 'Theme';
import { Translate, useTranslation } from 'Translation';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './styles';

const MoveDetail: React.FC = () => {
  const params = useParams<{ move?: string | undefined }>();
  const moveId = params.move ?? 0;
  const query = useGetMoveByIDQuery(moveId);
  const move = query.data;

  const [selectedTab, setSelectedTab] = useState<string>('1');
  const {
    chipBlock,
    container,
    definitionList,
    definitionListRow,
    definitionListDefinitionCell,
    definitionListCell,
    headerRow,
    imageContainer,
  } = styles(getAttributeColour(move ? move.attribute : Attribute.Neutral));

  const bgColor = useMemo(
    () => getAttributeColour(move ? move.attribute : Attribute.Neutral),
    [move],
  );
  const fontColor = useMemo(
    () => getAttributeFontColour(move ? move.attribute : Attribute.Neutral),
    [move],
  );
  const title = useTranslation('Pages.FieldGuide.title');

  const getTitle = () => (query.data ? `${title} - ${query.data.name}` : '');

  const breadcrumbs = [
    { label: useTranslation('Pages.Home.breadcrumb'), to: '/' },
    {
      label: useTranslation('Pages.FieldGuide.breadcrumb'),
      to: '/field-guide',
    },
    {
      label: useTranslation('Pages.FieldGuide.MovesListing.breadcrumb'),
      to: '/field-guide/moves',
    },
  ];

  useEntityPage(query.data, getTitle, breadcrumbs, !!query.isLoading, {
    bgcolor: bgColor,
    color: fontColor,
  });

  const handleTabClick = (event: any, newValue: string) => {
    setSelectedTab(newValue);
  };

  if (!!move && query.isSuccess) {
    const attributeLabel = getAttributeLabel(move.attribute);
    const moveTypeLabel = getMoveTypeLabel(move.type);

    return (
      <Box sx={container}>
        <Stack sx={{ bgcolor: bgColor, p: 2, pb: 4, px: 4 }}>
          <Box sx={headerRow}>
            <Typography
              component="h1"
              variant="h2"
              sx={{ color: fontColor, mb: 1 }}
            >
              {move.name}
            </Typography>
          </Box>
          <Box sx={headerRow}>
            <Box sx={chipBlock}>
              <Chip
                sx={{ color: fontColor, mr: 1 }}
                label={<Translate path={moveTypeLabel} />}
              />
              <Chip
                sx={{ color: fontColor }}
                label={<Translate path={attributeLabel} />}
              />
            </Box>
          </Box>
          <Box sx={imageContainer}>
            <img src={move.icon} alt={move.name} />
          </Box>
        </Stack>
        <Paper
          sx={{
            borderRadius: `${largeCornerRadius} ${largeCornerRadius} 0 0`,
            height: `calc(100% + ${theme.spacing(2)})`,
            mt: -2,
            p: 2,
          }}
          elevation={3}
        >
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
              <TabList onChange={handleTabClick} aria-label="tabs">
                <Tab
                  label={<Translate path="Pages.FieldGuide.Move.tabs.about" />}
                  value="1"
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  label={
                    <Translate path="Pages.FieldGuide.Move.tabs.digimon" />
                  }
                  value="2"
                  sx={{ fontWeight: 'bold' }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Stack>
                <Typography component="p" sx={{ mb: 4 }}>
                  {move.description}
                </Typography>
                <Accordion expanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="round-up-content"
                    id="round-up-header"
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      <Translate path="Pages.FieldGuide.Digimon.about.roundUp.title" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <dl css={definitionList}>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Move.about.roundUp.type" />
                        </dt>
                        <dd css={definitionListCell}>
                          <Translate path={moveTypeLabel} />
                        </dd>
                      </div>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Move.about.roundUp.attribute" />
                        </dt>
                        <dd css={definitionListCell}>
                          <Translate path={attributeLabel} />
                        </dd>
                      </div>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Move.about.roundUp.power" />
                        </dt>
                        <dd css={definitionListCell}>{move.power}</dd>
                      </div>
                      <div css={definitionListRow}>
                        <dt css={definitionListDefinitionCell}>
                          <Translate path="Pages.FieldGuide.Move.about.roundUp.spCost" />
                        </dt>
                        <dd css={definitionListCell}>{move.spCost}</dd>
                      </div>
                    </dl>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </TabPanel>
            <TabPanel value="2">
              <Grid container spacing={2}>
                {move.digimon &&
                  move.digimon.map((digimon) => (
                    <Grid
                      xs={12}
                      sm={6}
                      lg={3}
                      xl={2}
                      item
                      key={`digimon-${digimon.id}`}
                    >
                      <DigimonListingItem digimon={digimon} />
                    </Grid>
                  ))}
              </Grid>
            </TabPanel>
          </TabContext>
          <p>Whats this for?</p>
        </Paper>
      </Box>
    );
  }

  return <></>;
};

export default MoveDetail;
