const typography = {
  button: {
    fontWeight: 'bold',
  },
  fontFamily: ['Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
    ',',
  ),
  h1: {
    fontWeight: 'bold',
  },
  h2: {
    fontWeight: 'bold',
  },
  h3: {
    fontWeight: 'bold',
  },
  h4: {
    fontWeight: 'bold',
  },
  h5: {
    fontWeight: 'bold',
  },
  h6: {
    fontWeight: 'bold',
  },
};

export default typography;
