import type { ThemeAccent } from '../Types';
import { Accent } from '../Types';
import { blue, green, mauve, purple, red, yellow } from '../colourPalette';
import { white } from '../constants';

function getAccent(accent: Accent = Accent.Blue): ThemeAccent {
  switch (accent) {
    case Accent.Green:
      return {
        contrastText: white,
        dark: green[900],
        light: green[100],
        main: green[500],
      };

    case Accent.Yellow:
      return {
        contrastText: white,
        dark: yellow[900],
        light: yellow[100],
        main: yellow[500],
      };

    case Accent.Mauve:
      return {
        contrastText: white,
        dark: mauve[900],
        light: mauve[100],
        main: mauve[500],
      };

    case Accent.Red:
      return {
        contrastText: white,
        dark: red[900],
        light: red[100],
        main: red[500],
      };

    case Accent.Purple:
      return {
        contrastText: white,
        dark: purple[900],
        light: purple[100],
        main: purple[500],
      };

    default:
      return {
        contrastText: white,
        dark: blue[900],
        light: blue[100],
        main: blue[500],
      };
  }
}

export default getAccent;
