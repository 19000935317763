import React from 'react';

import './App.css';

function Landing() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Coming soon...</p>
      </header>
    </div>
  );
}

export default Landing;
