/** @jsxImportSource @emotion/react */
import { Grid, Stack } from '@mui/material';
import type { Roster, RosterEquipment } from 'Modules/Digilab';
import { RosterEquipmentSlot } from 'Modules/Digilab';
import { useModalProps } from 'Modules/Shared';
import { Translate } from 'Translation';
import React, { useMemo, useState } from 'react';

import { AddModal } from './modals';

function Equipment({ roster }: { roster: Roster }): JSX.Element {
  const [selectedSlot, setSelectedSlot] = useState<number>(1);
  const addModalProps = useModalProps();

  const openAddModal = (slotNumber: number) => {
    setSelectedSlot(slotNumber);
    addModalProps.onOpen();
  };

  const { equipment, digimon } = roster;
  const { equipmentSlot: slotCount = 0 } = digimon;

  const processedEquipment = useMemo(() => {
    const baseEquipment: RosterEquipment[] = [...equipment];
    const slotsFilled = equipment.length ?? 0;

    if (slotsFilled === slotCount) {
      return baseEquipment;
    }

    for (let index = slotsFilled; index < slotCount; index++) {
      baseEquipment.push({
        item: {},
        number: index + 1,
      } as RosterEquipment);
    }

    return baseEquipment;
  }, [equipment, slotCount]);

  return (
    <Stack>
      <Grid container spacing={2}>
        {processedEquipment.map((equipment, index) => (
          <Grid
            item
            xs={6}
            key={`equipment-${equipment.id ?? `empty-${index}`}`}
          >
            <RosterEquipmentSlot
              equipment={equipment}
              onOpenAddModal={openAddModal}
            />
          </Grid>
        ))}
      </Grid>
      <AddModal
        isOpen={addModalProps.isOpen}
        onClose={addModalProps.onClose}
        slotNumber={selectedSlot}
        roster={roster}
      />
    </Stack>
  );
}

export default Equipment;
