import type { SxProps, Theme } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import type { TranslationPath } from 'Translation';
import { Translate, useTranslation } from 'Translation';
import type { ReactNode } from 'react';
import React, { useState } from 'react';

export interface MenuItemProps {
  action?: () => void;
  isDisabled?: boolean;
  label: TranslationPath;
  actions?: MenuItemProps[];
}

function UsedSlotMenu({
  ariaLabel,
  iconColor,
  id,
  menuItems,
  sx,
  children,
}: {
  ariaLabel: TranslationPath;
  iconColor?: string;
  menuItems: MenuItemProps[];
  id?: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={id}
        color="inherit"
        aria-label={useTranslation(ariaLabel)}
        sx={sx}
        onClick={handleClick}
      >
        {children}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'edit-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((item) => (
          <MenuItem
            disabled={item.isDisabled}
            key={item.label}
            onClick={() => {
              if (item.action) {
                item.action();
              }

              handleClose();
            }}
          >
            <Translate path={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default UsedSlotMenu;
