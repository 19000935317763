import { Stack } from '@mui/material';
import { toggleDrawer } from 'Modules/Shared';
import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppBar, Drawer } from '..';

function Wrapper({ children }: { children: ReactNode }): JSX.Element {
  const dispatch = useDispatch();

  const closeDrawer = useCallback(() => {
    dispatch(toggleDrawer(false));
  }, []);

  return (
    <Stack sx={{ flexGrow: 1, height: '100%' }}>
      <AppBar />
      <Drawer />
      <Stack mt={8} alignItems="stretch" onClick={closeDrawer}>
        {children}
      </Stack>
    </Stack>
  );
}

export default Wrapper;
