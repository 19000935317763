import { PersistMethod } from '../Types';
import { addToPersistedObjectsMap } from './handlePersistedItems';

interface QuotaError {
  name: string;
  code: number;
}

function isQuotaExceeded(error: QuotaError): boolean {
  if (
    error.name === 'QuotaExceededError' ||
    error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
  ) {
    return true;
  }

  if (error.code === 22 || error.code === 1014) {
    return true;
  }

  return false;
}

/**
 *
 * @param {string} key - key of the data that is to be persisted, this MUST be unique
 * @param data - data to be persisted, this can be an array, object or primitive
 * @param canExpire
 * @param sessionOnly
 */
function persistObject<T>(
  key: string,
  data: T | Array<T>,
  canExpire: boolean = false,
  method: PersistMethod = PersistMethod.LocalStorage,
): void {
  const cachedDate = new Date();
  // This should be in 2 weeks time
  const expireIn = 1000 * 60 * 60 * 24 * 14;
  const toPersist = {
    data,
    expires: canExpire ? cachedDate.getTime() + expireIn : undefined,
    persistedAt: cachedDate.getTime(),
  };

  const stringifiedData = JSON.stringify(toPersist);

  try {
    if (method === PersistMethod.LocalStorage) {
      window.localStorage.setItem(key, stringifiedData);
    } else if (method === PersistMethod.SessionStorage) {
      window.sessionStorage.setItem(key, stringifiedData);
    } else {
      // IndexedDB not implemented
    }

    addToPersistedObjectsMap(key, method);
  } catch (error) {
    if (isQuotaExceeded(error as QuotaError)) {
      console.log('Quota Exceeded, will need to remove an item from cache');
    }
  }
}

export default persistObject;
