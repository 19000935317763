import type { TranslationPath } from 'Translation';

import { RosterPersonality } from '../Types';

const personalityList: Array<{
  description: TranslationPath;
  label: TranslationPath;
  value: RosterPersonality;
}> = [
  {
    description: 'Pages.Digilab.Roster.stats.personality.brainy.description',
    label: 'Pages.Digilab.Roster.stats.personality.brainy.label',
    value: RosterPersonality.Brainy,
  },
  {
    description: 'Pages.Digilab.Roster.stats.personality.builder.description',
    label: 'Pages.Digilab.Roster.stats.personality.builder.label',
    value: RosterPersonality.Builder,
  },
  {
    description: 'Pages.Digilab.Roster.stats.personality.defender.description',
    label: 'Pages.Digilab.Roster.stats.personality.defender.label',
    value: RosterPersonality.Defender,
  },
  {
    description: 'Pages.Digilab.Roster.stats.personality.durable.description',
    label: 'Pages.Digilab.Roster.stats.personality.durable.label',
    value: RosterPersonality.Durable,
  },
  {
    description: 'Pages.Digilab.Roster.stats.personality.fighter.description',
    label: 'Pages.Digilab.Roster.stats.personality.fighter.label',
    value: RosterPersonality.Fighter,
  },
  {
    description: 'Pages.Digilab.Roster.stats.personality.lively.description',
    label: 'Pages.Digilab.Roster.stats.personality.lively.label',
    value: RosterPersonality.Lively,
  },
  {
    description: 'Pages.Digilab.Roster.stats.personality.nimble.description',
    label: 'Pages.Digilab.Roster.stats.personality.nimble.label',
    value: RosterPersonality.Nimble,
  },
  {
    description: 'Pages.Digilab.Roster.stats.personality.searcher.description',
    label: 'Pages.Digilab.Roster.stats.personality.searcher.label',
    value: RosterPersonality.Searcher,
  },
];

export default personalityList;
