import { purple as colourPurple } from '../constants';

const purple50 = '#efeaf1';
const purple100 = '#d8cbdd';
const purple200 = '#bea9c6';
const purple300 = '#a387af';
const purple400 = '#906d9d';
const purple600 = '#744c84';
const purple700 = '#694279';
const purple800 = '#5f396f';
const purple900 = '#4c295c';
const purpleA100 = '#e1a4ff';
const purpleA200 = '#d171ff';
const purpleA400 = '#c03eff';
const purpleA700 = '#b825ff';

const purple = {
  100: purple100,
  200: purple200,
  300: purple300,
  400: purple400,
  50: purple50,
  500: colourPurple,
  600: purple600,
  700: purple700,
  800: purple800,
  900: purple900,
  A100: purpleA100,
  A200: purpleA200,
  A400: purpleA400,
  A700: purpleA700,
};

export default purple;
