import type { TranslationPath } from 'Translation/Types';

function getDigimonStatLabel(stat: string | null): TranslationPath {
  switch (stat) {
    case 'hp':
      return 'Pages.FieldGuide.Digimon.baseStats.stats.hp';

    case 'sp':
      return 'Pages.FieldGuide.Digimon.baseStats.stats.sp';

    case 'attack':
      return 'Pages.FieldGuide.Digimon.baseStats.stats.attack';

    case 'intelligence':
      return 'Pages.FieldGuide.Digimon.baseStats.stats.intelligence';

    case 'defence':
      return 'Pages.FieldGuide.Digimon.baseStats.stats.defence';

    case 'speed':
      return 'Pages.FieldGuide.Digimon.baseStats.stats.speed';

    default:
      return 'Pages.FieldGuide.Digimon.baseStats.stats.attack';
  }
}

export default getDigimonStatLabel;
