import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { TranslationPath } from 'Translation/Types';
import { useTranslation } from 'react-i18next';

interface Props extends TypographyProps {
  component?: any;
  path: TranslationPath;
  subTranslations?: {
    key: string;
    path: TranslationPath;
  }[];
  values?: Record<string, string | number>;
}

function TranslateTypography({
  path,
  values,
  sx,
  component,
  variant,
}: Props): JSX.Element {
  const { t: translate } = useTranslation();

  return (
    <Typography sx={sx} component={component} variant={variant}>
      {translate(path, values)}
    </Typography>
  );
}

export default TranslateTypography;
