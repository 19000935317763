import OneSignal from 'react-onesignal';

async function runOneSignal(): Promise<void> {
  try {
    OneSignal.init({
      allowLocalhostAsSecureOrigin: true,
      appId: `${
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_ONESIGNAL_APP_ID
          : process.env.REACT_APP_ONESIGNAL_TEST_APP_ID
      }`,
    });
    OneSignal.showSlidedownPrompt();
  } catch (e) {
    console.error(e);
  }
}

export default runOneSignal;
