/** @jsxImportSource @emotion/react */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { getItemCategoryLabel } from 'Modules/FieldGuide/Lib';
import type { Item } from 'Modules/FieldGuide/Types';
import { Translate } from 'Translation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

const ItemLisitingItem: React.FC<{ item: Item }> = ({ item }) => {
  const {
    avatar,
    avatarContainer,
    button,
    card,
    contents,
    definitionList,
    definitionListRow,
    definitionListCell,
    footer,
    title,
  } = styles;
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(`/field-guide/items/${item.id}`);
  };

  return (
    <Card sx={{ ...card }}>
      <CardContent>
        <Typography sx={title} gutterBottom>
          {item.name}
        </Typography>
        <Box sx={contents}>
          <Box css={definitionList} component="dl">
            <div css={definitionListRow}>
              <dt css={definitionListCell}>
                <Translate path="Pages.FieldGuide.ItemsListing.listingItem.price" />
                :
              </dt>
              <dd css={definitionListCell}>¥{item.price}</dd>
            </div>
            <div css={definitionListRow}>
              <dt css={definitionListCell}>
                <Translate path="Pages.FieldGuide.ItemsListing.listingItem.type" />
                :
              </dt>
              <dd css={definitionListCell}>
                <Translate path={getItemCategoryLabel(item.category)} />
              </dd>
            </div>
          </Box>
          <Box sx={avatarContainer}>
            <Avatar sx={avatar} alt={item.name} src={item.icon} />
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={footer}>
        <Button onClick={navigateTo} sx={button} size="small">
          See more
        </Button>
        <Button sx={button} size="small">
          Like
        </Button>
      </CardActions>
    </Card>
  );
};

export default React.memo(ItemLisitingItem);
