/** @jsxImportSource @emotion/react */
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import type { RosterEquipment } from 'Modules/Digilab';
import type { ItemEffect } from 'Modules/FieldGuide';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles';

function RosterEquipmentSlot({
  equipment,
  onOpenAddModal,
}: {
  equipment: RosterEquipment;
  onOpenAddModal: (slotNumber: number) => void;
}): JSX.Element {
  const {
    avatar,
    avatarContainer,
    button,
    card,
    contents,
    definitionList,
    definitionListRow,
    definitionListCell,
    footer,
    title,
    stickyMenuButton,
  } = styles;

  const navigate = useNavigate();

  const navigateTo = () => {};

  const isFilled = useMemo(() => !!equipment.id, [equipment.id]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ ...card }}>
      <CardContent>
        <Stack
          sx={{ position: 'relative' }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography sx={title} gutterBottom>
            {isFilled ? equipment.item.name : 'Empty'}
          </Typography>
          {isFilled ? (
            <IconButton
              id="edit-button"
              color="inherit"
              aria-label="edit"
              sx={stickyMenuButton}
              onClick={handleClick}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <IconButton
              id="add-button"
              color="inherit"
              aria-label="edit"
              sx={stickyMenuButton}
              onClick={() => onOpenAddModal(equipment.number)}
            >
              <AddIcon />
            </IconButton>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'edit-button',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>Edit</MenuItem>
            <MenuItem onClick={handleClose}>Remove</MenuItem>
          </Menu>
        </Stack>
        {isFilled && (
          <Box sx={contents}>
            <Box css={definitionList} component="dl">
              {equipment.item.effects?.map((effect: ItemEffect) => (
                <div css={definitionListRow} key={`effect-${effect.id}`}>
                  <dt css={definitionListCell}>{effect.name}:</dt>
                  <dd css={definitionListCell}>+{effect.value}</dd>
                </div>
              ))}
            </Box>
            <Box sx={avatarContainer}>
              <Avatar
                sx={avatar}
                alt={equipment.item.name}
                src={equipment.item.icon}
              />
            </Box>
          </Box>
        )}
      </CardContent>
      {isFilled && (
        <CardActions sx={footer}>
          <Button onClick={navigateTo} sx={button} size="small">
            See more
          </Button>
          <Button sx={button} size="small">
            Like
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default React.memo(RosterEquipmentSlot);
