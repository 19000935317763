/** @jsxImportSource @emotion/react */
import LoadingButton from '@mui/lab/LoadingButton';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import type { Roster, UpdateMemberDTO } from 'Modules/Digilab';
import {
  RosterCard,
  useGetRosterQuery,
  usePatchTeamMutation,
} from 'Modules/Digilab';
import { Translate, useTranslation } from 'Translation';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

interface FormData {
  memberID: number;
  number: number;
}

interface Props {
  teamID: number;
  memberID?: number;
  slotNumber: number;
  isOpen: boolean;
  team: UpdateMemberDTO[];
  onClose: () => void;
}

function removeOptions(team: UpdateMemberDTO[], roster: Roster[]): Roster[] {
  const members: number[] = team.map((member) => member.roster);
  const filteredRoster: Roster[] = [];

  roster.forEach((member) => {
    if (!members.includes(member.id)) {
      filteredRoster.push(member);
    }
  });

  return filteredRoster;
}

const AddModal: React.FC<Props> = ({
  isOpen = false,
  onClose,
  teamID,
  slotNumber = 1,
  memberID = 0,
  team = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [patchTeam, result] = usePatchTeamMutation();
  const rosterRTK = useGetRosterQuery({ limit: 200 });
  const { setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: { memberID: 0, number: slotNumber },
  });

  const description = useTranslation(
    'Pages.Digilab.Team.modals.add.description',
  );

  const roster: Roster[] = removeOptions(team, rosterRTK.data?.items ?? []);

  const watchedMemberId = watch('memberID');
  const selectedRosterMember: Roster | undefined = useMemo(() => {
    if (watchedMemberId !== 0) {
      return roster.find((member) => member.id === watchedMemberId);
    }

    return undefined;
  }, [roster, watchedMemberId]);

  const onSubmit = (data: FormData) => {
    if (memberID === 0) {
      const members = [...team, { number: slotNumber, roster: data.memberID }];
      patchTeam({
        id: teamID,
        members,
      });
    } else {
      const members = [
        ...team,
        { id: memberID, number: slotNumber, roster: data.memberID },
      ];
      patchTeam({
        id: teamID,
        members,
      });
    }
  };

  useEffect(
    () => () => {
      if (!isOpen) {
        reset({ memberID: 0, number: slotNumber });
      }
    },
    [slotNumber, isOpen],
  );

  useEffect(() => {
    if (!result.isLoading) {
      if (result.isSuccess) {
        enqueueSnackbar('Added to team', { variant: 'success' });
      } else if (result.isError) {
        enqueueSnackbar('Unable to add to the team', { variant: 'error' });
      }
    }
  }, [result]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {useTranslation('Pages.Digilab.Team.modals.add.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2} spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="equipment-itemId-label">Team member</InputLabel>
              <Select
                labelId="equipment-itemId-label"
                label="Item"
                value={`${watchedMemberId ?? ''}`}
                onChange={(e: SelectChangeEvent) =>
                  setValue('memberID', Number(e.target.value), {
                    shouldValidate: true,
                  })
                }
              >
                {roster.map((item) => (
                  <MenuItem
                    value={`${item.id}`}
                    key={`${item.id}-${item.nickname}`}
                  >
                    {item.nickname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!!selectedRosterMember && (
              <RosterCard roster={selectedRosterMember} />
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {useTranslation('Pages.Digilab.Team.modals.add.buttons.cancel')}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          disabled={result.isLoading}
        >
          {useTranslation('Pages.Digilab.Team.modals.add.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
