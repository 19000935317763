import { css } from '@emotion/react';
import { theme } from 'Theme';

const styles = {
  chipBlock: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  definitionList: css`
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: ${theme.spacing(0.5)};
    margin: 0;
  `,
  definitionListCell: css`
    display: table-cell;
    border-left-width: 3px;
    padding: ${theme.spacing(0.45)} ${theme.spacing(0.75)};
  `,
  definitionListDefinitionCell: css`
    display: table-cell;
    border-left-width: 3px;
    padding: ${theme.spacing(0.45)} ${theme.spacing(0.75)}
      ${theme.spacing(0.45)} 0;
    color: #b9c2cd;
    font-weight: 600;
  `,
  definitionListRow: css`
    display: table-row;
  `,
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    mb: 4,
  },
};

export default styles;
